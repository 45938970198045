import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
} from 'react';
import { plainToClass } from 'class-transformer';
import {
  TenantApplication,
  CompoundTenantId,
} from 'advanext-models/nectar/tenant';

import { useSelectors } from 'Hooks';
import { TenantApplicationsPath } from 'Constants';
import { fetcher } from 'Api';

import {
  user as userSelector,
  loading as userIsLoadingSelector,
} from 'Redux/models/Auth';

const LOCAL_STORAGE_APP_PARAMETER = 'selectedApplication';

export const TenantProviderContext = createContext({
  applications: [] as TenantApplication[],
  application: localStorage.getItem('') || '',
  selectApplication: (app: string) => {}, // eslint-disable-line
  compoundTenantId: new CompoundTenantId('', ''),
});

type Props = {
  children: ReactElement | ReactElement[];
};

const TenantProvider = ({ children }: Props): ReactElement => {
  const [application, setApplication] = useState(
    localStorage.getItem(LOCAL_STORAGE_APP_PARAMETER) || '',
  );
  const [applications, setApplications] = useState<TenantApplication[]>([]);
  const [user] = useSelectors([userSelector, userIsLoadingSelector]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!user?.tenant) return;

      const data = await fetcher(
        TenantApplicationsPath.replace(/:id/g, encodeURIComponent(user.tenant)),
      );
      setApplications(
        (data as JSON[]).map((item) => plainToClass(TenantApplication, item)),
      );
    })();
  }, [user?.tenant]); // eslint-disable-line

  const selectApplication = useCallback((app: string) => {
    setApplication(app);
    localStorage.setItem(LOCAL_STORAGE_APP_PARAMETER, app);
  }, []); // eslint-disable-line

  const compoundTenantId = useMemo(
    () => new CompoundTenantId(application, user?.tenant || ''),
    [application, user?.tenant], // eslint-disable-line
  );

  return (
    <TenantProviderContext.Provider
      value={{
        applications,
        application,
        selectApplication,
        compoundTenantId,
      }}
    >
      {children}
    </TenantProviderContext.Provider>
  );
};

export default TenantProvider;
