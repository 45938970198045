import type { Tenant, TenantApplication } from 'advanext-models/nectar/tenant';
import { User } from 'advanext-models/nectar';
import { apiService } from 'Services';
import { plainToClass } from 'class-transformer';

// ------
// Tenants
// ------

export const updateTenant = async (
  tenantId: string,
  tenant: Partial<Tenant>,
): Promise<Tenant | null> => {
  try {
    const { data } = await apiService.patch(
      `tenants/${encodeURIComponent(tenantId)}`,
      {
        data: tenant,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const createTenant = async (tenant: Tenant): Promise<Tenant | null> => {
  try {
    const { data } = await apiService.post('tenants', {
      data: tenant,
    });
    return data;
  } catch (e) {
    return null;
  }
};

export const deleteTenant = async (
  tenantId: string,
): Promise<Tenant | null> => {
  try {
    const { data } = await apiService.delete(
      `tenants/${encodeURIComponent(tenantId)}`,
    );
    return data;
  } catch (e) {
    return null;
  }
};

// ------
// Tenant Applications
// ------

export const createTenantApplication = async (
  tenantId: string,
  application: TenantApplication,
): Promise<TenantApplication | null> => {
  try {
    const { data } = await apiService.post(
      `tenants/${encodeURIComponent(tenantId)}/applications`,
      {
        data: application,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const updateTenantApplication = async (
  tenantId: string,
  applicationId: string,
  application: TenantApplication,
): Promise<TenantApplication | null> => {
  try {
    const { data } = await apiService.patch(
      `tenants/${encodeURIComponent(
        tenantId,
      )}/applications/${encodeURIComponent(applicationId)}`,
      {
        data: application,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const deleteTenantApplication = async (
  tenantId: string,
  applicationId: string,
): Promise<TenantApplication | null> => {
  try {
    const { data } = await apiService.delete(
      `tenants/${encodeURIComponent(
        tenantId,
      )}/applications/${encodeURIComponent(applicationId)}`,
    );
    return data;
  } catch (e) {
    return null;
  }
};

// ------
// Tenant Members
// ------

export const createUser = async (
  tenantId: string,
  member: User,
): Promise<User | null> => {
  try {
    const { data } = await apiService.post(
      `tenants/${encodeURIComponent(tenantId)}/members`,
      {
        data: member,
      },
    );
    return plainToClass(User, data);
  } catch (e) {
    return null;
  }
};

export const updateUser = async (
  tenantId: string,
  username: string,
  member: User,
): Promise<User | null> => {
  try {
    const { data } = await apiService.patch(
      `tenants/${encodeURIComponent(tenantId)}/members/${encodeURIComponent(
        username,
      )}`,
      {
        data: member,
      },
    );
    return plainToClass(User, data);
  } catch (e) {
    return null;
  }
};

export const deleteUser = async (
  tenantId: string,
  username: string,
): Promise<boolean> => {
  try {
    await apiService.delete(
      `tenants/${encodeURIComponent(tenantId)}/members/${encodeURIComponent(
        username,
      )}`,
    );
    return true;
  } catch (e) {
    return false;
  }
};
