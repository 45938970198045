import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { StyledCard } from 'advanext-components';
import type { CompanyData } from 'advanext-models/nectar/report';
import type { IndustryCodeNode } from 'advanext-models/nectar/industry';
import type { ConsolidatedMultiSourceProperty } from 'advanext-models/nectar/multisource';
import { formatDate, getDateDiffOrNA, getValueOrNA } from 'Utilities';

type Props = {
  companyData?: CompanyData;
  industry?: ConsolidatedMultiSourceProperty<IndustryCodeNode>;
};

const CompanyDataTable = ({ companyData, industry }: Props): JSX.Element => (
  <StyledCard title="Company Details">
    <Table>
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '75%' }} />
      </colgroup>
      <TableBody>
        <TableRow>
          <TableCell>Unique ID</TableCell>
          <TableCell>
            {getValueOrNA(companyData?.companyId.toString())}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Legal Form</TableCell>
          <TableCell>
            {getValueOrNA(companyData?.legalForm?.consolidated?.legalFormText)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Founding Date</TableCell>
          <TableCell>
            {formatDate(companyData?.foundingDate?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Auditing Duty</TableCell>
          <TableCell>
            {getValueOrNA(companyData?.auditingCompany?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Industry</TableCell>
          <TableCell>
            {getValueOrNA(industry?.consolidated?.industry)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>NOGA</TableCell>
          <TableCell>{getValueOrNA(industry?.CRIF?.industryCode)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Purpose</TableCell>
          <TableCell>
            {getValueOrNA(companyData?.purpose?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Name Change</TableCell>
          <TableCell>
            {getDateDiffOrNA(companyData?.nameChanged?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Domicile Change</TableCell>
          <TableCell>
            {getDateDiffOrNA(companyData?.domicileChanged?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Auditing Duty Change</TableCell>
          <TableCell>
            {getDateDiffOrNA(companyData?.auditChanged?.consolidated)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Capital Change</TableCell>
          <TableCell>
            {getDateDiffOrNA(companyData?.capitalChanged?.CRIF)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </StyledCard>
);

export default CompanyDataTable;
