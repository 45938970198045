import React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { Formik } from 'formik';
import { isNil } from 'ramda';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NectarReport } from 'advanext-models/nectar/report';
import {
  useScoreResult,
  useBlueprint,
  useCompanyMandates,
  useCreateScoreResult,
  useDeactivateScoreResult,
} from 'Hooks';
import { ErrorAlert } from 'advanext-components';
import { BlueprintTypes } from 'Constants';
import { handleBlueprintValues, handleResultValues } from 'Utilities';
import Panel from './Panel';
import QuestionField from './QuestionField';
import ScoringResult from './ScoringResult';

const useStyles = makeStyles({
  introText: {
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#333',
  },
  startOverButton: {
    float: 'right',
    border: '1px solid #245580',
    fontSize: '13px',
    color: '#fff',
    backgroundColor: '#265a88',
    height: '34px',
    padding: '6px 12px',
    '&:hover': {
      border: '1px solid #245580',
      backgroundColor: '#265a88',
      color: '#fff',
    },
  },
  submitBlock: {
    marginTop: '10px',
    fontSize: '13px',
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  submitButton: {
    border: '1px solid #245580',
    backgroundColor: '#265a88',
    padding: '6px 12px',
    textAlign: 'center',
    fontSize: '13px',
    color: '#fff',
    width: '300px',
    height: '34px',
    '&.highlighted': {
      backgroundColor: '#f29f3e',
      borderColor: '#f29f3e',
    },
    '&:hover': {
      border: '1px solid #245580',
      backgroundColor: '#265a88',
      color: '#fff',
    },
    '&:disabled': {
      border: '1px solid #245580',
      backgroundColor: '#265a88 !important',
      color: '#fff',
      opacity: 0.65,
      cursor: 'not-allowed',
      pointerEvents: 'initial',
    },
  },
  attentionIcon: {
    fontSize: '20px',
    color: 'red',
    marginLeft: '5px',
  },
  loadingWrapper: {
    position: 'relative',
    marginTop: '40px',
    textAlign: 'center',
  },
  errorAlert: {
    marginTop: '16px',
  },
});

type Props = {
  company?: NectarReport;
  companyId: string;
};

const QualitativeDD = ({ company, companyId }: Props): JSX.Element => {
  const classes = useStyles();

  const { data: partialResult, isLoading: isPartialResultLoading } =
    useScoreResult(
      BlueprintTypes.Segment1,
      company?.companyData?.companyId,
      company?.companyData?.country,
    );
  const { data: result, isLoading: isResultLoading } = useScoreResult(
    BlueprintTypes.Segment2,
    company?.companyData?.companyId,
    company?.companyData?.country,
  );
  const isDisabled = !isNil(result?.score);
  const {
    data: blueprint1,
    isLoading: isBlueprint1Loading,
    error: blueprint1Error,
  } = useBlueprint(BlueprintTypes.Segment1, company?.companyData?.country);
  const {
    data: blueprint2,
    isLoading: isBlueprint2Loading,
    error: blueprint2Error,
  } = useBlueprint(BlueprintTypes.Segment2, company?.companyData?.country);
  const { data: otherMandates, isLoading: isMandatesLoading } =
    useCompanyMandates(companyId);
  const { createResult } = useCreateScoreResult(
    companyId,
    company?.companyData?.country,
    company?.companyData?.companyId,
  );
  const { deactivateResult, isDeactivating } = useDeactivateScoreResult(
    companyId,
    [BlueprintTypes.Segment1, BlueprintTypes.Segment2],
    company?.companyData?.country,
    company?.companyData?.companyId,
  );

  const prefillValues1 = handleBlueprintValues(
    blueprint1,
    company,
    otherMandates,
  );
  const prefillValues2 = handleBlueprintValues(
    blueprint2,
    company,
    otherMandates,
  );
  const values1 = handleResultValues(partialResult);
  const values2 = handleResultValues(result);

  const Loading = (): JSX.Element => (
    <div className={classes.loadingWrapper}>
      <CircularProgress size={40} />
    </div>
  );

  const finalScore =
    !isNil(result?.score) && !isNil(partialResult?.score)
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Math.min(result!.score, partialResult!.score)
      : undefined;
  const finalRating =
    !isNil(result?.rating) && !isNil(partialResult?.rating)
      ? [result?.rating, partialResult?.rating].sort()[1]
      : undefined;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={8} className={classes.introText}>
          Fill out the form below to obtain the Qualitative Due Diligence Score
          & Rating.
          <br />A final PDF report will be generated upon completion of the
          second segment and calculation of the Total Score.
        </Grid>
        <Grid item md={4}>
          <Button
            type="button"
            onClick={deactivateResult}
            className={classes.startOverButton}
          >
            Start Over
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <ScoringResult
          score={finalScore}
          rating={finalRating}
          withTimestamp={false}
        />
        <ScoringResult
          partial
          score={partialResult?.score}
          rating={partialResult?.rating}
          created={partialResult?.created}
        />
      </Grid>
      {isDeactivating ||
      isPartialResultLoading ||
      isBlueprint1Loading ||
      isResultLoading ||
      isBlueprint2Loading ||
      isMandatesLoading ? (
        <Loading />
      ) : (
        <>
          {blueprint1Error ? (
            <ErrorAlert text="Failed to load data" />
          ) : (
            <>
              {!!blueprint1 && (
                <Formik
                  enableReinitialize
                  validateOnMount={true}
                  onSubmit={(values): void =>
                    createResult(
                      Object.entries(values).map(([key, value]) => ({
                        answer: R.is(Array, value)
                          ? JSON.stringify(value)
                          : `${value}`,
                        questionId: key,
                      })),
                      blueprint1.id,
                      BlueprintTypes.Segment1,
                    )
                  }
                  initialValues={values1 ?? prefillValues1 ?? {}}
                >
                  {({ isValid, handleSubmit, dirty }): JSX.Element => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                        {blueprint1.sections.map((section) => (
                          <Grid key={section.title} item md={6}>
                            <Panel title={section.title}>
                              {section.questions.map((question) => (
                                <QuestionField
                                  disabled={isDisabled}
                                  key={question.id}
                                  question={question}
                                  prefillValue={prefillValues1?.[question.id]}
                                />
                              ))}
                            </Panel>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        container
                        spacing={4}
                        className={classes.submitBlock}
                      >
                        {partialResult?.score ? (
                          <ScoringResult
                            partial
                            score={partialResult.score}
                            rating={partialResult.rating}
                            created={partialResult.created}
                          />
                        ) : (
                          <Grid item md={6}>
                            After you have filled out all the questions above,
                            we calculate the partial score.
                            <br />
                            You can start filling out the final score after.
                          </Grid>
                        )}
                        <Grid item md={6} className={classes.submitContainer}>
                          <Button
                            type="submit"
                            disabled={!isValid || isDisabled}
                            className={cn(classes.submitButton, {
                              highlighted: partialResult?.score && dirty,
                            })}
                          >
                            Calculate Partial Score
                            {!!partialResult?.score && dirty && (
                              <span className={classes.attentionIcon}>
                                &#9888;
                              </span>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </>
          )}
          {blueprint2Error ? (
            <ErrorAlert />
          ) : (
            <>
              {!!blueprint2 && !isNil(partialResult?.score) && (
                <Formik
                  enableReinitialize
                  validateOnMount={true}
                  onSubmit={(values): void =>
                    createResult(
                      Object.entries(values).map(([key, value]) => ({
                        answer: R.is(Array, value)
                          ? JSON.stringify(value)
                          : `${value}`,
                        questionId: key,
                      })),
                      blueprint2.id,
                      BlueprintTypes.Segment2,
                    )
                  }
                  initialValues={values2 ?? prefillValues2 ?? {}}
                >
                  {({ isValid, handleSubmit }): JSX.Element => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                        {blueprint2.sections.map((section) => (
                          <Grid key={section.title} item md={6}>
                            <Panel title={section.title}>
                              {section.questions.map((question) => (
                                <QuestionField
                                  disabled={isDisabled}
                                  key={question.id}
                                  question={question}
                                  prefillValue={prefillValues2?.[question.id]}
                                />
                              ))}
                            </Panel>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        container
                        spacing={4}
                        className={classes.submitBlock}
                      >
                        {result?.score ? (
                          <ScoringResult
                            score={result.score}
                            rating={result.rating}
                            created={result.created}
                          />
                        ) : (
                          <Grid item md={6}>
                            When you have filled in the entire form, you will be
                            able to click: Calculate Total Score
                          </Grid>
                        )}
                        <Grid item md={6} className={classes.submitContainer}>
                          <Button
                            type="submit"
                            disabled={!isValid || isDisabled}
                            className={classes.submitButton}
                          >
                            Calculate Total Score
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QualitativeDD;
