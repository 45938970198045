import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { PageTitle } from 'advanext-components';

const Dashboard = (): JSX.Element => (
  <>
    <PageTitle title="Dashboard" />
    <Card>
      <CardHeader subheader="Header" />
      <CardContent>
        <Typography>We could have something useful here</Typography>
      </CardContent>
    </Card>
  </>
);

export default Dashboard;
