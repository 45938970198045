import React, { useCallback } from 'react';
import { FastField } from 'formik';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormInput,
  FormDatePicker,
  FormSwitchDatePicker,
  FormRadio,
  FormSelect,
  FormSwitchInput,
  FormFieldArray,
} from 'advanext-components';
import { Question, AnswerType } from 'advanext-models/nectar/astrea';
import { OptionType } from 'Types';
import {
  BOOLEAN_OPTIONS,
  NO_DATA_OPTION,
  NOT_APPLICABLE,
  getOptions,
} from 'Utilities';
import { compose, notEmpty, isPercent } from 'Utilities/validators';

type Props = {
  disabled?: boolean;
  question: Question;
  prefillValue?: string | string[];
};

const useStyles = makeStyles({
  labelWrapper: {
    '& > *': {
      width: '50%',
    },
  },
  warningWrapper: {
    '& > p': {
      width: '50%',
      marginLeft: 'auto',
      marginTop: '-10px',
      marginBottom: '12px',
    },
  },
  label: {
    width: '49% !important',
    padding: '7px',
    fontSize: '13px',
    '&.empty': {
      background: '#eee',
      borderRadius: '4px',
    },
  },
});

const QuestionField = ({
  disabled = false,
  question,
  prefillValue,
}: Props): JSX.Element => {
  const classes = useStyles();

  const getAdditionalFieldProps = (): Record<
    string,
    string | React.ReactNode | Array<OptionType>
  > => {
    const props: Record<string, string | React.ReactNode | Array<OptionType>> =
      {};
    switch (question.answerType) {
      case AnswerType.STRING:
        props.type = 'text';
        // @ts-ignore
        props.component = FormInput;
        break;
      case AnswerType.STRING_NO_DATA:
        props.type = 'text';
        // @ts-ignore
        props.component = FormSwitchInput;
        break;
      case AnswerType.NUMBER:
        props.type = 'number';
        // @ts-ignore
        props.component = FormInput;
        break;
      case AnswerType.NUMBER_NO_DATA:
        props.type = 'number';
        // @ts-ignore
        props.component = FormSwitchInput;
        break;
      case AnswerType.RANGE_NUMBER:
        props.type = 'number';
        props.min = 0;
        props.max = 100;
        // @ts-ignore
        props.validate = compose(notEmpty, isPercent);
        // @ts-ignore
        props.component = FormInput;
        break;
      case AnswerType.ENUM:
      case AnswerType.ENUM_SPECIFIC:
        props.options = getOptions(question.choices);
        // @ts-ignore
        props.component = FormSelect;
        break;
      case AnswerType.ENUM_NO_DATA:
        props.options = [NO_DATA_OPTION, ...getOptions(question.choices)];
        // @ts-ignore
        props.component = FormSelect;
        break;
      case AnswerType.BOOLEAN:
        props.options = BOOLEAN_OPTIONS;
        // @ts-ignore
        props.component = FormRadio;
        break;
      case AnswerType.BOOLEAN_NO_DATA:
        props.options = [...BOOLEAN_OPTIONS, NO_DATA_OPTION];
        // @ts-ignore
        props.component = FormRadio;
        break;
      case AnswerType.BOOLEAN_NOT_APPLICABLE:
        props.options = [...BOOLEAN_OPTIONS, NOT_APPLICABLE];
        // @ts-ignore
        props.component = FormRadio;
        break;
      case AnswerType.LIST_STRING:
        props.validate = undefined;
        props.labelClassName = undefined;
        // @ts-ignore
        props.component = FormFieldArray;
        break;
      case AnswerType.DATE:
      case AnswerType.RANGE_DATE:
        // @ts-ignore
        props.component = FormDatePicker;
        break;
      case AnswerType.DATE_NO_DATA:
        // @ts-ignore
        props.component = FormSwitchDatePicker;
        break;
      default:
        // @ts-ignore
        props.component = FormInput;
        break;
    }
    return props;
  };

  const handleWarningText = useCallback(
    (value: string | string[]) => {
      if (!R.isNil(prefillValue) && !R.equals(value, prefillValue))
        return `Auto calculated value is ${
          question.answerType === AnswerType.LIST_STRING
            ? JSON.stringify(prefillValue)
            : `${prefillValue}`
        }`;
      return;
    },
    [prefillValue, question.answerType],
  );

  return (
    <FastField
      disableFormError
      id={question.id}
      name={question.id}
      label={question.text}
      labelDirection="row"
      labelClassName={classes.label}
      labelWrapperClassName={classes.labelWrapper}
      warningWrapperClassName={classes.warningWrapper}
      validate={notEmpty}
      disabled={disabled}
      getWarningText={handleWarningText}
      {...getAdditionalFieldProps()}
    />
  );
};

export default QuestionField;
