import React, { Fragment } from 'react';
import { v1 as uuid } from 'uuid';
import { User } from 'advanext-models/nectar';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Icon,
} from '@material-ui/core';

type Props = {
  members: Array<User>;
  onEditMember: (user: User) => void;
  onRemoveMember: (user: User) => void;
};

const TenantMembersList = ({
  members,
  onEditMember,
  onRemoveMember,
}: Props): JSX.Element => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Group</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members &&
          members.map((member) => (
            <Fragment key={uuid()}>
              <TableRow>
                <TableCell>{member.email}</TableCell>
                <TableCell>{member.group.map((e) => e).join(', ')}</TableCell>
                <TableCell>
                  <IconButton onClick={(): void => onEditMember(member)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton onClick={(): void => onRemoveMember(member)}>
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TenantMembersList;
