import { useState, useContext, useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import * as R from 'ramda';

import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { TenantProviderContext } from 'Views/providers/Tenant';

import {
  GetMonitoringChangesRequest,
  GetMonitoringChangesResult,
} from 'advanext-models/nectar/monitoring';

import { getMonitoredChangesApi } from 'Api';
import { CompaniesInfoPath, GetMonitoringChangesPath } from 'Constants';
import { apiService } from 'Services';

export const useMonitoredChanges = (
  requestBody: Omit<
    GetMonitoringChangesRequest,
    'tenantId' | 'compoundCompanyId'
  >,
): {
  getChanges: (compoundCompanyId?: string, lastEvaluatedKey?: unknown) => void;
  companiesFilterOptions: Array<{ label: string; value: string }>;
  result?: GetMonitoringChangesResult;
  error?: string;
  isLoading: boolean;
} => {
  const { compoundTenantId } = useContext(TenantProviderContext);
  const [result, setResult] = useState<GetMonitoringChangesResult>();
  const [error, setError] = useState<string | undefined>();
  const [companiesFilterOptions, setCompaniesFilterOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const getCompaniesFilterOptions = useAsyncCallback(
    async (): Promise<void> => {
      try {
        const { data: allMonitoredEvents } =
          await apiService.get<GetMonitoringChangesResult>(
            GetMonitoringChangesPath,
            {
              params: {
                compoundTenantId: compoundTenantId?.toString(),
              },
            },
          );
        const uniqueIds = R.uniq(
          allMonitoredEvents.changes.map((e) => e.compoundCompanyId.toString()),
        );

        const { data: companyInfo } = await apiService.get(CompaniesInfoPath, {
          params: {
            compoundTenantId: compoundTenantId?.toString(),
            ids: uniqueIds.join(','),
          },
        });

        setCompaniesFilterOptions(
          uniqueIds.map((e) => ({
            label: companyInfo.find((i: { id: string }) => i.id === e).name,
            value: e,
          })),
        );
      } catch {
        setCompaniesFilterOptions([]);
      }
    },
  );

  const getMonitoredChanges = useAsyncCallback(
    async (compoundCompanyId, lastEvaluatedKey) => {
      try {
        const data = await getMonitoredChangesApi(
          compoundCompanyId
            ? CompoundCompanyId.fromValue(compoundCompanyId.toString())
            : undefined,
          compoundTenantId,
          requestBody.limit,
          requestBody.status,
          lastEvaluatedKey,
        );
        setResult(data);
      } catch {
        setError('Failed to load monitoring events');
      }
    },
  );

  useEffect(() => {
    getCompaniesFilterOptions.execute();
    // eslint-disable-next-line
  }, []);

  return {
    getChanges: getMonitoredChanges.execute,
    companiesFilterOptions,
    result,
    error,
    isLoading: getMonitoredChanges.loading,
  };
};
