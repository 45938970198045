import useSWR, { mutate as mutateGlobal } from 'swr';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { UWChange, UWTask } from 'advanext-models/nectar/uwtask';
import Status from 'advanext-models/nectar/uwtask/Status';
import { plainToClass } from 'class-transformer';
import { useAsyncCallback } from 'react-async-hook';

import { user as userSelector } from 'Redux/models/Auth';
import { TenantProviderContext } from 'Views/providers/Tenant';
import { fetcher, updateUWTaskApi } from 'Api';
import { UWTasksPath } from 'Constants';
import { sleep, sortUWTasksByType } from 'Utilities';

export const useUWTasks = (
  id?: string,
  swrParams = { revalidateOnFocus: true },
): {
  data: Array<UWTask>;
  isCompleted: boolean;
  isLoading: boolean;
  error: Error;
  onUpdateRow: (id: string, comment: string, status: Status) => void;
} => {
  const user = useSelector(userSelector);
  const { compoundTenantId } = useContext(TenantProviderContext);

  const stringified = JSON.stringify({
    compoundCompanyId: id,
    compoundTenantId: compoundTenantId.toString(),
  });

  const { data, error, mutate } = useSWR(
    [UWTasksPath, stringified],
    fetcher,
    swrParams,
  );

  const onUpdateRow = useAsyncCallback(
    async (id: string, comment: string, status: Status): Promise<void> => {
      await updateUWTaskApi(
        id,
        plainToClass(UWChange, {
          updatedBy: `${user?.firstName} ${user?.lastName}`,
          comment,
          status,
        }),
      );
      await sleep(1500); // Wait 1500 ms before re-fetching the data
      mutate();
      // Refetch Company Due Diligence table too
      if (id) {
        const stringifiedWithoutId = JSON.stringify({
          compoundTenantId: compoundTenantId.toString(),
        });
        mutateGlobal([UWTasksPath, stringifiedWithoutId]);
      }
    },
  );

  return {
    data: sortUWTasksByType(data as Array<UWTask>),
    isCompleted: data
      ? (data as Array<UWTask>)?.every(
          (e) =>
            e.status === Status.NotRequired || e.status === Status.Completed,
        )
      : false,
    isLoading: !error && !data,
    error,
    onUpdateRow: onUpdateRow.execute,
  } as {
    data: Array<UWTask>;
    isCompleted: boolean;
    isLoading: boolean;
    error: Error;
    onUpdateRow: (id: string, comment: string, status: Status) => void;
  };
};
