import React, { ReactElement, useMemo, useCallback, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRedo,
  faFolder,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FileFolderTreeNode } from 'Types';
import { traverseFoldersTree } from 'Utilities';

const useStyles = makeStyles(() => ({
  button: {
    width: '80px',
    height: '20px',
    padding: 0,
    backgroundColor: '#c4c4c4',
    border: '1px solid #262B32',
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'none',
    marginLeft: '10px',
  },
  navigationButton: {
    padding: 0,
    color: 'grey',
    fontSize: '14px',
    marginLeft: '10px',
    textTransform: 'none',
  },
  foldersContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  folderIcon: {
    marginRight: '5px',
    fontSize: '24px',
  },
  folderButton: {
    minWidth: '220px',
    height: '34px',
    border: '1px solid #262B32',
    margin: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    justifyContent: 'flex-start',
  },
  folderName: {
    width: '100%',
    justifySelf: 'center',
  },
}));

type Props = {
  foldersTree: FileFolderTreeNode;
  currentPath: string | null;
  onChange: (path: string | null) => void;
  onReload: () => void;
};

const FoldersComponent = ({
  foldersTree,
  currentPath,
  onChange,
  onReload,
}: Props): JSX.Element => {
  const classes = useStyles();
  const currentPathFolders = currentPath?.split('/') ?? [];

  // Traversal callback to figure out current node inside the tree
  const currentLevelCallback = useCallback(
    (
      folder: string,
      children: (number | null)[],
      level: number,
      path: string,
    ): number | null =>
      children.filter(Boolean).length
        ? children[0]
        : path === currentPath
        ? level
        : null,
    [currentPath],
  );

  // Figure out current tree node
  const currentLevel = useMemo(
    () =>
      !currentPath
        ? -1
        : (traverseFoldersTree(foldersTree, currentLevelCallback)[0] as number),
    [foldersTree, currentPath, currentLevelCallback],
  );

  // Traversal callback to render folders of the current level, since we don't render sub-folder we ignore "children" here
  const renderFoldersCallback = useCallback(
    (
      root: string,
      children: (ReactElement | null)[],
      level: number,
      path: string,
    ): ReactElement | null => {
      // Render only child nodes of the currently selected folder (since we are only rendering part of the tree)
      const pathMatches =
        path.startsWith((currentPath || '') + '/') &&
        level === currentLevel + 1;
      if (!pathMatches && currentLevel !== -1)
        return <Fragment key={uuid()}>{children}</Fragment>;

      return (
        <Button
          className={classes.folderButton}
          key={uuid()}
          onClick={(): void => onChange(path)}
        >
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faFolder} className={classes.folderIcon} />
          <span className={classes.folderName}>{root}</span>
        </Button>
      );
    },
    [classes, currentLevel, currentPath, onChange],
  );

  // Array with rendered folders
  const renderedFolders = useMemo(
    () => traverseFoldersTree(foldersTree, renderFoldersCallback),
    [foldersTree, renderFoldersCallback],
  );

  return (
    <Box m={2}>
      <Button className={classes.button} onClick={(): void => onChange(null)}>
        Home
      </Button>
      <Button className={classes.button} onClick={(): void => onReload()}>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faRedo} />
      </Button>
      {currentLevel > -1 && (
        <Button
          className={classes.button}
          onClick={(): void =>
            onChange(
              currentLevel === 0
                ? null
                : currentPathFolders.slice(0, -1).join('/'),
            )
          }
        >
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      )}
      <Box mt={1}>
        <Button
          className={classes.navigationButton}
          onClick={(): void => onChange(null)}
        >
          All Documents{' /'}
        </Button>
        {currentPathFolders.map((parent, idx) => (
          <Button
            key={uuid()}
            className={classes.navigationButton}
            onClick={(): void => {
              if (idx === currentPathFolders.length - 1) return;
              onChange(currentPathFolders.slice(0, idx + 1).join('/'));
            }}
          >
            {parent}&nbsp;/
          </Button>
        ))}
      </Box>
      <Box mt={1} className={classes.foldersContainer}>
        {renderedFolders.length > 0 ? (
          renderedFolders
        ) : (
          <Box ml={1}>
            <Typography variant="subtitle1">
              No more folders to show.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FoldersComponent;
