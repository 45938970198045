import { BankStatementObject } from 'Types';

export const headCells: Array<{
  id: keyof BankStatementObject;
  label: string;
  style?: { width: string };
  isSortable: boolean;
}> = [
  {
    id: 'created',
    label: 'Upload Date',
    isSortable: true,
  },
  {
    id: 'bank',
    label: 'Bank name',
    isSortable: true,
  },
  {
    id: 'iban',
    label: 'IBAN',
    style: { width: '20%' },
    isSortable: true,
  },
  {
    id: 'currency',
    label: 'Currency',
    style: { width: '15%' },
    isSortable: true,
  },
  {
    id: 'period_start',
    label: 'Start date',
    isSortable: true,
  },
  {
    id: 'period_end',
    label: 'End date',
    isSortable: true,
  },
];
