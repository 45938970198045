import React, { useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { PageTitle } from 'advanext-components';
import { useToasts } from 'react-toast-notifications';

import { ROUTES } from 'Constants';
import { usePolicy } from 'Hooks';
import { IsOwner } from 'Policies';
import { deleteTenant } from 'Api';

import {
  TenantApplicationsWidget,
  TenantMembersWidget,
  TenantUsageWidget,
} from 'Views/widgets';

import { TenantCard, PricingCard } from './components';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

const Tenant = (): JSX.Element => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { push } = useHistory();
  const classes = useStyles();

  const { addToast } = useToasts();

  const [tenantSubmitting, setTenantSubmitting] = useState(false);
  const [isOwner, isOwnerLoading] = usePolicy([IsOwner]);

  const isInAddMode = tenantId === 'new';

  if (!isOwnerLoading && !isOwner) {
    return <Redirect to={ROUTES.Dashboard} />;
  }

  const destroyTenant = async (): Promise<void> => {
    if (!tenantId) return;

    setTenantSubmitting(true);
    const tenant = await deleteTenant(tenantId);
    if (!tenant) {
      addToast('Failed to delete tenant', { appearance: 'error' });
    }
    push(ROUTES.Dashboard);
  };

  return (
    <>
      <PageTitle
        title={
          isInAddMode ? 'Add a New Tenant' : `Edit Tenant "${tenantId || ''}"`
        }
        subtitle={
          <Typography variant="subtitle1" color="textSecondary">
            By adding a new tenant you may grant a third party rights to use the
            Nectar platform.
          </Typography>
        }
        info="Manage tenants and applied pricing"
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={destroyTenant}
          disabled={isInAddMode || tenantSubmitting}
        >
          Remove Tenant
        </Button>
      </PageTitle>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <TenantCard
            tenantId={tenantId || ''}
            isInAddMode={isInAddMode}
            className={classes.card}
          />
          <TenantMembersWidget
            className={classes.card}
            isInAddMode={isInAddMode}
            isOwner={isOwner}
            tenantId={tenantId || ''}
          />
        </Grid>
        <Grid item md={6}>
          <TenantApplicationsWidget
            tenantId={tenantId || ''}
            isInAddMode={isInAddMode}
            className={classes.card}
          />
          <PricingCard className={classes.card} />
          <TenantUsageWidget className={classes.card} />
        </Grid>
      </Grid>
    </>
  );
};

export default Tenant;
