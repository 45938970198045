import React, { useState, useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { ArrowDropDown, Delete } from '@material-ui/icons';
import { useToasts } from 'react-toast-notifications';
import { StyledCard, StyledFormDialog } from 'advanext-components';
import { useEntities } from 'Hooks';
import { formatNumber, sleep } from 'Utilities';
import { Order } from 'Types';
import { headCells } from './constants';
import DDSummary from './DDSummary';
import { useAsyncCallback } from 'react-async-hook';
import { processSheetApi } from 'Api';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { TenantProviderContext } from 'Views/providers/Tenant';
import {
  EntityType,
  GenericNectarData,
  NectarEntity,
} from 'advanext-models/nectar/entity';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  row: {
    cursor: 'pointer',
  },
  box: {
    minWidth: '500px',
    marginBottom: '40px',
  },
  reviewUploadContainer: {
    padding: '8px 12px',
  },
});

type Props = {
  companyId: string;
};

const CompanyDD = ({ companyId }: Props): JSX.Element => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<string>();
  const [selectedReview, setSelectedReview] = useState<
    NectarEntity<GenericNectarData> | undefined
  >(undefined);
  const [reviewToDelete, setReviewToDelete] = useState<
    NectarEntity<GenericNectarData> | undefined
  >(undefined);
  const [googleSheetLink, setGoogleSheetLink] = useState<string>('');
  const { compoundTenantId } = useContext(TenantProviderContext);

  const {
    entities: reviewEntities,
    loadMore,
    remove,
  } = useEntities<GenericNectarData>(compoundTenantId, companyId, {
    entityType: EntityType.SUMMARY,
    fetchAll: true,
  });

  const handleRequestSort = (property: string): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const handleOpenDialog = useCallback(
    (review: NectarEntity<GenericNectarData>) => setReviewToDelete(review),
    [setReviewToDelete],
  );

  const handleCloseDialog = useCallback(() => {
    setReviewToDelete(undefined);
  }, [setReviewToDelete]);

  const compoundCompanyId = CompoundCompanyId.fromValue(companyId);

  const sendGoogleSheet = useAsyncCallback(async () => {
    const sheetResponse = await processSheetApi({
      compoundTenantId,
      compoundCompanyId,
      link: googleSheetLink,
    });
    if (typeof sheetResponse === 'object') {
      addToast(`${sheetResponse?.errorName} - ${sheetResponse?.errorMsg}`, {
        appearance: 'error',
      });
    } else {
      addToast(sheetResponse, {
        appearance: 'success',
      });
      setGoogleSheetLink('');
      await sleep(2000);
      await loadMore(true);
    }
  });

  return (
    <>
      {selectedReview ? (
        <DDSummary
          data={selectedReview}
          onClose={(): void => setSelectedReview(undefined)}
          onRemove={(): void => handleOpenDialog(selectedReview)}
        />
      ) : (
        <>
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledCard title="">
              <Grid
                container
                spacing={3}
                className={classes.reviewUploadContainer}
              >
                <Grid item md={7} style={{ fontSize: '14px' }}>
                  To add a new review, please paste a link to the google sheet.
                  Please note, it will also update Financial Statements and Bank
                  Statements. If you need to update the information of the
                  existing review, you should remove the old one first and add
                  it as a new review.
                </Grid>
                <Grid item md={3}>
                  <TextField
                    multiline
                    variant="outlined"
                    value={googleSheetLink}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setGoogleSheetLink(e.target.value);
                    }}
                    type="text"
                    style={{
                      width: '100%',
                    }}
                    inputProps={{
                      style: {
                        fontSize: '14px',
                      },
                    }}
                  />
                </Grid>
                <Grid item md={2} style={{ margin: 'auto' }}>
                  <Button
                    variant="contained"
                    disabled={!googleSheetLink || sendGoogleSheet.loading}
                    onClick={sendGoogleSheet.execute}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </StyledCard>
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledCard title="Previous reviews">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map(({ id, label, style, isSortable }) =>
                      isSortable ? (
                        <TableCell
                          component="th"
                          sortDirection={orderBy === id ? order : false}
                          key={id}
                          style={style}
                        >
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : Order.ASC}
                            onClick={(): void => {
                              handleRequestSort(id);
                            }}
                            IconComponent={ArrowDropDown}
                          >
                            <strong>{label}</strong>
                          </TableSortLabel>
                        </TableCell>
                      ) : (
                        <TableCell component="th" key={id} style={style}>
                          <strong>{label}</strong>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewEntities?.length ? (
                    reviewEntities?.map((value, idx) => {
                      const e = value?.content?.elements;

                      return (
                        <TableRow
                          key={idx}
                          onClick={(): void => setSelectedReview(value)}
                          className={classes.row}
                        >
                          <TableCell>
                            {dayjs(
                              e?.find((el) => el.id === 'creation_date')?.value,
                            ).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell>
                            {
                              e?.find((el) => el.id === 'selected_rating')
                                ?.value
                            }
                          </TableCell>
                          <TableCell>
                            {
                              e?.find(
                                (el) =>
                                  el.id === 'approval_product_risk_admin_2',
                              )?.value
                            }
                          </TableCell>
                          <TableCell>
                            {e?.find((el) => el.id === 'risk_admin_1')?.value}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              e?.find(
                                (el) =>
                                  el.id ===
                                  'approval_credit_limit_risk_admin_2',
                              )?.value,
                            )}
                          </TableCell>
                          <TableCell>
                            <Delete
                              onClick={(
                                event: React.MouseEvent<SVGSVGElement>,
                              ): void => {
                                event.stopPropagation();
                                handleOpenDialog(value);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1">
                          No reviews to show.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledCard>
          </Box>
        </>
      )}
      <StyledFormDialog
        isOpen={!!reviewToDelete}
        onClose={handleCloseDialog}
        onSave={async (): Promise<void | undefined> => {
          handleCloseDialog();
          if (reviewToDelete) {
            await remove(reviewToDelete.entityId);
            setSelectedReview(undefined);
            await loadMore(true);
          }
        }}
        header="Remove Review"
        confirmBtnName="Remove"
      >
        <Box className={classes.box}>
          <>
            <span>
              Please confirm if you really want to remove the selected review.
            </span>
            <span>
              Please note, this will not affect Financial Statements and Bank
              Statements. If you need to remove Financial Statements and Bank
              Statements, they have to be deleted manually on the corresponding
              pages.
            </span>
          </>
        </Box>
      </StyledFormDialog>
    </>
  );
};

export default CompanyDD;
