import React, { useState, useCallback } from 'react';
import { Field } from 'formik';
import { Button, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  StyledDropzone,
  FormInput,
  StyledFormDialog,
  FormSelect,
} from 'advanext-components';

import { OnCreateEntityEvent } from 'Types';
import { entityStatusesOptions, documentTypeOptions } from 'Utilities';
import { notEmpty } from 'Utilities/validators';
import { EntityMetadata } from 'advanext-models/nectar/entity';

const useStyles = makeStyles((theme) => ({
  openModalButton: {
    float: 'right',
    backgroundColor: '#262B32',
    color: 'white',
    padding: '5px 55px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#262B32',
      color: 'white',
      opacity: '80%',
    },
  },
  dialogText: {
    color: '#262B32',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  infoBlock: {
    display: 'inline-flex',
    marginTop: '10px',
    fontSize: '14px',
    padding: '2px 15px',
    border: '1px solid',
    borderRadius: '20px',
  },
  inputField: {
    display: 'box',
  },
}));

type Props = {
  onCreate: (event: OnCreateEntityEvent) => void;
};

const EntityUploader = ({ onCreate }: Props): JSX.Element => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [file, setFile] = useState<File>();

  const clearData = useCallback(() => {
    setFile(undefined);
  }, [setFile]);

  const handleOpenDialog = useCallback(
    () => setIsDialogOpen(true),
    [setIsDialogOpen],
  );

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setTimeout(() => clearData(), 500);
  }, [setIsDialogOpen, clearData]);

  return (
    <>
      <Button onClick={handleOpenDialog} className={classes.openModalButton}>
        Upload Document
      </Button>
      <StyledFormDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={({ status, documentType, comment }): void => {
          if (!file) return;

          const metadata = new EntityMetadata({ status, comment });
          onCreate({ file, documentType, metadata });
        }}
        initialValues={{}}
        header="Upload Document"
        confirmBtnName="Upload"
      >
        <DialogContentText className={classes.dialogText}>
          Upload File
        </DialogContentText>
        <StyledDropzone
          file={file}
          onDropAccepted={([file]): void => {
            setFile(file);
          }}
          acceptTypes={[
            'application/pdf',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/x-csv',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'image/png',
            'image/jpeg',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/plain',
            'text/html',
            'application/zip',
            'application/octet-stream',
            'application/x-zip-compressed',
            'multipart/x-zip',
            'message/rfc822', // for .eml type files
          ]}
        />
        <Field
          id="documentType"
          name="documentType"
          label="Document Type"
          placeholder="Select Document Type"
          component={FormSelect}
          options={documentTypeOptions}
          validate={notEmpty}
        />
        <Field
          id="status"
          name="status"
          label="Status"
          placeholder="Select Status"
          component={FormSelect}
          options={entityStatusesOptions}
          validate={notEmpty}
        />
        <Field
          id="comment"
          name="comment"
          label="Comment (optional)"
          component={FormInput}
          size="small"
          variant="outlined"
          fullWidth
        />
      </StyledFormDialog>
    </>
  );
};

export default EntityUploader;
