import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { PageTitle } from 'advanext-components';

import { usePolicy, useSelectors } from 'Hooks';
import { IsOwner } from 'Policies';
import { user as userSelector } from 'Redux/models/Auth';
import { TenantMembersWidget } from 'Views/widgets';

const Members = (): JSX.Element => {
  const [isOwner] = usePolicy([IsOwner]);
  const [user] = useSelectors([userSelector]);

  return (
    <>
      <PageTitle
        title="Team Members"
        subtitle={
          <Typography variant="subtitle1" color="textSecondary">
            Invite new people to your team in Nectar.
          </Typography>
        }
      />

      {user && !user.tenant && (
        <Alert severity="warning">You do not belong to any tenant.</Alert>
      )}

      {user && user.tenant && (
        <TenantMembersWidget isOwner={isOwner} tenantId={user.tenant} />
      )}
    </>
  );
};

export default Members;
