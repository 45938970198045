import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CreditRiskRatingAE } from 'advanext-models/nectar/score';
import { getValueOrNA } from 'Utilities';

type Props = {
  partial?: boolean;
  withTimestamp?: boolean;
  score?: number;
  rating?: CreditRiskRatingAE;
  created?: Date;
};

const useStyles = makeStyles({
  wrapper: {
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '15px',
  },
  withoutTimestamp: {
    paddingBottom: '36px',
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
  },
  scoreRating: {
    marginRight: '30px',
    fontSize: '25px',
    height: '41px',
    '&.partial': {
      fontSize: '15px',
    },
    '& > span': {
      color: '#676a6c',
    },
  },
  timestamp: {
    fontSize: '14px',
    '& > span': {
      color: '#676a6c',
    },
  },
});

const ScoringResult = ({
  partial = false,
  withTimestamp = true,
  score,
  rating,
  created,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item md={6}>
      <div
        className={cn(classes.wrapper, {
          [classes.withoutTimestamp]: !withTimestamp,
        })}
      >
        <div className={classes.data}>
          <div
            className={cn(classes.scoreRating, {
              partial: partial,
            })}
          >
            {partial ? 'Partial' : 'Total'} Score: <span>{score ?? '-'}</span>
          </div>
          <div
            className={cn(classes.scoreRating, {
              partial: partial,
            })}
          >
            {partial ? 'Partial' : 'Total'} Rating: <span>{rating ?? '-'}</span>
          </div>
        </div>
        {withTimestamp && (
          <div className={classes.timestamp}>
            Timestamp:{' '}
            <span>
              {created
                ? dayjs(created).format('DD MMM YYYY HH:mm')
                : getValueOrNA(created)}
            </span>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default ScoringResult;
