import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import {
  Link,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  TableSortLabel,
  Chip,
  makeStyles,
  IconButton,
  ClickAwayListener,
  TextField,
} from '@material-ui/core';
import { Search, ArrowDropDown } from '@material-ui/icons';
import { LoadingAlert, PageTitle, StyledCard } from 'advanext-components';
import Status from 'advanext-models/nectar/uwtask/Status';
import { TenantProviderContext } from 'Views/providers/Tenant';

import {
  formatDate,
  getTaskStatus,
  getTaskType,
  ONBOARDED_COMPANIES_FILTER,
} from 'Utilities';

import { Order } from 'Types';
import { ROUTES } from 'Constants';

import UnderwritingTasks from 'Views/pages/owner/Company/UnderwritingTasks';
import columns from './columns.json';
import { UWTask } from 'advanext-models/nectar/uwtask';
import { useUWTasks, useMonitoredCompaniesInfo, useEntities } from 'Hooks';
import { SHOW_ALL_STATUS, TASK_TYPE_SORT_ORDER } from './constants';
import { Company } from 'advanext-models/advanext/company';
import { EntityType } from 'advanext-models/nectar/entity';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  trReadyToReview: {
    backgroundColor: 'rgba(144, 202, 249, 0.2)',
  },
  trBordered: {
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  trExtraBordered: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
    '&:last-child td': {
      borderBottom: 0,
    },
  },
  td: {
    whiteSpace: 'break-spaces',
    overflow: 'visible',
    overflowWrap: 'break-word',
  },
  tdInnerTable: {
    padding: 0,
  },
  status: {
    color: '#000000',
    minWidth: '100px',
  },
  unprocessedStatus: {
    backgroundColor: 'rgba(255, 152, 0, 0.4)',
    border: '1px solid #FF9800',
  },
  progressStatus: {
    backgroundColor: 'rgba(33, 150, 243, 0.4)',
    border: '1px solid #2196F3',
  },
  completedStatus: {
    backgroundColor: 'rgba(76, 175, 80, 0.4);',
    border: '1px solid #4CAF50',
  },
  declinedStatus: {
    backgroundColor: 'rgba(244, 67, 54, 0.4)',
    border: '1px solid #F44336;',
  },
  notRequiredStatus: {
    backgroundColor: 'rgba(171, 172, 173, 0.5)',
    border: '1px solid #ABACAD',
  },
});

const SIGN_UP_DATE_SORT_KEY = 'signUpDate';

const CompanyDueDiligence = (): JSX.Element => {
  const classes = useStyles();

  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<
    keyof UWTask | typeof SIGN_UP_DATE_SORT_KEY
  >(SIGN_UP_DATE_SORT_KEY);
  const [isCompanyOpen, setCompanyOpen] = useState(false);
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [companyValue, setCompanyValue] = useState('');
  const [statusValue, setStatusValue] = useState<
    Status | typeof SHOW_ALL_STATUS
  >(Status.ToReview);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  // eslint-disable-next-line
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();

  const { push } = useHistory();

  const handleOpenCompany = useCallback(
    (id) => push(ROUTES.Company.replace(':companyId', id)),
    [push],
  );

  const handleRequestSort = (property: keyof UWTask): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };
  const { compoundTenantId } = useContext(TenantProviderContext);

  const { data: companiesInfo, isLoading: isMonitoredCompaniesLoading } =
    useMonitoredCompaniesInfo();

  const { entities, loading: isEntitiesLoading } = useEntities<Company>(
    compoundTenantId,
    undefined,
    {
      entityType: EntityType.COMPANY_INFORMATION,
      filterExpression: ONBOARDED_COMPANIES_FILTER,
    },
  );

  const { data: uwTasks, isLoading: isUWTasksLoading } = useUWTasks();

  const isLoading = useMemo(() => {
    return isUWTasksLoading || isMonitoredCompaniesLoading || isEntitiesLoading;
  }, [isUWTasksLoading, isMonitoredCompaniesLoading, isEntitiesLoading]);

  const groupedData = useMemo((): { [key: string]: Array<UWTask> } => {
    const grouped = uwTasks
      ?.filter((task) => {
        if (statusValue === SHOW_ALL_STATUS) return task;
        else return task.status === statusValue;
      })
      .sort((a, b) => {
        if (orderBy === 'created') {
          return order === Order.ASC
            ? new Date(a.created).getTime() - new Date(b.created).getTime()
            : new Date(b.created).getTime() - new Date(a.created).getTime();
        }
        return 1;
      })
      .reduce(
        (acc, item) => {
          const key = item.company?.toString();
          const groupData = acc[key] || [];
          acc[key] = groupData.concat([item]);
          return acc;
        },
        {} as {
          [key: string]: Array<UWTask>;
        },
      );

    const sortedByTaskType = Object.keys(grouped).reduce(
      (acc, key) => {
        acc[key] = grouped[key].sort((a, b) => {
          return (
            TASK_TYPE_SORT_ORDER.indexOf(a.uwTaskType) -
            TASK_TYPE_SORT_ORDER.indexOf(b.uwTaskType)
          );
        });
        return acc;
      },
      {} as {
        [key: string]: Array<UWTask>;
      },
    );

    return sortedByTaskType;
  }, [uwTasks, statusValue, orderBy, order]);

  const companiesData = useMemo((): {
    [key: string]: {
      name: string;
      isAllStatusToReview: boolean;
      signUpDate: string;
    };
  } => {
    const dataFromEntities = entities.map((entity) => ({
      id: entity.entityId,
      signUpDate: entity.content?.created?.toLocaleDateString() as string,
    }));

    const companyMap = uwTasks
      ?.filter((task) => {
        if (statusValue === SHOW_ALL_STATUS) return task;
        else return task.status === statusValue;
      })
      .reduce(
        (acc, item) => {
          const key = item.company?.toString();
          const isAllStatusToReview =
            uwTasks
              .filter(
                (task) => task.company?.toString() === item.company?.toString(),
              )
              .findIndex((task) => task.status === Status.Required) === -1;
          const match = companiesInfo?.find(
            (e) => e.id === item.company.toString(),
          );
          const signUpDate =
            dataFromEntities.find(
              (entity: { id: string; signUpDate: string }) => entity.id === key,
            )?.signUpDate ?? '';

          acc[key] = {
            name: match?.name ?? '',
            isAllStatusToReview,
            signUpDate,
          };
          return acc;
        },
        {} as {
          [key: string]: {
            name: string;
            isAllStatusToReview: boolean;
            signUpDate: string;
          };
        },
      );

    return companyMap;
  }, [uwTasks, entities, companiesInfo, statusValue]);

  return (
    <>
      <PageTitle title="Company Due Diligence" />
      <StyledCard title="Open Underwriting Tasks">
        {isLoading ? (
          <LoadingAlert text="Underwriting tasks are loading, please wait" />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map(({ id, label, sortable, style }) =>
                  sortable ? (
                    <TableCell
                      component="th"
                      style={style}
                      key={id}
                      sortDirection={orderBy === id ? order : undefined}
                    >
                      {id === 'company' && (
                        <ClickAwayListener
                          onClickAway={(): void => {
                            setCompanyOpen(false);
                          }}
                        >
                          <IconButton
                            onClick={(): void => {
                              setCompanyOpen(true);
                            }}
                            style={{ backgroundColor: 'transparent' }}
                            disableRipple
                            size="small"
                          >
                            <Search />
                            {isCompanyOpen && (
                              <TextField
                                value={companyValue}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ): void => {
                                  setCompanyValue(e.target.value);
                                }}
                                type="search"
                              />
                            )}
                          </IconButton>
                        </ClickAwayListener>
                      )}
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : Order.ASC}
                        onClick={(): void => {
                          handleRequestSort(id as keyof UWTask);
                        }}
                        IconComponent={ArrowDropDown}
                      >
                        {id === 'company' && isCompanyOpen ? null : (
                          <strong>{label}</strong>
                        )}
                      </TableSortLabel>
                    </TableCell>
                  ) : id === 'status' ? (
                    <TableCell key={id} style={style}>
                      <ClickAwayListener
                        onClickAway={(): void => {
                          setStatusOpen(false);
                        }}
                      >
                        <IconButton
                          onClick={(): void => {
                            setStatusOpen(true);
                          }}
                          style={{ backgroundColor: 'transparent' }}
                          disableRipple
                          size="small"
                        >
                          <Search />
                          {isStatusOpen && (
                            <TextField
                              select
                              value={statusValue}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>,
                              ): void => {
                                setStatusValue(event.target.value as Status);
                              }}
                              SelectProps={{
                                MenuProps: { disablePortal: true },
                              }}
                            >
                              {[SHOW_ALL_STATUS, ...Object.values(Status)].map(
                                (status) => (
                                  <MenuItem value={status} key={status}>
                                    {getTaskStatus(status)}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          )}
                        </IconButton>
                      </ClickAwayListener>
                      {isStatusOpen ? null : <strong>{label}</strong>}
                    </TableCell>
                  ) : (
                    <TableCell key={id} style={style}>
                      <strong>{label}</strong>
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData)
                .filter(
                  (companyId) =>
                    companiesData[companyId].name &&
                    companiesData[companyId].name
                      .toLowerCase()
                      .toString()
                      .includes(companyValue.toLowerCase()),
                )
                .sort((a, b) => {
                  if (orderBy === 'company') {
                    return order === Order.DESC
                      ? companiesData[b].name.localeCompare(
                          companiesData[a].name,
                        )
                      : companiesData[a].name.localeCompare(
                          companiesData[b].name,
                        );
                  }
                  if (orderBy === 'signUpDate') {
                    return new Date(companiesData[b].signUpDate).getTime() >
                      new Date(companiesData[a].signUpDate).getTime()
                      ? 1
                      : -1;
                  } else return 0;
                })
                .map((companyId, index) => (
                  <TableRow
                    key={index}
                    className={cn('cdd-company-block', {
                      ready: companiesData[companyId].isAllStatusToReview,
                      [classes.trReadyToReview]:
                        companiesData[companyId].isAllStatusToReview,
                      [classes.trExtraBordered]:
                        index < Object.keys(groupedData).length - 1,
                    })}
                    onClick={(): void => {
                      setSelectedCompanyId(companyId);
                      setIsTaskModalOpen(true);
                    }}
                  >
                    <TableCell colSpan={4} className={classes.tdInnerTable}>
                      <Table>
                        <TableBody>
                          {groupedData[companyId].map((task, index) => (
                            <TableRow
                              key={task.uwTaskId}
                              className={cn({
                                [classes.trBordered]:
                                  index < groupedData[companyId].length - 1,
                              })}
                            >
                              <TableCell
                                className={classes.td}
                                style={{ width: '14%' }}
                              >
                                {formatDate(task.created)}
                              </TableCell>
                              <TableCell
                                className={classes.td}
                                style={{ width: '28%' }}
                              >
                                {getTaskType(task.uwTaskType)}
                              </TableCell>
                              <TableCell
                                className={classes.td}
                                style={{ width: '21%' }}
                              >
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  label={getTaskStatus(task.status)}
                                  className={cn(classes.status, {
                                    [classes.unprocessedStatus]:
                                      task.status === Status.Required ||
                                      task.status === Status.Incomplete ||
                                      task.status === Status.PartiallyApproved,
                                    [classes.progressStatus]:
                                      task.status === Status.ToReview,
                                    [classes.completedStatus]:
                                      task.status === Status.Completed,
                                    [classes.declinedStatus]:
                                      task.status === Status.Declined,
                                    [classes.notRequiredStatus]:
                                      task.status === Status.NotRequired,
                                  })}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.td}
                                style={{ width: '35%' }}
                              >
                                {task.comment}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell className={classes.td}>
                      <Link
                        underline="always"
                        onClick={(
                          event: React.MouseEvent<
                            HTMLAnchorElement,
                            MouseEvent
                          >,
                        ): void => {
                          event.stopPropagation();
                          handleOpenCompany(companyId);
                        }}
                      >
                        {companiesData[companyId].name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        <UnderwritingTasks
          isOpen={isTaskModalOpen}
          onClose={(): void => setIsTaskModalOpen(false)}
          companyId={selectedCompanyId}
        />
      </StyledCard>
    </>
  );
};

export default CompanyDueDiligence;
