export const COMPANY_ROUTES = {
  Overview: '/overview',
  Reports: '/reports',
  DueDiligence: '/due_diligence',
  FinancialStatements: '/financial_statements',
  BankStatements: '/bank_statements',
  CompanyDueDiligence: '/company_due_diligence',
  Monitoring: '/monitoring',
  Documents: '/documents',
};
