import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { ErrorAlert } from 'advanext-components';
import { makeStyles } from '@material-ui/core/styles';
import { OpenInNew } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { PageTitle } from 'advanext-components';

import {
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import { user as userSelector } from 'Redux/models/Auth';
import { useSelectors, useTenantApplicationEvents } from 'Hooks';
import { TenantApplicationEvent } from 'advanext-models/nectar/tenant';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 0,
    },
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const Application = (): JSX.Element => {
  const classes = useStyles();
  const [user] = useSelectors([userSelector]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(null); // eslint-disable-line
  const [showDetails, setShowDetails] = useState<TenantApplicationEvent | null>(
    null,
  );

  const { applicationId } = useParams<{ applicationId: string }>();

  const {
    data,
    error,
    isLoading,
    lastEvaluatedKey: newLastEvaluatedKey,
  } = useTenantApplicationEvents(
    user?.tenant || '',
    applicationId,
    lastEvaluatedKey,
  );

  return (
    <>
      <PageTitle
        title="Application"
        subtitle={
          <Typography variant="subtitle1" color="textSecondary">
            {applicationId}
          </Typography>
        }
      />

      <Dialog
        open={!!showDetails}
        onClose={(): void => setShowDetails(null)}
        title="Event Payload"
      >
        <DialogContent>
          <pre>{JSON.stringify(showDetails?.payload, null, 4)}</pre>
        </DialogContent>
      </Dialog>

      <Card className={classes.card}>
        {isLoading ? (
          <Skeleton variant="rect" width="100%" height={270} />
        ) : error ? (
          <ErrorAlert text="Failed to load applications data" />
        ) : (
          <>
            <CardHeader title="Application Events" />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Event ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((event) => (
                    <TableRow key={event.tenantApplicationEventId}>
                      <TableCell>{event.tenantApplicationEventId}</TableCell>
                      <TableCell>{event.status}</TableCell>
                      <TableCell>
                        {new Date(event.created).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(): void => setShowDetails(event)}>
                          <OpenInNew />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                disabled={!newLastEvaluatedKey || isLoading}
                onClick={(): void => setLastEvaluatedKey(newLastEvaluatedKey)}
              >
                Load More
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </>
  );
};

export default Application;
