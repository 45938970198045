import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { Logo } from 'advanext-components';
import ErrorContext from '../ErrorContext';
import Image from './background.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: '#262B32',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    position: 'absolute',
    width: '100%',
  },
  form: {
    maxWidth: theme.spacing(50),
    minWidth: theme.spacing(38),
    background: theme.palette.background.default,
    margin: theme.spacing(2, 'auto', 0, 'auto'),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
  },
  logo: {
    opacity: 0.8,
    margin: '0 auto',
    height: theme.spacing(5),
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    background: `url(${Image}) no-repeat`,
    backgroundSize: 'cover',
    position: 'absolute',
    filter: 'blur(5px)',
    top: 0,
    left: 0,
    opacity: 0.4,
  },
  alert: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  children: React.ReactNode;
  onSubmit?: (event: React.FormEvent) => void;
};

const Layout = ({ children, onSubmit }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.backgroundImage} />
      <div className={classes.container}>
        <Logo className={classes.logo} />
        <form onSubmit={onSubmit} className={classes.form}>
          <ErrorContext.Consumer>
            {(error): JSX.Element =>
              error ? (
                <Alert className={classes.alert} severity="error">
                  {error}
                </Alert>
              ) : (
                <></>
              )
            }
          </ErrorContext.Consumer>
          <Grid container spacing={3} direction="column">
            {children}
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Layout;
