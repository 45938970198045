import React from 'react';
import { StyledCard } from 'advanext-components';
import { Loan, ProductTypeDisplayName } from 'advanext-models/advanext/loan';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { headCells } from './constants';
import { formatDate, formatNumber, getValueOrNA } from 'Utilities';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  noData: {
    textAlign: 'center',
    fontSize: '16px',
    color: 'grey',
  },
});

type Props = {
  isLoading: boolean;
  loans: (Loan | undefined)[];
};

const Deals = ({ isLoading, loans }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <StyledCard title="Deals">
      {isLoading ? (
        <CircularProgress size={40} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, label, style }) => (
                <TableCell component="th" key={id} style={style}>
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loans.map((e?: Loan) => (
              <TableRow key={e?.loanId}>
                <TableCell>{e?.loanId}</TableCell>
                <TableCell>{formatDate(e?.activated)}</TableCell>
                <TableCell>
                  {e ? ProductTypeDisplayName[e?.productType] : getValueOrNA()}
                </TableCell>
                <TableCell>{formatNumber(e?.amount)}</TableCell>
                <TableCell>{getValueOrNA(e?.state)}</TableCell>
                <TableCell>{getValueOrNA(e?.creditArrangementId)}</TableCell>
              </TableRow>
            ))}
            {!loans?.length && (
              <TableRow>
                <TableCell colSpan={6} className={classes.noData}>
                  Nothing to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </StyledCard>
  );
};

export default Deals;
