import React from 'react';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Table from './Table';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type Props = {
  isOpen: boolean;
  isMonitored?: boolean;
  companyId?: string;
  onClose: () => void;
};

const UnderwritingTasks = ({
  isOpen,
  isMonitored = true,
  companyId,
  onClose,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h4">Underwriting Tasks</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Table isMonitored={isMonitored} companyId={companyId} />
      </DialogContent>
    </Dialog>
  );
};

export default UnderwritingTasks;
