import { SignatoryRightsLevel } from 'advanext-models/nectar';

export const Translations: Record<string, string> = {
  [SignatoryRightsLevel.UNKNOWN]: 'Unknown',
  [SignatoryRightsLevel.NO_SIGNATORY_RIGHTS]: 'No Signatory Rights',
  [SignatoryRightsLevel.LIMITED_PROCURATION_RIGHTS]:
    'Limited Procuration Rights',
  [SignatoryRightsLevel.FULL_PROCURATION_RIGHTS]: 'Full Procuration Rights',
  [SignatoryRightsLevel.LIMITED_SIGNATORY_RIGHTS]: 'Limited Signatory Rights',
  [SignatoryRightsLevel.FULL_SIGNATORY_RIGHTS]: 'Full Signatory Rights',
};
