import { CompanyBankAccount } from 'advanext-models/advanext/company';
import { getValueOrNA, formatDate, getYesNoValue } from 'Utilities';

export const cells: Array<{
  id: keyof CompanyBankAccount | 'title';
  label: string;
  render: (
    e: CompanyBankAccount,
    index: number,
  ) => string | number | JSX.Element;
}> = [
  {
    id: 'title',
    label: '',
    render: (e, index): string => `Account ${index + 1}`,
  },
  {
    id: 'bankAccountId',
    label: 'ID',
    render: (e): string => e.bankAccountId,
  },
  {
    id: 'name',
    label: 'Name',
    render: (e): string | number => getValueOrNA(e.name),
  },
  {
    id: 'bank',
    label: 'Bank',
    render: (e): string | number => getValueOrNA(e.bank),
  },
  {
    id: 'iban',
    label: 'IBAN',
    render: (e): string | number => getValueOrNA(e.iban),
  },
  {
    id: 'bic',
    label: 'BIC',
    render: (e): string | number => getValueOrNA(e.bic),
  },
  {
    id: 'currency',
    label: 'Currency',
    render: (e): string | number => getValueOrNA(e.currency),
  },
  {
    id: 'direction',
    label: 'Direction',
    render: (e): string | number => getValueOrNA(e.direction),
  },
  {
    id: 'created',
    label: 'Created',
    render: (e): string => formatDate(e.created),
  },
  {
    id: 'bankCompany',
    label: 'Bank Account Company',
    render: (e): string | number => getValueOrNA(e.bankCompany),
  },
  {
    id: 'qrEnabled',
    label: 'QR IBAN Enabled',
    render: (e): string => getYesNoValue(e.qrEnabled),
  },
  {
    id: 'updated',
    label: 'Bank Account Updated',
    render: (e): string => formatDate(e.updated),
  },
];
