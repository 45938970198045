import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import type { TenantApplication } from 'advanext-models/nectar/tenant';

import {
  FormState,
  INITIAL_FORM_STATE,
  isValid,
  formStateApplyEvent,
  hasError as formHasError,
  getError as formGetError,
} from 'Services/forms';

import { APPLICATION_SOURCES } from 'Constants';
import { FormEvent } from 'Types';

const schema = {
  tenantApplicationId: { required: true, tenantId: true },
  url: { required: true, url: true },
  source: { required: true },
};

type Props = {
  application?: TenantApplication | null;
  onChange: (formState: FormState) => void;
};

const TenantApplicationForm = ({
  application,
  onChange,
}: Props): JSX.Element => {
  const [formState, setFormState] = useState<FormState>({
    ...INITIAL_FORM_STATE,
    values: application ? { ...application } : ({} as TenantApplication),
    isValid: isValid({ values: application || {} } as FormState, schema),
  });

  const handleInputChange = useCallback(
    (event: FormEvent) => {
      setFormState(formStateApplyEvent(event, formState, schema));
    },
    [formState],
  );

  const hasError = useCallback(
    (field: string) => formHasError(field, formState),
    [formState],
  );
  const getError = useCallback(
    (field: string) => formGetError(field, formState),
    [formState],
  );

  // Renew the form in case incoming application has changed without form unmounting
  useEffect(() => {
    setFormState({
      ...INITIAL_FORM_STATE,
      values: application ? { ...application } : {},
      isValid: isValid({ values: application || {} } as FormState, schema),
    });
  }, [application]);

  useEffect(() => {
    onChange(formState as FormState);
  }, [formState, onChange]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Application Identifier</TableCell>
          <TableCell>
            <TextField
              error={hasError('tenantApplicationId')}
              fullWidth
              helperText={getError('tenantApplicationId')}
              label="Application Identifier"
              margin="dense"
              name="tenantApplicationId"
              onChange={handleInputChange}
              required
              value={formState.values.tenantApplicationId || ''}
              disabled={!!application}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Webhook URL</TableCell>
          <TableCell>
            <TextField
              error={hasError('url')}
              fullWidth
              helperText={getError('url')}
              label="Webhook URL"
              margin="dense"
              name="url"
              onChange={handleInputChange}
              required
              value={formState.values.url || ''}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Application Source</TableCell>
          <TableCell>
            <TextField
              select
              error={hasError('source')}
              fullWidth
              helperText={getError('source')}
              label="Application Source"
              margin="dense"
              name="source"
              onChange={handleInputChange}
              required
              value={formState.values.source || ''}
            >
              {Object.entries(APPLICATION_SOURCES).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Application Username</TableCell>
          <TableCell>
            <TextField
              error={hasError('username')}
              fullWidth
              type="password"
              helperText={getError('username')}
              label="Application Username"
              margin="dense"
              name="username"
              onChange={handleInputChange}
              required
              value={formState.values.username || ''}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Application Password</TableCell>
          <TableCell>
            <TextField
              error={hasError('password')}
              fullWidth
              type="password"
              helperText={getError('password')}
              label="Application Password"
              margin="dense"
              name="password"
              onChange={handleInputChange}
              required
              value={formState.values.password || ''}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TenantApplicationForm;
