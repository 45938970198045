import React from 'react';
import { Cancel, CheckCircle } from '@material-ui/icons';

type Props = {
  isCompleted: boolean;
  isMonitored: boolean;
  size?: 'default' | 'small' | 'large';
};

const TasksStatusIcon = ({
  isCompleted,
  isMonitored,
  size,
}: Props): JSX.Element =>
  isCompleted ? (
    <CheckCircle
      fontSize={size}
      style={{ color: isMonitored ? '#4CAF50' : '#C4C4C4' }}
    />
  ) : (
    <Cancel
      fontSize={size}
      style={{ color: isMonitored ? '#F9AD0B' : '#C4C4C4' }}
    />
  );

export default TasksStatusIcon;
