import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { StyledCard } from 'advanext-components';
import { formatDate, formatMoney } from 'Utilities';
import { GenericNectarData, NectarEntity } from 'advanext-models/nectar/entity';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '15px',
    },
  },
  bankButton: {
    width: '60px',
    height: '30px',
    padding: 0,
    backgroundColor: '#c4c4c4',
    border: '1px solid #262B32',
    fontSize: '18px',
    fontWeight: 400,
    textTransform: 'none',
  },
}));

type Props = {
  data: NectarEntity<GenericNectarData>;
  onClose: () => void;
  onRemove: () => void;
};

const DDSummary = ({ data, onClose, onRemove }: Props): JSX.Element => {
  const classes = useStyles();
  const summary = data?.content?.elements;

  return (
    <div className={classes.wrapper}>
      <Button className={classes.bankButton} onClick={(): void => onClose()}>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <StyledCard title="General">
            <Table>
              <colgroup>
                <col style={{ width: '34%' }} />
                <col style={{ width: '66%' }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>
                    {formatDate(
                      summary?.find((el) => el.id === 'creation_date')?.value,
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Security Type 1</TableCell>
                  <TableCell>
                    {summary?.find((el) => el.id === 'security_type_1')?.value}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Security Type 2</TableCell>
                  <TableCell>
                    {summary?.find((el) => el.id === 'security_type_2')?.value}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Security Valuation</TableCell>
                  <TableCell>
                    {formatMoney(
                      summary?.find((el) => el.id === 'security_valuation')
                        ?.value,
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>General remarks</TableCell>
                  <TableCell colSpan={3}>
                    {
                      summary?.find(
                        (el) => el.id === 'general_remarks_risk_admin_1',
                      )?.value
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledCard>
        </Grid>
        <Grid item md={4}>
          <StyledCard title="">
            <Table>
              <colgroup>
                <col style={{ width: '50%' }} />
                <col style={{ width: '50%' }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell>Google Sheets Link</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      target="_blank"
                      href={
                        summary?.find((el) => el.id === 'source_link')?.value ??
                        ''
                      }
                    >
                      Link
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Due Diligence File</TableCell>
                  <TableCell>
                    <Button variant="contained">Download</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledCard>
        </Grid>
      </Grid>
      <StyledCard title="Review Risk Admin 1">
        <Table>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Review Date</TableCell>
              <TableCell>
                {formatDate(
                  summary?.find((el) => el.id === 'review_date_risk_admin_1')
                    ?.value,
                )}
              </TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>
                {summary?.find((el) => el.id === 'risk_admin_1')?.value}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>
                {
                  summary?.find(
                    (el) => el.id === 'suggested_product_risk_admin_1',
                  )?.value
                }
              </TableCell>
              <TableCell>Credit Limit</TableCell>
              <TableCell>
                {formatMoney(
                  summary?.find(
                    (el) => el.id === 'suggested_credit_limit_risk_admin_1',
                  )?.value,
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assessment</TableCell>
              <TableCell colSpan={3}>
                {
                  summary?.find((el) => el.id === 'decision_risk_admin_1')
                    ?.value
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <StyledCard title="Review Risk Admin 2">
        <Table>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Review Date</TableCell>
              <TableCell>
                {formatDate(
                  summary?.find((el) => el.id === 'review_date_risk_admin_2')
                    ?.value,
                )}
              </TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>
                {summary?.find((el) => el.id === 'risk_admin_2')?.value}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>
                {
                  summary?.find(
                    (el) => el.id === 'approval_product_risk_admin_2',
                  )?.value
                }
              </TableCell>
              <TableCell>Credit Limit</TableCell>
              <TableCell>
                {formatMoney(
                  summary?.find(
                    (el) => el.id === 'approval_credit_limit_risk_admin_2',
                  )?.value,
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assessment</TableCell>
              <TableCell colSpan={3}>
                {
                  summary?.find((el) => el.id === 'decision_risk_admin_2')
                    ?.value
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <StyledCard title="Final Conclusion">
        <Table>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Rating</TableCell>
              <TableCell>
                {summary?.find((el) => el.id === 'selected_rating')?.value}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          style={{
            paddingRight: '50px',
            paddingLeft: '50px',
          }}
          onClick={(): void => onRemove()}
        >
          Delete review
        </Button>
      </Box>
    </div>
  );
};

export default DDSummary;
