import React, { useContext } from 'react';
import { EntityType } from 'advanext-models/nectar/entity';
import { Company, CompanyMemberInfo } from 'advanext-models/advanext/company';
import { User } from 'advanext-models/advanext/user';
import { CreditArrangement } from 'advanext-models/advanext/creditarrangement';
import { Loan } from 'advanext-models/advanext/loan';
import { NectarReport } from 'advanext-models/nectar/report';

import { Box, Grid } from '@material-ui/core';

import { useEntities } from 'Hooks';
import { LinkedOrMemberPerson } from 'Types';
import { TenantProviderContext } from 'Views/providers/Tenant';
import CompanyDataTable from './CompanyDataTable';
import ManagementInfo from './ManagementInfo';
import Survey from './Survey';
import SignUpDetails from './SignUpDetails';
import LinkedPersons from './LinkedPersons';
import CreditArrangements from './CreditArrangements';
import Deals from './Deals';
import Banking from './Banking';

type Props = {
  companyReport?: NectarReport;
  companyId: string;
};

const Overview = ({ companyReport, companyId }: Props): JSX.Element => {
  const { compoundTenantId } = useContext(TenantProviderContext);
  const { entities: companyEntites, loading: companyLoading } =
    useEntities<Company>(compoundTenantId, companyId, {
      entityType: EntityType.COMPANY_INFORMATION,
      fetchAll: true,
    });

  const { entities: userEntities, loading: userLoading } = useEntities<User>(
    compoundTenantId,
    companyId,
    {
      entityType: EntityType.USER_INFORMATION,
      fetchAll: true,
    },
  );

  const { entities: caEntities, loading: caLoading } =
    useEntities<CreditArrangement>(compoundTenantId, companyId, {
      entityType: EntityType.CREDIT_INFORMATION,
      fetchAll: true,
    });

  const { entities: loanEntities, loading: loanLoading } = useEntities<Loan>(
    compoundTenantId,
    companyId,
    {
      entityType: EntityType.LOAN,
      fetchAll: true,
    },
  );

  return (
    <>
      <CompanyDataTable
        companyData={companyReport?.companyData}
        industry={companyReport?.industry}
      />
      {companyReport?.managers && (
        <ManagementInfo
          managers={companyReport?.managers}
          country={companyReport?.companyData?.country}
          companyId={companyId}
        />
      )}
      <Grid container direction="row" spacing={1}>
        <Grid item md={6}>
          <Survey
            isLoading={companyLoading}
            survey={companyEntites?.[0]?.content?.survey}
          />
        </Grid>
        <Grid item md={6}>
          <SignUpDetails
            isLoading={companyLoading}
            company={companyEntites?.[0]?.content}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <LinkedPersons
          isLoading={companyLoading || userLoading}
          linkedPersons={[
            ...(companyEntites?.[0]?.content?.linkedPersons ?? []),
            ...(companyEntites?.[0]?.content?.members ?? []).reduce(
              (
                prev: LinkedOrMemberPerson[],
                member: CompanyMemberInfo,
              ): LinkedOrMemberPerson[] => {
                const userFromMember = userEntities?.find(
                  (entity) => entity?.entityId === member.userId,
                );
                if (userFromMember) {
                  return [
                    ...prev,
                    {
                      ...userFromMember.content,
                      signatoryRights: member.signatoryRightsLevel,
                    },
                  ] as LinkedOrMemberPerson[];
                }
                return prev;
              },
              [],
            ),
          ]}
        />
      </Box>
      <Box mt={2}>
        <CreditArrangements
          isLoading={caLoading}
          creditArrangements={caEntities.map((entity) => entity.content)}
        />
      </Box>
      <Box mt={2}>
        <Deals
          isLoading={loanLoading}
          loans={loanEntities?.map((entity) => entity.content)}
        />
      </Box>
      <Box mt={2}>
        <Banking
          isLoading={companyLoading}
          bankAccounts={companyEntites?.[0]?.content?.bankAccounts}
        />
      </Box>
    </>
  );
};

export default Overview;
