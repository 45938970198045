/* Amplify Configs */

export default {
  signUpFields: [
    {
      label: 'First name',
      key: 'given_name',
      required: true,
      placeholder: 'First Name',
      type: 'text',
      displayOrder: 10,
    },
    {
      label: 'Last Name',
      key: 'family_name',
      required: true,
      placeholder: 'Last Name',
      type: 'text',
      displayOrder: 20,
    },
    {
      label: 'Tenant',
      key: 'custom:tenant',
      required: true,
      placeholder: 'Tenant',
      type: 'text',
      displayOrder: 30,
      custom: true,
    },
    {
      label: 'Group',
      key: 'custom:group',
      required: true,
      placeholder: 'Group',
      type: 'text',
      displayOrder: 30,
      custom: true,
    },
  ],
};
