import { Dashboard, NotFound, Monitoring } from 'Views/pages/common';
import {
  Tenants,
  Tenant,
  Companies,
  Company,
  CompanyDueDiligence,
} from 'Views/pages/owner';
import { Application, Applications, Members } from 'Views/pages/admin';
import { ROUTES } from 'Constants';
import { UserGroup } from 'advanext-models/nectar';
import {
  Business,
  Visibility,
  CheckBox,
  SupervisorAccount,
  Apps,
  SupervisedUserCircle,
} from '@material-ui/icons';

const routes = [
  // Common routes
  {
    redirect: true,
    exact: true,
    from: '/index.html',
    to: ROUTES.Dashboard,
    access: UserGroup.USER,
  },
  {
    exact: true,
    component: Dashboard,
    path: ROUTES.Dashboard,
    access: UserGroup.USER,
  },
  {
    exact: true,
    component: Companies,
    path: ROUTES.Companies,
    access: UserGroup.USER,
  },
  {
    component: Company,
    path: ROUTES.Company,
    access: UserGroup.USER,
  },
  {
    exact: true,
    component: Monitoring,
    path: ROUTES.Monitoring,
    access: UserGroup.USER,
  },
  {
    exact: true,
    component: CompanyDueDiligence,
    path: ROUTES.Tasks,
    access: UserGroup.USER,
  },
  // Admin routes
  {
    exact: true,
    component: Members,
    path: ROUTES.AdminAccountMembers,
    access: UserGroup.ADMIN,
  },
  // Owner routes
  {
    exact: true,
    component: Tenants,
    path: ROUTES.OwnerTenants,
    access: UserGroup.OWNER,
  },
  {
    exact: true,
    component: Tenant,
    path: ROUTES.OwnerTenant,
    access: UserGroup.OWNER,
  },
  {
    exact: true,
    component: Applications,
    path: ROUTES.OwnerAccountApplications,
    access: UserGroup.OWNER,
  },
  {
    exact: true,
    component: Application,
    path: ROUTES.OwnerAccountApplication,
    access: UserGroup.OWNER,
  },
  // For other unknown routes redirect to NotFound
  {
    exact: true,
    component: NotFound,
    path: ROUTES.NotFound,
  },
  {
    redirect: true,
    to: ROUTES.NotFound,
  },
];

const clientNavItems = [
  {
    access: UserGroup.USER,
    section: undefined,
    items: [
      {
        id: 'companies',
        label: 'Companies',
        icon: Business,
        to: ROUTES.Companies,
      },
      {
        id: 'monitoring',
        label: 'Monitoring',
        icon: Visibility,
        to: ROUTES.Monitoring,
      },
      {
        id: 'tasks',
        label: 'Company Due Diligence',
        icon: CheckBox,
        to: ROUTES.Tasks,
      },
    ],
  },
];

const ownerNavItems = [
  {
    access: UserGroup.OWNER,
    section: 'Tenants Management',
    items: [
      {
        id: 'tenants',
        label: 'Tenants',
        icon: SupervisedUserCircle,
        to: ROUTES.OwnerTenants,
      },
      {
        id: 'applications',
        label: 'Applications',
        icon: Apps,
        to: ROUTES.OwnerAccountApplications,
      },
    ],
  },
];

const adminNavItems = [
  {
    access: UserGroup.ADMIN,
    section: 'Account Settings',
    items: [
      {
        id: 'members',
        label: 'Team Members',
        icon: SupervisorAccount,
        to: ROUTES.AdminAccountMembers,
      },
    ],
  },
];

export const navItems = [...clientNavItems, ...ownerNavItems, ...adminNavItems];

export default routes;
