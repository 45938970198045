import React, { useMemo, ReactElement } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import { CompoundCompanyId } from 'advanext-models/advanext/company';

import { useEntities } from 'Hooks';

type Props = {
  compoundTenantId: CompoundTenantId;
  compoundCompanyId: CompoundCompanyId;
  startPath?: string;
  open: boolean;
  onClose: () => void;
  onSelect: (fileId: string) => void | Promise<void>;
};

const DocumentSelectionModal = ({
  compoundTenantId,
  compoundCompanyId,
  startPath,
  open,
  onClose,
  onSelect,
}: Props): ReactElement => {
  const { entities, loading } = useEntities(
    compoundTenantId,
    compoundCompanyId,
    { fetchAll: true },
  );

  const documents = useMemo(
    () =>
      entities
        .filter(({ file }) => !!file?.fileId)
        .filter(({ file }) => !startPath || file?.filePath?.includes(startPath))
        .sort(({ created: c1 }, { created: c2 }) => +c1 - +c2),
    [entities, startPath],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Choose a File</DialogTitle>
      <DialogContent>
        <List>
          {loading &&
            new Array(5).fill(null).map((_, idx) => (
              <ListItem key={idx}>
                <ListItemText
                  primary={<Skeleton key={idx} variant="text" />}
                  secondary={<Skeleton key={idx} variant="text" />}
                />
              </ListItem>
            ))}
          {documents.map((doc) => (
            <ListItem
              button
              key={doc.entityId}
              onClick={(): Promise<void> | void =>
                onSelect(doc?.file?.fileId as string)
              }
            >
              <ListItemText
                primary={doc.file?.filePath}
                secondary={doc.created.toLocaleString()}
              />
            </ListItem>
          ))}
        </List>
        {!loading && entities.length === 0 && (
          <Alert severity="warning">There are no files to choose from</Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentSelectionModal;
