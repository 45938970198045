import React, { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import { Alert } from '@material-ui/lab';
import { StyledFormDialog, FormSelect } from 'advanext-components';
import { BankStatementObject } from 'Types';

const useStyles = makeStyles({
  openModalButton: {
    border: '1px solid #262B32',
    color: '#262B32',
    padding: '4px 15px',
    textTransform: 'none',
  },
  box: {
    minWidth: '500px',
    marginBottom: '40px',
  },
});

type Props = {
  isLoading: boolean;
  onDelete: (entityId: string) => void;
  options: BankStatementObject[];
};

const StatementsRemover = ({
  isLoading,
  onDelete,
  options,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const removeOptions = useMemo(
    () =>
      options.map((e: BankStatementObject) => ({
        label: `${e.bank}: ${dayjs(e.period_start).format(
          'YYYY-MM-DD',
        )} - ${dayjs(e.period_end).format('YYYY-MM-DD')} (${e.currency})`,
        value: e.entityId,
      })),
    [options],
  );

  const handleOpenDialog = useCallback(
    () => setIsDialogOpen(true),
    [setIsDialogOpen],
  );

  const handleCloseDialog = useCallback(
    () => setIsDialogOpen(false),
    [setIsDialogOpen],
  );

  return (
    <>
      <Button onClick={handleOpenDialog} className={classes.openModalButton}>
        Remove Bank Statements
      </Button>
      <StyledFormDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={(values): void => {
          onDelete(values.statement);
          setTimeout(() => handleCloseDialog(), 2000);
        }}
        header="Remove Bank Statements"
        confirmBtnName="Remove"
      >
        <Box className={classes.box}>
          {isLoading ? (
            <Alert icon={<CircularProgress size={20} />} severity="info">
              Removing Bank Statements
            </Alert>
          ) : (
            <Field
              id="statement"
              name="statement"
              label="Remove By Bank and Period"
              placeholder="Remove By Bank and Period"
              component={FormSelect}
              options={removeOptions}
            />
          )}
        </Box>
      </StyledFormDialog>
    </>
  );
};

export default StatementsRemover;
