import validateJs from 'validate.js';
import { isValid } from 'iban';

/* eslint-disable */

validateJs.extend(validateJs.validators.email, {
  message: (value: any) =>
    validateJs.format('^%{value} is not a valid email', { value }),
});

const checked = (value: any, options: any) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const phone = (value: any, options: any) => {
  if (!/^\+\d+$/.test(value)) {
    return (
      options.message ||
      validateJs.format('^%{value} is not a valid phone number', { value })
    );
  }
};

const required = (value: any, options: any) => {
  if (value === null || value === undefined || value === '') {
    return options.message || '^is required';
  }
};

const agreed = (value: any, options: any) => {
  return checked(value, {
    ...options,
    message: options.message || '^You must agree in order to proceed',
  });
};

const array = (value: any, options: any) => {
  if (!(value instanceof Array)) {
    return options.message || validateJs.format('^is required', {});
  }

  if (
    options.lengthGreaterThan !== undefined &&
    value.length <= options.lengthGreaterThan
  ) {
    return options.message || validateJs.format('^requires more items', {});
  }
};

const iban = (value: any, options: any) => {
  if (!isValid(value)) {
    return options.message || validateJs.format('^must be a valid IBAN', {});
  }
};

const tenantId = (value: any, options: any) => {
  if (!/^[a-z0-9_-]{3,255}$/.test(value)) {
    return (
      options.message ||
      validateJs.format('^%{value} is not a valid tenant identifier', { value })
    );
  }
};

const username = (value: any, options: any) => {
  if (!/^[a-z0-9_\-.]{3,255}$/.test(value)) {
    return (
      options.message ||
      validateJs.format('^%{value} is not a valid username', { value })
    );
  }
};

export const notEmpty = (value: any) =>
  /^\s*$/i.test(value) || value == null ? 'Required' : null;

export const isPercent = (value: string | number) =>
  +value <= 100 && +value >= 0
    ? null
    : 'The value should be in range from 0 to 100';

export const compose =
  (...validators: Array<(value: string | number) => string | null>) =>
  (value: string | number) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validators) {
      const res = validator(value);
      if (res !== null || typeof res === 'string') {
        return res;
      }
    }
    return undefined;
  };

export default {
  checked,
  phone,
  required,
  agreed,
  array,
  iban,
  tenantId,
  username,
};
