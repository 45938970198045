import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { CompanyLinkedPerson } from 'advanext-models/advanext/company';
import { getValueOrNA, formatDate } from 'Utilities';
import { LinkedOrMemberPerson } from 'Types';

import previousChecks from './previousChecks.json';

const useStyles = makeStyles(() => ({
  checksRow: {
    background: '#f2f2f2',
  },
  noBorder: {
    border: 'none',
  },
  noData: {
    fontSize: '16px',
    background: '#f2f2f2',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

type Props = {
  row: LinkedOrMemberPerson;
};

const Row = ({ row }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [latestCheck, ...restChecks] = useMemo(
    () =>
      row.pepChecks?.sort(
        (a, b) => +new Date(b.created) - +new Date(a.created),
      ) ?? [],
    [row.pepChecks],
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(): void => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          className={classes.ellipsis}
          title={`${row.firstName} ${row.lastName}`}
        >
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell
          className={classes.ellipsis}
          title={`${
            row instanceof CompanyLinkedPerson
              ? getValueOrNA(row.personType)
              : 'ACCOUNT_CREATOR'
          }
          `}
        >
          {row instanceof CompanyLinkedPerson
            ? getValueOrNA(row.personType)
            : 'ACCOUNT_CREATOR'}
        </TableCell>
        <TableCell
          className={classes.ellipsis}
          title={`${getValueOrNA(row.signatoryRights)}`}
        >
          {getValueOrNA(row.signatoryRights)}
        </TableCell>
        <TableCell>
          {row instanceof CompanyLinkedPerson
            ? 'N/A'
            : row.identification?.identificationStatus}
        </TableCell>
        <TableCell>{getValueOrNA(latestCheck?.status)}</TableCell>
        <TableCell>{formatDate(latestCheck?.created)}</TableCell>
      </TableRow>
      {open && (
        <>
          <TableRow className={classes.checksRow}>
            <TableCell className={classes.noBorder} />
            <TableCell colSpan={6} className={classes.noBorder}>
              <h3>Previous checks</h3>
            </TableCell>
          </TableRow>
          {restChecks?.length ? (
            <TableRow className={classes.checksRow}>
              <TableCell className={classes.noBorder} />
              {previousChecks.map(({ id, label }) => (
                <TableCell key={id}>
                  <strong>{label}</strong>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          ) : (
            <TableRow>
              <TableCell className={cn(classes.noBorder, classes.noData)} />
              <TableCell colSpan={6} className={classes.noData}>
                Nothing to show
              </TableCell>
            </TableRow>
          )}
          {restChecks.map((check) => (
            <TableRow key={+check.created} className={classes.checksRow}>
              <TableCell className={classes.noBorder} />
              <TableCell>{getValueOrNA(check?.status)}</TableCell>
              <TableCell>{formatDate(check?.created)}</TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

export default Row;
