import React, { useState } from 'react';
import { mutate } from 'swr';
import { Skeleton, Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications';
import { ErrorAlert } from 'advanext-components';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core';
import { User } from 'advanext-models/nectar';

import { createUser, updateUser, deleteUser } from 'Api';
import { TenantMemberForm } from 'Forms';
import { TenantMembersList } from 'Tables';
import { useTenantMembers } from 'Hooks';
import { TenantMembersPath } from 'Constants';
import { FormState } from 'Services/forms';
import { plainToClass } from 'class-transformer';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

type Props = {
  isOwner: boolean;
  isInAddMode?: boolean;
  tenantId: string;
  className?: string;
};

const TenantMembersWidget = ({
  isOwner,
  isInAddMode,
  tenantId,
  className = '',
}: Props): JSX.Element => {
  const classes = useStyles();

  const [memberSubmitting, setMemberSubmitting] = useState(false);
  const [memberFormState, setMemberFormState] = useState<Partial<FormState>>(
    {},
  );
  const [selectedMember, setSelectedMember] = useState<User | null>(null);
  const [showMemberForm, setShowMemberForm] = useState(false);
  const {
    data: members,
    isLoading: areMemberLoading,
    error,
    revalidate,
  } = useTenantMembers(tenantId);

  const { addToast } = useToasts();
  const submitMember = async (): Promise<void> => {
    if (!memberFormState.isValid) return;

    setMemberSubmitting(true);

    if (!selectedMember) {
      const userFields = memberFormState?.values as User;
      const member = await createUser(
        tenantId,
        plainToClass(User, {
          ...userFields,
          tenant: tenantId,
          group: memberFormState?.values?.group,
        }),
      );
      if (!member) {
        addToast('Failed to create member', { appearance: 'error' });
      }
    } else {
      const member = await updateUser(
        tenantId,
        memberFormState?.values?.username,
        plainToClass(User, {
          ...memberFormState.values,
          tenant: tenantId,
          group: memberFormState?.values?.group,
        }),
      );
      if (!member) {
        addToast('Failed to update member', { appearance: 'error' });
      }
    }

    setShowMemberForm(false);
    setSelectedMember(null);
    setMemberSubmitting(false);

    mutate(TenantMembersPath.replace(/:id/g, tenantId));
  };

  const destroyMember = async (member: User): Promise<void> => {
    setMemberSubmitting(true);
    const isRemoved = await deleteUser(tenantId, member.username);
    if (isRemoved) {
      addToast('Member is removed', { appearance: 'success' });
      revalidate();
    } else {
      addToast('Failed to delete member', { appearance: 'error' });
    }
    setMemberSubmitting(false);
  };

  const Loading = (): JSX.Element => (
    <Skeleton variant="rect" width="100%" height={270} />
  );

  return (
    <Card className={className}>
      {areMemberLoading ? (
        <Loading />
      ) : error || !members ? (
        <ErrorAlert text="Failed to load members data" />
      ) : (
        <>
          <CardHeader
            title="Members"
            action={
              <IconButton
                disabled={isInAddMode || memberSubmitting}
                onClick={(): void => {
                  setShowMemberForm(!showMemberForm);
                  setSelectedMember(null);
                }}
              >
                {showMemberForm && <Icon>arrow_back</Icon>}
                {!showMemberForm && <Icon>add</Icon>}
              </IconButton>
            }
          />
          <CardContent>
            {memberSubmitting && (
              <Alert icon={<CircularProgress size={20} />} severity="info">
                The Member is being submitted, please wait.
              </Alert>
            )}

            {isInAddMode && (
              <Alert severity="info">
                You should first create a tenant to add a new member
              </Alert>
            )}

            {!showMemberForm && !isInAddMode && (
              <TenantMembersList
                members={members}
                onEditMember={(member: User): void => {
                  setSelectedMember(member);
                  setShowMemberForm(true);
                }}
                onRemoveMember={destroyMember}
              />
            )}
            {showMemberForm && (
              <TenantMemberForm
                member={selectedMember}
                onChange={setMemberFormState}
                isOwner={isOwner}
              />
            )}
          </CardContent>
          {showMemberForm && (
            <CardActions className={classes.cardActions}>
              <Button
                color="primary"
                onClick={submitMember}
                disabled={!memberFormState.isValid || memberSubmitting}
              >
                Save
              </Button>
            </CardActions>
          )}
        </>
      )}
    </Card>
  );
};

export default TenantMembersWidget;
