import { mutate } from 'swr';
import { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useAsyncCallback } from 'react-async-hook';
import { useSelector } from 'react-redux';
import { Country } from 'advanext-models/advanext';
import { CompoundCompanyId } from 'advanext-models/advanext/company';

import { deactivateScoreResultApi } from 'Api';
import { TenantProviderContext } from 'Views/providers/Tenant';
import { user as userSelector } from 'Redux/models/Auth';
import { ScoreResultPath } from 'Constants';

export const useDeactivateScoreResult = (
  referenceId: string,
  types: string[],
  country?: Country,
  compoundCompanyId?: CompoundCompanyId,
): {
  deactivateResult: () => void;
  isDeactivating: boolean;
} => {
  const { addToast } = useToasts();
  const user = useSelector(userSelector);
  const { compoundTenantId } = useContext(TenantProviderContext);

  const deactivateResult = useAsyncCallback(async () => {
    if (!user?.tenant) {
      throw new Error('tenant of user must be defined');
    }
    const errors = await Promise.all(
      types.map((type) =>
        deactivateScoreResultApi({
          type,
          country: country?.toString() ?? 'CH',
          referenceId,
          compoundTenant: compoundTenantId,
        }),
      ),
    );
    const realErrors = errors.filter((error) => !!error);
    if (realErrors?.length) {
      addToast(realErrors[0], {
        appearance: 'error',
      });
    }
    types.forEach((type) => {
      const stringified = JSON.stringify({
        type,
        country,
        compoundCompanyId: compoundCompanyId?.toString(),
        compoundTenantId: compoundTenantId.toString(),
      });
      mutate([ScoreResultPath, stringified]);
    });
  });

  return {
    deactivateResult: deactivateResult.execute,
    isDeactivating: deactivateResult.loading,
  };
};
