import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RootDispatch } from 'Redux/store';

/**
 * A function intended to reduce the amount of a boiler plate code when
 * connecting components to Redux's Dispatcher.
 *
 * == Connect model(s) dispatcher
 * ```
 * const { Users } = useModels();
 * ```
 *
 * Very often we need these dispatchers just to execute some loading jobs using,
 * for instance, useEffect() hook. Instead of doing that, this function
 * provides additional attributes which you can use to achieve the similar
 * behavior:
 *
 * ```
 * const { Users } = useModels(() => {
 *   Users.doSomething();
 * }, { ...dependencies which will trigger an update });
 * ```
 */
export const useModels = (
  init?: () => void,
  deps?: { [id: string]: unknown },
): RootDispatch => {
  const dispatch: RootDispatch = useDispatch();

  useEffect(() => {
    const context = { ...dispatch, ...deps };
    if (init) init.call(context);
  }, [...(deps ? Object.values(deps) : [])]); // eslint-disable-line

  return dispatch;
};
