import { CompoundTenantId } from 'advanext-models/nectar/tenant';

/**
 * Shared tenant includes responses that are common to all tenants.
 */
export const SHARED_TENANT = new CompoundTenantId('', '*');

export const APPLICATION_SOURCES = {
  MainApp: 'MAINAPP',
  Mambu: 'MAMBU',
};
