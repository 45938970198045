import React, { ReactElement } from 'react';
import cn from 'classnames';
import {
  useTable,
  useExpanded,
  Column,
  UseExpandedRowProps,
  UseExpandedInstanceProps,
  UseTableRowProps,
} from 'react-table';
import { Tooltip, Typography } from '@material-ui/core';
import {
  ArrowDropDownRounded,
  ArrowRightRounded,
  TrendingUp,
  TrendingDown,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Theme from 'Config/theme';
import { Row } from '../helpers';

type Props = {
  data: Row[];
  years: number[];
  tableName?: string;
};

const DEFAULT_WIDTH = 244;
const DEFAULT_HEIGHT = 36;
const DEFAULT_FORMATTER = (value: unknown): unknown => value;

const useStyles = makeStyles((theme: typeof Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '14px',
  },
  tWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  tr: {
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  td: {
    width: `${DEFAULT_WIDTH}px`,
    height: `${DEFAULT_HEIGHT}px`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 10px',
  },
  title: {
    background: theme.palette.background.default,
  },
  tooltip: {
    color: 'black',
    backgroundColor: '#c4c4c4',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  arrow: { color: '#c4c4c4' },
}));

const FinancialDataGrid = ({ years, tableName, data }: Props): ReactElement => {
  const classes = useStyles();

  /* @ts-ignore */
  const columns: Column<Row>[] = React.useMemo(
    () => [
      {
        id: 'expander',
        accessor: 'expander',
        Header: function ExpanderHeader({
          toggleAllRowsExpanded,
          isAllRowsExpanded,
        }: UseExpandedInstanceProps<Row>): JSX.Element {
          return (
            <span
              onClick={(): void => toggleAllRowsExpanded(!isAllRowsExpanded)}
              style={{ cursor: 'pointer' }}
            >
              {isAllRowsExpanded ? (
                <ArrowDropDownRounded />
              ) : (
                <ArrowRightRounded />
              )}
            </span>
          );
        },
        Cell: function ExpanderCell({
          row,
        }: {
          row: UseExpandedRowProps<Row>;
        }): JSX.Element {
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 24}px`,
                },
              })}
            >
              {row.isExpanded ? (
                <ArrowDropDownRounded />
              ) : (
                <ArrowRightRounded />
              )}
            </span>
          ) : (
            <span style={{ paddingLeft: '24px' }} />
          );
        },
      },
      {
        id: 'name',
        Header: tableName,
        accessor: 'name',
        Cell: function NameCell({
          row,
          value,
        }: {
          row: UseExpandedRowProps<Row> & UseTableRowProps<Row>;
          value: string;
        }): JSX.Element {
          return row.original.tooltipInfo ? (
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              title={row.original.tooltipInfo}
              placement="top-end"
              arrow
            >
              <div
                style={{
                  ...row.original.cellStyle,
                  opacity: row.depth !== 0 && !row.canExpand ? 0.5 : 1,
                }}
              >
                {value}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                ...row.original.cellStyle,
                opacity: row.depth !== 0 && !row.canExpand ? 0.5 : 1,
              }}
            >
              {value}
            </div>
          );
        },
      },
      ...years.map((year) => ({
        id: year.toString(),
        Header: year.toString(),
        accessor: year.toString(),
        Cell: function YearCell({
          row,
          value,
        }: {
          row: UseExpandedRowProps<Row> & UseTableRowProps<Row>;
          value: number;
        }): JSX.Element {
          const previousValue = row.original[year - 1] as number | undefined;
          const formattingFn = row.original.formatter || DEFAULT_FORMATTER;

          const color = cn({
            error: value < (previousValue ?? 0),
            primary: value > (previousValue ?? 0),
            inherit: value === previousValue,
          });

          const showCompare =
            row.original.compare &&
            previousValue !== undefined &&
            value !== undefined;
          return (
            <Typography
              style={{
                ...row.original.cellStyle,
                opacity: row.depth !== 0 && !row.canExpand ? 0.5 : 1,
              }}
              // @ts-ignore
              color={showCompare ? color : undefined}
            >
              {formattingFn(value)}
              {showCompare && color === 'error' && (
                <TrendingDown fontSize="inherit" />
              )}
              {showCompare && color === 'primary' && (
                <TrendingUp fontSize="inherit" />
              )}
            </Typography>
          );
        },
      })),
    ],
    [years, tableName, classes.tooltip, classes.arrow],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Row>(
      {
        columns,
        data,
      },
      useExpanded,
    );

  return (
    <div className={classes.root}>
      <table className={classes.table} {...getTableProps()}>
        <thead className={classes.tWrapper}>
          {headerGroups.map((headerGroup) => {
            const { key: headerGroupKey, ...getHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr
                key={headerGroupKey}
                className={classes.tr}
                {...getHeaderGroupProps}
              >
                {headerGroup.headers.map((column, index) => {
                  const { key: headerKey, ...getHeaderProps } =
                    column.getHeaderProps();
                  return (
                    <th
                      key={headerKey}
                      className={cn({
                        [classes.td]: index !== 0,
                        [classes.title]: index === 1,
                      })}
                      {...getHeaderProps}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody className={classes.tWrapper} {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...getRowProps } = row.getRowProps();
            return (
              <tr key={rowKey} className={classes.tr} {...getRowProps}>
                {row.cells.map((cell, index) => {
                  const { key: cellKey, ...getCellProps } = cell.getCellProps();
                  return (
                    <td
                      key={cellKey}
                      className={cn({
                        [classes.td]: index !== 0,
                        [classes.title]: index === 1,
                      })}
                      {...getCellProps}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FinancialDataGrid;
