import React, { useMemo } from 'react';
import { components, OptionProps, ValueContainerProps } from 'react-select';
import Flag from 'react-world-flags';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Address } from 'advanext-models/advanext';
import { getCompanyAddressFormatted } from 'Utilities';

const useStyles = makeStyles((theme) => ({
  optionContainer: {
    '& > *': {
      padding: '8px 40px 8px 8px !important',
    },
  },
  flag: {
    height: 23,
    textAlign: 'center',
  },
  address: {
    paddingLeft: theme.spacing(2),
    fontSize: 12,
    color: 'hsl(0,0%,60%)',
  },
}));

export const CountryOption = ({
  children,
  data,
  ...rest
}: OptionProps<{ label: string; value: string }, false>): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid className={classes.optionContainer}>
      <components.Option data={data} {...rest}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={5} md={4} lg={3} className={classes.flag}>
            <Flag code={data.value} height="23" />
          </Grid>
          <Grid item xs={7} md={8} lg={9}>
            {children}
          </Grid>
        </Grid>
      </components.Option>
    </Grid>
  );
};

export const CountryValueContainer = ({
  selectProps,
  children,
  ...props
}: ValueContainerProps<
  { label: string; value: string },
  false
>): JSX.Element => {
  const classes = useStyles();
  return (
    <components.ValueContainer selectProps={selectProps} {...props}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={5} md={4} lg={3} className={classes.flag}>
          {/* @ts-ignore */}
          <Flag code={selectProps?.value?.value} height="23" />
        </Grid>
        <Grid item xs={7} md={8} lg={9}>
          {children}
        </Grid>
      </Grid>
    </components.ValueContainer>
  );
};

export const CompanyOption = ({
  data,
  ...rest
}: OptionProps<
  {
    label: { address: Address; name: string };
    value: string;
  },
  false
>): JSX.Element => {
  const classes = useStyles();
  const formattedAddress = useMemo(
    () => getCompanyAddressFormatted(data.label.address),
    [data.label.address],
  );
  return (
    <components.Option data={data} {...rest}>
      <span>{data.label.name}</span>
      <span className={classes.address}>{formattedAddress}</span>
    </components.Option>
  );
};
