import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import { Company } from 'advanext-models/advanext/company';
import { StyledCard } from 'advanext-components';
import { formatDate, getValueOrNA } from 'Utilities';

type Props = {
  isLoading: boolean;
  company?: Company;
};

const SignUpDetails = ({ isLoading, company }: Props): JSX.Element => (
  <StyledCard title="Sign Up Details">
    {isLoading ? (
      <CircularProgress size={40} />
    ) : (
      <Table>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell>Account creation date</TableCell>
            <TableCell>{formatDate(company?.created)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>{getValueOrNA(company?.role)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Promo code</TableCell>
            <TableCell>{getValueOrNA(company?.promoCode)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type Of Control</TableCell>
            <TableCell>{getValueOrNA(company?.typeOfControl)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fiduciary Holding Assets</TableCell>
            <TableCell>
              {getValueOrNA(company?.fiduciaryHoldingAssets)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )}
  </StyledCard>
);

export default SignUpDetails;
