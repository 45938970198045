import React, { useState, useCallback, useEffect } from 'react';

import {
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { User, UserGroup } from 'advanext-models/nectar';

import {
  FormState,
  INITIAL_FORM_STATE,
  isValid,
  formStateApplyEvent,
  hasError as formHasError,
  getError as formGetError,
} from 'Services/forms';
import { FormEvent } from 'Types';

const schema = {
  username: { required: true, username: true },
  firstName: { required: true },
  lastName: { required: true },
  email: { required: true, email: true },
  phoneNumber: { required: true, phone: true },
  group: { required: true },
};

type Props = {
  member?: User | null;
  isOwner?: boolean | null;
  onChange: (formState: FormState) => void;
};

const TenantMemberForm = ({
  member,
  isOwner = false,
  onChange,
}: Props): JSX.Element => {
  const [formState, setFormState] = useState<FormState>({
    ...INITIAL_FORM_STATE,
    values: member ? { ...member } : ({} as User),
    isValid: isValid({ values: member || {} } as FormState, schema),
  });

  const handleInputChange = useCallback(
    (event: FormEvent) => {
      setFormState(formStateApplyEvent(event, formState, schema));
    },
    [formState],
  );

  const hasError = useCallback(
    (field: string) => formHasError(field, formState),
    [formState],
  );
  const getError = useCallback(
    (field: string) => formGetError(field, formState),
    [formState],
  );

  // Renew the form in case incoming application has changed without form unmounting
  useEffect(() => {
    setFormState({
      ...INITIAL_FORM_STATE,
      values: member ? { ...member } : {},
      isValid: isValid({ values: member || {} } as FormState, schema),
    });
  }, [member]);

  useEffect(() => {
    onChange(formState as FormState);
  }, [formState, onChange]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell>
            <TextField
              error={hasError('username')}
              fullWidth
              helperText={getError('username')}
              label="Username"
              margin="dense"
              name="username"
              onChange={handleInputChange}
              required
              value={formState.values.username || ''}
              disabled={!!member}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>First Name</TableCell>
          <TableCell>
            <TextField
              error={hasError('firstName')}
              fullWidth
              helperText={getError('firstName')}
              label="First Name"
              margin="dense"
              name="firstName"
              onChange={handleInputChange}
              required
              value={formState.values.firstName || ''}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Name</TableCell>
          <TableCell>
            <TextField
              error={hasError('lastName')}
              fullWidth
              helperText={getError('lastName')}
              label="Last Name"
              margin="dense"
              name="lastName"
              onChange={handleInputChange}
              required
              value={formState.values.lastName || ''}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>
            <TextField
              error={hasError('email')}
              fullWidth
              helperText={getError('email')}
              label="Email"
              margin="dense"
              name="email"
              onChange={handleInputChange}
              required
              value={formState.values.email || ''}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>User Group</TableCell>
          <TableCell>
            <TextField
              select
              SelectProps={{ multiple: true }}
              error={hasError('group')}
              fullWidth
              helperText={getError('group')}
              label="User Group"
              margin="dense"
              name="group"
              onChange={handleInputChange}
              required
              value={formState.values.group || []}
            >
              {Object.keys(UserGroup).map((e) => (
                <MenuItem
                  key={e}
                  value={e}
                  disabled={e === UserGroup.OWNER && !isOwner}
                >
                  {e}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone</TableCell>
          <TableCell>
            <TextField
              error={hasError('phoneNumber')}
              fullWidth
              helperText={getError('phoneNumber')}
              label="Phone"
              margin="dense"
              name="phoneNumber"
              onChange={handleInputChange}
              required
              value={formState.values.phoneNumber || ''}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TenantMemberForm;
