import { UserGroup } from 'advanext-models/nectar';
import { user as userSelector } from 'Redux/models/Auth';
import type { PolicyDocument } from 'Types';

const IsOwner: PolicyDocument = {
  selectors: {
    authUser: userSelector,
  },
  permit: async ({ authUser }) => {
    return authUser?.group.includes(UserGroup.OWNER);
  },
};

export default IsOwner;
