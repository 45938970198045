import useSWR from 'swr';
import type { TenantApplication } from 'advanext-models/nectar/tenant';

import { fetcher } from 'Api';
import { TenantApplicationsPath } from 'Constants';
import { ApiEntity } from 'Types';

export const useTenantApplications = (
  id: string,
): ApiEntity<Array<TenantApplication>> => {
  const { data, error } = useSWR(
    TenantApplicationsPath.replace(/:id/g, encodeURIComponent(id)),
    fetcher,
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  } as ApiEntity<Array<TenantApplication>>;
};
