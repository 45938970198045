import { GenericNectarElement } from 'advanext-models/nectar/entity';
import {
  BalanceSheet,
  BalanceSheetRatios,
  FinancialStatement,
  IncomeStatement,
  IndustryRatio,
} from 'advanext-models/nectar/financialstatement';
import { FinancialStatementNectarScore } from 'advanext-models/nectar/score';
import Big from 'big.js';

import { IndustryRatioNames } from 'Constants';
import { formatMoney, formatNumber } from 'Utilities';

export interface ComplexColDef {
  name: string;
  compare?: boolean;
  accessor?: Function;
  formatter?: Function;
  cellStyle?: Record<string, string>;
  tooltipInfo?: string;
}

export interface Column<T> {
  compare?: boolean;
  name: string;
  formatter?: Function;
  accessor?: keyof T;
  getValue?: (val0: T) => unknown;
  cellStyle?: Record<string, string>;
  tooltipInfo?: string;
}

export type Row = {
  expander?: unknown;
  id?: string;
  compare?: boolean;
  name: string;
  formatter?: Function;
  cellStyle?: Record<string, string>;
  tooltipInfo?: string;
  subRows?: Array<Row>;
  [key: string]: unknown;
};

const prepareValue = (value?: string): string => {
  if (value) {
    let tempValue = value;
    if (value[0] === '(' && value[value.length - 1] === ')') {
      tempValue = '-' + value.slice(1, -1);
    }
    return tempValue.replaceAll(/[^0-9.-]/g, '');
  }
  return '';
};

export const fillFinancialData = <T>(
  statements: Array<[string, T]>,
  columns: Column<T>[],
): Row[] =>
  columns.map(
    ({
      name,
      accessor,
      getValue,
      formatter,
      compare,
      cellStyle,
      tooltipInfo,
    }) => ({
      name,
      ...statements.reduce(
        (curr: { subRows?: Row[] }, [year, fs]) => {
          const genericNectarElement = accessor
            ? (fs[accessor] as unknown as GenericNectarElement[])
            : undefined;
          const value = getValue ? getValue(fs) : undefined;
          return {
            ...curr,
            formatter,
            compare,
            cellStyle,
            tooltipInfo,
            [year]:
              (!genericNectarElement
                ? undefined
                : genericNectarElement
                    .map((e) => Big(prepareValue(e.value)))
                    .reduce((acc, val) => acc.plus(val), Big(0.0))
                    .toNumber()) ?? value,
            subRows:
              !genericNectarElement || genericNectarElement.length < 2
                ? undefined
                : curr.subRows?.length
                ? curr.subRows.map((e: Row) => ({
                    ...e,
                    formatter,
                    cellStyle,
                    [year]: Big(
                      prepareValue(
                        genericNectarElement.find((el) => el.id === e.id)
                          ?.value,
                      ),
                    ).toNumber(),
                  }))
                : genericNectarElement.map((e) => ({
                    id: e.id,
                    name: e.name,
                    formatter,
                    cellStyle,
                    [year]: Big(prepareValue(e.value)).toNumber(),
                  })),
          };
        },
        { subRows: [] },
      ),
    }),
  );

export const fillRatios = <T>(
  statements: Array<[string, T]>,
  columns: Column<T>[],
): Row[] =>
  columns.map(
    ({ name, accessor, formatter, compare, cellStyle, tooltipInfo }) => ({
      name,
      ...statements.reduce(
        (curr, [year, fs]) => {
          const ratio = accessor
            ? (fs[accessor] as unknown as number[])
            : undefined;
          return {
            ...curr,
            formatter,
            compare,
            cellStyle,
            tooltipInfo,
            [year]: ratio,
          };
        },
        { subRows: [] },
      ),
    }),
  );

export const assetsColumns: Column<BalanceSheet>[] = [
  {
    compare: true,
    name: 'Cash And Cash equivalents',
    formatter: formatMoney,
    accessor: 'cashAndCashEquivalents',
  },
  {
    compare: true,
    name: 'Receivables',
    formatter: formatMoney,
    accessor: 'receivables',
  },
  {
    compare: true,
    name: 'Inventories',
    formatter: formatMoney,
    accessor: 'inventories',
  },
  {
    compare: true,
    name: 'Other Current Assets',
    formatter: formatMoney,
    accessor: 'otherCurrentAssets',
  },
  {
    compare: true,
    name: 'Current Assets',
    accessor: 'shortTermAssets',
    formatter: formatMoney,
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Intangibles',
    formatter: formatMoney,
    accessor: 'intangibles',
  },
  {
    compare: true,
    name: 'Plants And Equipment',
    formatter: formatMoney,
    accessor: 'plantsAndEquipment',
  },
  {
    compare: true,
    name: 'Other Fixed Assets',
    formatter: formatMoney,
    accessor: 'otherFixedAssets',
  },
  {
    compare: true,
    name: 'Noncurrent Assets',
    formatter: formatMoney,
    accessor: 'longTermAssets',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Total Assets',
    formatter: formatMoney,
    accessor: 'assets',
    cellStyle: { fontWeight: 'bold' },
  },
];

export const liabilitiesColumns: Column<BalanceSheet>[] = [
  {
    compare: true,
    name: 'Trade And Other Payables',
    formatter: formatMoney,
    accessor: 'tradeAndOtherPayables',
    cellStyle: { borderBottom: 'none', minWidth: '120px' },
  },
  {
    compare: true,
    name: 'Short Term Borrowings',
    formatter: formatMoney,
    accessor: 'shortTermBorrowings',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Current Tax Payable',
    formatter: formatMoney,
    accessor: 'currentTaxPayable',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Other Current Liabilities',
    formatter: formatMoney,
    accessor: 'otherCurrentLiabilities',
  },
  {
    compare: true,
    name: 'Current liabilities',
    formatter: formatMoney,
    accessor: 'shortTermLiabilities',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Long Term Debt',
    formatter: formatMoney,
    accessor: 'longTermDebt',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Deferred Taxes',
    formatter: formatMoney,
    accessor: 'deferredTaxes',
  },
  {
    compare: true,
    name: 'Other Long Term Liabilities',
    formatter: formatMoney,
    accessor: 'otherLongTermLiabilities',
  },
  {
    compare: true,
    name: 'Long Term Liabilities',
    formatter: formatMoney,
    accessor: 'longTermLiabilities',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Total Liabilities',
    formatter: formatMoney,
    getValue: (bs: BalanceSheet): number =>
      bs.shortTermLiabilities
        .map((e) => Big(prepareValue(e.value)))
        .reduce((acc, val) => acc.plus(val), Big(0.0))
        .plus(
          bs.longTermLiabilities
            .map((e) => Big(prepareValue(e.value)))
            .reduce((acc, val) => acc.plus(val), Big(0.0)),
        )
        .toNumber(),
    cellStyle: { borderBottom: 'none', fontWeight: 'bold' },
  },
  {
    compare: true,
    name: '',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Capital',
    formatter: formatMoney,
    accessor: 'capital',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Additional Paid In Capital',
    formatter: formatMoney,
    accessor: 'additionalPaidInCapital',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'Retained Earnings',
    formatter: formatMoney,
    accessor: 'retainedEarnings',
  },
  {
    compare: true,
    name: 'Total Equity',
    formatter: formatMoney,
    accessor: 'equity',
    cellStyle: { borderBottom: 'none', fontWeight: 'bold' },
  },
  {
    compare: true,
    name: '',
  },
  {
    compare: true,
    name: 'Total Equity & Liabilities',
    formatter: formatMoney,
    accessor: 'equityLiabilities',
    cellStyle: { borderBottom: 'none', fontWeight: 'bold' },
  },
];

export const bsRatiosColumns: Column<BalanceSheetRatios>[] = [
  {
    compare: true,
    name: 'Net sales',
    formatter: formatNumber,
    accessor: 'netSales',
  },
  {
    compare: true,
    name: 'COGS',
    formatter: formatNumber,
    accessor: 'cogs',
  },
  {
    compare: true,
    name: 'Net income',
    formatter: formatNumber,
    accessor: 'netIncome',
  },
  {
    compare: true,
    name: 'DSO',
    formatter: formatNumber,
    accessor: 'dso',
    tooltipInfo: 'Trade receivables / Net sales * 365',
  },
  {
    compare: true,
    name: 'DSO with average receivables',
    formatter: formatNumber,
    accessor: 'dsoReceivables',
    tooltipInfo:
      '(((Trade receivables of t-1 + Trade receivables of t) / 2) / Net sales of t) * 365',
  },
  {
    compare: true,
    name: 'DPO',
    formatter: formatNumber,
    accessor: 'dpo',
    tooltipInfo: 'Accounts trade payable / COGS * 365',
  },
  {
    compare: true,
    name: 'DPO with average payables',
    formatter: formatNumber,
    accessor: 'dpoPayables',
    tooltipInfo:
      '((Accounts trade payable of t-1 + Accounts trade payable of t) / 2) / COGS of t) * 365',
  },
  {
    compare: true,
    name: 'DIO',
    formatter: formatNumber,
    accessor: 'dio',
    tooltipInfo: 'Inventories / COGS * 365',
  },
  {
    compare: true,
    name: 'DIO with average inventories',
    formatter: formatNumber,
    accessor: 'dioInventories',
    tooltipInfo:
      '((Inventories of t-1 + Inventories of t) / 2) / COGS of t) * 365',
  },
  {
    compare: true,
    name: 'Cash conversion cycle',
    formatter: formatNumber,
    accessor: 'cashConversionCycle',
    tooltipInfo: 'DSO + DIO - DPO',
  },
  {
    compare: true,
    name: 'Approx. financing gap',
    formatter: formatNumber,
    accessor: 'financingGap',
    tooltipInfo:
      'if “Cash conversion cycle“ < 0 then 0, else: Net sales / 365 * Cash conversions cycle',
  },
  {
    compare: true,
    name: 'Provisions for bad debt as % of AR',
    formatter: formatNumber,
    accessor: 'provisionsForBadDebtAsPercent',
    tooltipInfo: '-Provisions for bad debt / Trade receivables',
  },
  {
    compare: true,
    name: 'Tangible net worth',
    formatter: formatNumber,
    accessor: 'tangibleNetWorth',
    tooltipInfo: 'Total equity - Intangibles',
  },
  {
    compare: true,
    name: 'Total Debt / Total Assets',
    formatter: formatNumber,
    accessor: 'debtAssets',
    tooltipInfo: '(Current Liabilities + Long term liabilities) / Total Assets',
  },
  {
    compare: true,
    name: 'Total Debt / Total Equity',
    formatter: formatNumber,
    accessor: 'totalDebtEquity',
    tooltipInfo: '(Current Liabilities + Long term liabilities) / Total Equity',
  },
  {
    compare: true,
    name: 'S-T borrowings / Total Assets',
    formatter: formatNumber,
    accessor: 'stBorrowingsAssets',
    tooltipInfo: 'Short-term borrowings / Total Liabilities & Equity',
  },
  {
    compare: true,
    name: 'L-T debts / Total Assets',
    formatter: formatNumber,
    accessor: 'ltDebtsAssets',
    tooltipInfo: 'Long-term debt / Total Liabilities & Equity',
  },
  {
    compare: true,
    name: 'Current ratio',
    formatter: formatNumber,
    accessor: 'currentRatio',
    tooltipInfo: 'Current Assets / Current Liabilities',
  },
  {
    compare: true,
    name: 'Cash ratio',
    formatter: formatNumber,
    accessor: 'cashRatio',
    tooltipInfo: 'Cash And Cash Equivalents / Current Liabilities',
  },
  {
    compare: true,
    name: 'Quick ratio',
    formatter: formatNumber,
    accessor: 'quickRatio',
    tooltipInfo:
      '(Cash And Cash Equivalents + Trade receivables) / Current Liabilities',
  },
  {
    compare: true,
    name: 'Asset Turnover',
    formatter: formatNumber,
    accessor: 'assetTurnover',
    tooltipInfo: 'Net sales / Total Liabilities & Equity',
  },
  {
    compare: true,
    name: 'ROE',
    formatter: formatNumber,
    accessor: 'roe',
    tooltipInfo: 'Net income / Total equity',
  },
  {
    compare: true,
    name: 'Equity / Total Assets',
    formatter: formatNumber,
    accessor: 'equityAssets',
    tooltipInfo: 'Total equity / Total Assets',
  },
  {
    compare: true,
    name: 'Non-current Assets / Total Assets',
    formatter: formatNumber,
    accessor: 'nonCurrentTotalAssets',
    tooltipInfo: 'Non-current Assets / Total Assets',
  },
  {
    compare: true,
    name: 'Net Debt / EBITDA',
    formatter: formatNumber,
    accessor: 'netDebtEbitda',
    tooltipInfo:
      '(Short-term borrowings + Long-term debt - Cash And Cash Equivalents) / EBITDA',
  },
  {
    compare: true,
    name: 'EBIT / Total Assets',
    formatter: formatNumber,
    accessor: 'ebitAssets',
    tooltipInfo: 'EBIT / Total Assets',
  },
  {
    compare: true,
    name: 'Net Sales / Total Assets',
    formatter: formatNumber,
    accessor: 'netSalesAssets',
    tooltipInfo: 'Net sales / Total Assets',
  },
  {
    compare: true,
    name: 'Equity / Total Liabilities',
    formatter: formatNumber,
    accessor: 'equityLiabilities',
    tooltipInfo: 'Total Equity / (Current Liabilities + Long Term Liabilities)',
  },
  {
    compare: true,
    name: 'Working Capital / Total Assets',
    formatter: formatNumber,
    accessor: 'workCapitalAssets',
    tooltipInfo: '(Current Assets - Current Liabilities) / Total Assets',
  },
  {
    compare: true,
    name: 'Retained earnings / Total Assets',
    formatter: formatNumber,
    accessor: 'retainedEarningsAssets',
    tooltipInfo: 'Retained earnings / Total Assets',
  },
  {
    compare: true,
    name: 'Altman Z score (private)',
    formatter: formatNumber,
    accessor: 'altmanZScorePrivate',
    tooltipInfo:
      '3.107 * “EBIT / Total Assets" + 0.998 * "Net Sales / Total Assets" + 0.42 * "Equity / Total Liabilities" + 0.717 * "Working Capital / Total Assets" + 0.847 * ”Retained earnings / Total Assets”',
  },
  {
    compare: true,
    name: 'Altman Z score (non-manufacturers)',
    formatter: formatNumber,
    accessor: 'altmanZScoreNonManufac',
    tooltipInfo:
      '1.05 * “Equity / Total Liabilities” + 6.56 * “Working Capital / Total Assets” + 3.26 * ”Retained earnings / Total Assets” + 6.72 * “EBIT / Total Assets"',
  },
  {
    compare: true,
    name: 'Tangible assets / Total assets',
    formatter: formatNumber,
    accessor: 'tangibleTotalAssets',
    tooltipInfo: '(Total Assets - Intangibles) / Total Assets',
  },
  {
    compare: true,
    name: 'Net Income to Assets',
    formatter: formatNumber,
    accessor: 'netIncomeAssets',
    tooltipInfo: 'Net income / Total Assets',
  },
  {
    compare: true,
    name: 'X score',
    formatter: formatNumber,
    accessor: 'xScore',
    tooltipInfo:
      '-4.336 - 4.513 * "Net Income to Assets" + 5.679 * "Total Debt / Total Assets" + 0.004 * ”Current ratio”',
  },
  {
    compare: true,
    name: 'ST Loans to Shareholders / Current Assets',
    formatter: formatNumber,
    accessor: 'stLoansToShareholdersAssets',
    tooltipInfo: 'Short-term loans to shareholders / Current assets',
  },
  {
    compare: true,
    name: 'ST Loans to Related Companies / Current Assets',
    formatter: formatNumber,
    accessor: 'stLoansToCompaniesAssets',
    tooltipInfo: 'Short-term loans to related companies / Current assets',
  },
  {
    compare: true,
    name: 'Loans to Shareholders / Assets',
    formatter: formatNumber,
    accessor: 'loansToShareholdersAssets',
    tooltipInfo:
      '(Short-term loans to shareholders + Long-term loans to shareholders) / Total Assets',
  },
  {
    compare: true,
    name: 'Loans to Related Companies / Assets',
    formatter: formatNumber,
    accessor: 'loansToCompaniesAssets',
    tooltipInfo:
      '(Short-term loans to related companies + Long-term loans to related companies) / Total Assets',
  },
  {
    compare: true,
    name: 'Debt / Equity',
    formatter: formatNumber,
    accessor: 'debtEquity',
    tooltipInfo: '(Short-term borrowings + Long-term debt) / Total Equity',
  },
  {
    compare: true,
    name: 'Inventory Turnover',
    formatter: formatNumber,
    accessor: 'inventoryTurnover',
    tooltipInfo: 'Net sales / Inventories',
  },
  {
    compare: true,
    name: 'Receivables Turnover',
    formatter: formatNumber,
    accessor: 'receivablesTurnover',
    tooltipInfo: 'Net Sales / Trade receivables',
  },
  {
    compare: true,
    name: 'Anlagedeckungsgrad I',
    formatter: formatNumber,
    accessor: 'anlagedeckungsgrad1',
    tooltipInfo: 'Total Equity / Noncurrent Assets',
  },
  {
    compare: true,
    name: 'Anlagedeckungsgrad II',
    formatter: formatNumber,
    accessor: 'anlagedeckungsgrad2',
    tooltipInfo: '(Long Term Liabilities + Total Equity) / Noncurrent Assets',
  },
  {
    compare: true,
    name: 'Erweiterte Anlagedeckungsgrad',
    formatter: formatNumber,
    accessor: 'erweiterteAnlagedeckungsgrad',
    tooltipInfo:
      '(Long Term Liabilities + Total Equity) / (Noncurrent Assets + Inventories)',
  },
  {
    compare: true,
    name: 'NOPAT',
    formatter: formatNumber,
    accessor: 'nopat',
    tooltipInfo: 'EBIT - Tax expense',
  },
  {
    compare: true,
    name: 'Invested Capital (Liability Based)',
    formatter: formatNumber,
    accessor: 'investedCapitalLiability',
    tooltipInfo:
      'Total Equity - Cash And Cash Equivalents + Current Liabilities + Long Term Liabilities',
  },
  {
    compare: true,
    name: 'ROIC',
    formatter: formatNumber,
    accessor: 'roic',
    tooltipInfo: '“NOPAT“ / “Invested Capital (Liability Based)“',
  },
  {
    compare: true,
    name: 'NOPAT (tax=20%)',
    formatter: formatNumber,
    accessor: 'nopat20',
    tooltipInfo: 'EBIT * (1 - 0.2)',
  },
  {
    compare: true,
    name: 'Invested Capital (Asset Based)',
    formatter: formatNumber,
    accessor: 'investedCapitalAsset',
    tooltipInfo:
      '(Current Assets - Current Liabilities) + Noncurrent Assets - Cash And Cash Equivalents',
  },
  {
    compare: true,
    name: 'ROIC (tax=20%)',
    formatter: formatNumber,
    accessor: 'roic20',
    tooltipInfo: '“NOPAT (tax=20%)“ / “Invested Capital (Asset Based)“',
  },
  {
    compare: true,
    name: 'Tax Burden',
    formatter: formatNumber,
    accessor: 'taxBurden',
    tooltipInfo: 'Net income / EBT',
  },
  {
    compare: true,
    name: 'Interest Burden',
    formatter: formatNumber,
    accessor: 'interestBurden',
    tooltipInfo: 'EBT / EBIT',
  },
  {
    compare: true,
    name: 'Operating Margin',
    formatter: formatNumber,
    accessor: 'operatingMargin',
    tooltipInfo: 'EBIT / Net sales',
  },
  {
    compare: true,
    name: 'Asset Turnover',
    formatter: formatNumber,
    accessor: 'assetTurnoverAnalysis',
    tooltipInfo: 'Net sales / Total Assets',
  },
  {
    compare: true,
    name: 'Equity Multiplier',
    formatter: formatNumber,
    accessor: 'equityMultiplier',
    tooltipInfo: 'Total Assets / Total Equity',
  },
];

export const incomeStatementColumns: Column<IncomeStatement>[] = [
  {
    compare: true,
    name: 'Net sales',
    formatter: formatMoney,
    accessor: 'netSales',
  },
  {
    compare: true,
    name: 'COGS',
    formatter: formatMoney,
    accessor: 'cogs',
  },
  {
    compare: true,
    name: 'Gross Profit',
    formatter: formatMoney,
    accessor: 'grossProfit',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'SG&A Expense',
    formatter: formatMoney,
    accessor: 'sgaExpense',
  },
  {
    compare: true,
    name: 'Other Operating Expense (OOX)',
    formatter: formatMoney,
    accessor: 'otherOperatingExpense',
  },
  {
    compare: true,
    name: 'EBITDA',
    formatter: formatMoney,
    accessor: 'ebitda',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Depreciation And Amortization',
    formatter: formatMoney,
    accessor: 'depreciationAndAmortization',
  },
  {
    compare: true,
    name: 'EBIT',
    formatter: formatMoney,
    accessor: 'ebit',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Interest Expenses',
    formatter: formatMoney,
    accessor: 'interestExpenses',
    cellStyle: { borderBottom: 'none' },
  },
  {
    compare: true,
    name: 'EBT',
    formatter: formatMoney,
    accessor: 'ebt',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'Tax Expense',
    formatter: formatMoney,
    accessor: 'incomeTaxExpense',
  },
  {
    compare: true,
    name: 'Extraordinary Items',
    formatter: formatMoney,
    accessor: 'extraordinaryItems',
  },
  {
    compare: true,
    name: 'Net income',
    formatter: formatMoney,
    accessor: 'netProfit',
    cellStyle: { fontWeight: 'bold' },
  },
];

export const incomeStatementKpisColumns: Column<IncomeStatement>[] = [
  // FIXME: add "grossProfitMargin" to models
  {
    compare: true,
    name: 'CHECK - Gross Profit Margin',
    formatter: formatNumber,
    accessor: 'grossProfit',
    tooltipInfo: 'Gross Profit / Net Sales',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    compare: true,
    name: 'SG&A / Net Sales',
    formatter: formatNumber,
    accessor: 'netSales',
    tooltipInfo: 'SG&A Expense / Net Sales',
  },
  {
    compare: true,
    name: 'OOX / Net Sales',
    formatter: formatNumber,
    accessor: 'otherOperatingExpense',
    tooltipInfo: 'Other Operating Expenses / Net Sales',
  },
  {
    compare: true,
    name: 'EBITDA Margin',
    formatter: formatNumber,
    accessor: 'ebitda',
    cellStyle: { fontWeight: 'bold' },
    tooltipInfo: 'EBITDA / Net Sales',
  },
  // {
  //   compare: true,
  //   name: 'Depreciation / PPE',
  //   formatter: formatNumber,
  //   accessor: (fs: FinancialStatement): number | string =>
  //     fs.ratios?.DEPRECATION_TO_PPE ?? DEFAULT_VALUE,
  //   tooltipInfo: 'Depreciation And Amortization / Plants And Equipment',
  // },
  {
    compare: true,
    name: 'Adjusted Net Income Margin',
    formatter: formatNumber,
    accessor: 'adjustedNetProfit',
    cellStyle: { fontWeight: 'bold' },
    tooltipInfo: '(EBT - Tax Expense) / Net Sales',
  },
  {
    compare: true,
    name: 'Net Income Margin',
    formatter: formatNumber,
    accessor: 'incomeTaxExpense',
    cellStyle: { fontWeight: 'bold' },
    tooltipInfo: 'Net Income / Net Sales',
  },
  {
    compare: true,
    name: 'Approx. Interest Rate Coverage',
    formatter: formatNumber,
    accessor: 'interestExpenses',
    cellStyle: { fontWeight: 'bold' },
    tooltipInfo: 'EBIT / Interest Expense',
  },
];

export const financialStatementScoresTableData: ComplexColDef[] = [
  ...Object.entries(IndustryRatioNames).map(([key, value]) => ({
    compare: true,
    name: value,
    formatter: formatNumber,
    accessor: (scores: FinancialStatementNectarScore): number =>
      scores.scores?.[key as IndustryRatio] ?? Infinity,
  })),
  {
    compare: true,
    name: 'Total Score',
    formatter: formatNumber,
    accessor: (scores: FinancialStatementNectarScore): number =>
      scores.score ?? Infinity,
    cellStyle: { fontWeight: 'bold' },
  },
];

export const financialStatementRatiosTableData: ComplexColDef[] =
  Object.entries(IndustryRatioNames).map(([key, value]) => ({
    compare: true,
    name: value,
    formatter: formatNumber,
    accessor: (scores: FinancialStatement): number =>
      scores.ratios?.[key as IndustryRatio] ?? Infinity,
  }));
