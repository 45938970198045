import { User } from 'advanext-models/nectar';
import { createModel } from '@rematch/core';
import {
  getAuthenticatedUser,
  updateUserDetails,
  signOut,
  getAuthenticatedUserAuthTokenWithExpTime,
} from 'Services/auth';

import { apiService } from 'Services';

import type { State } from './types';

export * from './selectors';
export * from './types';

/* eslint-disable */

export default createModel({
  state: {
    user: null,
    loading: false,
    loaded: false,
    error: null,
    doRefreshToken: null,
  },
  reducers: {
    setError: (state: State, error: any) => {
      return { ...state, error };
    },
    setLoading: (state: State, loading: boolean) => {
      return { ...state, loading };
    },
    setUser: (state: State, user: User) => {
      return { ...state, user, loaded: true };
    },
    setLoaded: (state: State, loaded: boolean) => {
      return { ...state, loaded };
    },
    setDoRefreshToken: (state: State, doRefreshToken: Function) => {
      if (state.doRefreshToken) clearTimeout(state.doRefreshToken);
      return { ...state, doRefreshToken };
    },
  },
  // Here should be Effects instead of any
  effects: () => ({
    async setDefaultAuthWithAutoRefresh() {
      const { token, expTime } =
        await getAuthenticatedUserAuthTokenWithExpTime();
      apiService.setDefaultAuth(token);
      // In 5 sec after exp time - get refreshed token and put to apiService
      const timeToUpdateDefaultAuth = expTime * 1000 - +new Date() + 5000;
      this.setDoRefreshToken(
        setTimeout(() => {
          this.setDefaultAuthWithAutoRefresh();
        }, timeToUpdateDefaultAuth),
      );
    },
    async storeAuthenticatedUser(user?: User) {
      if (user) {
        await this.setDefaultAuthWithAutoRefresh();
        return this.setUser(user);
      }

      this.setLoading(true);

      try {
        const authUser = await getAuthenticatedUser();
        await this.setDefaultAuthWithAutoRefresh();
        this.setUser(authUser);
      } catch (error) {
        this.setError(error);
      } finally {
        this.setLoading(false);
        this.setLoaded(true);
      }
    },
    async updateUser(details: User) {
      this.setLoading(true);

      try {
        await updateUserDetails(details as any);
        const authUser = await getAuthenticatedUser(true);
        await this.setDefaultAuthWithAutoRefresh();
        this.setUser(authUser);
      } catch (error) {
        this.setError(error);
      } finally {
        this.setLoading(false);
      }
    },
    async signOut() {
      this.setUser(null);
      await signOut();
    },
  }),
});
