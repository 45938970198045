import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StyledCard, Table } from 'advanext-components';
import { CompanyPosition } from 'Types';
import { ROUTES } from 'Constants';
import columns from './columns';

type Props = {
  rows: Array<CompanyPosition>;
};

export const MonitoredCompanies = ({ rows }: Props): JSX.Element => {
  const { push } = useHistory();
  const handleOpenCompany = useCallback(
    (id) => push(ROUTES.Company.replace(':companyId', id)),
    [push],
  );

  return (
    <StyledCard title="Monitored Companies">
      <Table
        paginated
        columns={columns({
          onOpenCompany: handleOpenCompany,
        })}
        data={rows}
      />
    </StyledCard>
  );
};

export default MonitoredCompanies;
