import { apiService } from 'Services';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import {
  RequestSheetProcessing,
  SheetError,
} from 'advanext-models/nectar/sheets';
import { SheetsProcess } from 'Constants';

export const deleteReviewApi = async (
  id: string,
  compoundTenantId: CompoundTenantId,
): Promise<string | undefined> => {
  try {
    const url = 'REVIEWAPIPATH'.replace(/:id/g, encodeURIComponent(id));

    await apiService.delete(url, {
      params: {
        compoundTenantId: compoundTenantId.toString(),
        id,
      },
    });

    return;
  } catch (e) {
    return 'Error occured while deleting review';
  }
};

export const processSheetApi = async (
  data: RequestSheetProcessing,
): Promise<string | SheetError> => {
  try {
    const { data: response } = await apiService.post(SheetsProcess, {
      data,
    });
    return response;
  } catch (e) {
    // @ts-ignore
    return e.response.data as SheetError;
  }
};
