import { PersonPosition } from 'Types';

export const headCells: Array<{
  id: keyof PersonPosition;
  label: string;
}> = [
  { id: 'name', label: 'Full Name' },
  { id: 'birthLocation', label: 'From' },
  {
    id: 'signatoryRights',
    label: 'Signatory Rights',
  },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },
];
