import React from 'react';
import { Loading as AmplifyLoading } from 'aws-amplify-react';
import { LinearProgress } from '@material-ui/core';

import { AUTH_STATES } from 'Services/auth';
import Layout from 'Views/providers/Auth/Layout';

/* eslint-disable */

class Loading extends AmplifyLoading {
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line
    this._validAuthStates = [AUTH_STATES.Loading];
  }

  // eslint-disable-next-line
  showComponent() {
    return (
      <Layout>
        <LinearProgress />
      </Layout>
    );
  }
}

export default Loading;
