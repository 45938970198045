import { UserGroup } from 'advanext-models/nectar';
import { user as userSelector } from 'Redux/models/Auth';
import type { PolicyDocument } from 'Types';

const IsAdmin: PolicyDocument = {
  selectors: {
    authUser: userSelector,
  },
  permit: async ({ authUser }) => authUser?.group.includes(UserGroup.ADMIN),
};

export default IsAdmin;
