import { NectarEntity } from 'advanext-models/nectar/entity';
import { FileFolderTreeNode } from 'Types';

const inferMimeType = (fileName: string): string => {
  if (fileName.endsWith('.pdf')) {
    return 'application/pdf';
  }
  if (fileName.endsWith('.json')) {
    return 'application/json';
  }
  if (fileName.endsWith('.html')) {
    return 'text/html';
  }
  return 'text/plain';
};

export const downloadFileContent = (
  content: string | Blob,
  fileName: string,
  contentType?: string,
): void => {
  const cType = contentType || inferMimeType(fileName);
  const blob = new Blob([content], {
    type: `${cType};charset=utf8;`,
  });
  // create and open hidden link
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', window.URL.createObjectURL(blob));
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

/**
 * A simple pointer-based tree builder.
 *
 * == Example of the tee:
 *
 * Input:
 * - folderA/folderB/file.txt
 * - folderC/folderD/file.txt
 * - folderA/folderX/folderY/file.txt
 *
 * Map(
 *   "folderA" => Map(
 *     "folderB" => undefined,
 *     "folderX" => Map(
 *       "folderY" => undefined
 *     )
 *   ),
 *   "folderC" => Map(
 *     "folderD" => undefined
 *   )
 * )
 *
 * @param {NectarEntity[]} entities
 * @return {FileFolderTreeNode}
 */
export const buildFoldersTree = <T = unknown>(
  entities: NectarEntity<T>[],
): FileFolderTreeNode => {
  // Find all unique paths but filenames
  const uniquePaths: string[] = [
    ...new Set(
      entities
        .map(({ file }) => file?.filePath)
        .filter(Boolean)
        .map((path) => (path as string).split('/').slice(0, -1).join('/')),
    ),
  ];

  // Build array of folder arrays from array of string paths
  const paths = uniquePaths.map((path) => path.split('/'));
  const tree: FileFolderTreeNode = new Map<
    string,
    FileFolderTreeNode | undefined
  >();

  // Build a tree by offsetting the pointer
  let pointer = tree;
  for (const path of paths) {
    for (const [idx, folder] of path.entries()) {
      if (!pointer) continue;
      if (!pointer.has(folder) && idx !== path.length - 1)
        pointer.set(folder, new Map<string, FileFolderTreeNode>());
      if (!pointer.has(folder)) pointer.set(folder, undefined);
      pointer = pointer.get(folder) as FileFolderTreeNode;
    }
    pointer = tree;
  }

  return tree;
};

/**
 * Post-Order Tree Traversal.
 *
 * Traverses the tree and applies "callback" to each of the nodes.
 * In the end function responds with an array of results returned by the "callback".
 */
export const traverseFoldersTree = <T>(
  tree: FileFolderTreeNode,
  callback: (
    root: string,
    children: T[],
    level: number,
    path: string,
    node: FileFolderTreeNode,
  ) => T,
  level = 0,
  path = '',
): T[] => {
  const nodes: T[] = [];

  for (const root of tree.keys()) {
    const child = tree.get(root);
    const newPath = path ? path + '/' + root : root;

    if (child) {
      nodes.push(
        callback(
          root,
          traverseFoldersTree(child, callback, level + 1, newPath),
          level,
          newPath,
          tree,
        ),
      );
    } else {
      nodes.push(callback(root, [], level, newPath, tree));
    }
  }

  return nodes;
};
