import React from 'react';
import ReactDOM from 'react-dom';
import validate from 'validate.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as RouterProvider } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ToastProvider } from 'react-toast-notifications';

import theme from 'Config/theme';
import { amplifyConfig, signUpConfig } from './Config/auth';
import store from './Redux/store';
import Root from './Views/pages';
import AuthProvider from './Views/providers/Auth';
import TenantProvider from './Views/providers/Tenant';
import { validators } from 'Utilities';
import * as serviceWorker from './serviceWorker';

import 'Config/theme/css/base.css';
import 'reflect-metadata';

dayjs.extend(isBetween);
dayjs.extend(relativeTime);

validate.validators = {
  ...validate.validators,
  ...validators,
};

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider
            amplifyConfig={amplifyConfig}
            signUpConfig={signUpConfig}
          >
            <ToastProvider autoDismiss>
              <TenantProvider>
                <Root />
              </TenantProvider>
            </ToastProvider>
          </AuthProvider>
        </ThemeProvider>
      </StoreProvider>
    </RouterProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
