import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SignIn as AmplifySignIn } from 'aws-amplify-react';
import {
  Grid,
  Button,
  TextField,
  Link,
  FormHelperText,
} from '@material-ui/core';

import { AUTH_STATES } from 'Services/auth';
import Layout from 'Views/providers/Auth/Layout';

import {
  INITIAL_FORM_STATE,
  formStateApplyEvent,
  formStateTouchAll,
  hasError,
  getError,
} from 'Services/forms';

/* eslint-disable */

const schema = {
  username: { required: true, email: true },
  password: { required: true },
};

const styles = () => ({
  helperText: {
    textAlign: 'center',
  },
  submitButton: {
    width: '100%',
  },
});

class SignIn extends AmplifySignIn {
  constructor(props: any) {
    super(props);

    // eslint-disable-next-line
    this._validAuthStates = [
      AUTH_STATES.SignIn,
      AUTH_STATES.SignedOut,
      AUTH_STATES.SignedUp,
    ];

    this.state = {
      ...(this.state || {}),
      formState: INITIAL_FORM_STATE,
    } as any;
  }

  setStateAsync = async (state: any) => {
    return new Promise((resolve) => {
      // @ts-ignore
      this.setState(state, resolve);
    });
  };

  handleInputChange = async (event: any) => {
    if (event.persist instanceof Function) event.persist();

    const { formState: oldFormState } = this.state as any;
    const formState = formStateApplyEvent(event, oldFormState, schema);
    await this.setStateAsync({ ...this.state, formState });

    return super.handleInputChange(event);
  };

  hasError = (field: string) => {
    return hasError(field, (this.state as any).formState);
  };

  getError = (field: string) => {
    return getError(field, (this.state as any).formState);
  };

  error = (error: any) => {
    super.changeState('signIn');
    super.error(error);
  };

  submitForm = async (event: any) => {
    event.preventDefault();

    const { formState } = this.state as any;
    if (formState.isValid) {
      super.changeState('loading');
      return this.signIn(event);
    }

    this.setStateAsync({
      formState: formStateTouchAll(formState, schema),
    });
  };

  showComponent = () => {
    const { classes } = this.props as any;

    return (
      <Layout onSubmit={this.submitForm}>
        <Grid item>
          <TextField
            error={this.hasError('username')}
            fullWidth
            helperText={this.getError('username')}
            label="Email address"
            name="username"
            onChange={this.handleInputChange}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            error={this.hasError('password')}
            fullWidth
            helperText={this.getError('password')}
            label="Password"
            name="password"
            onChange={this.handleInputChange}
            placeholder="********"
            required
            type="password"
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.submitButton}
            color="primary"
            onClick={this.submitForm}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        </Grid>
        <Grid item>
          <FormHelperText className={classes.helperText}>
            Forgot your password? {/* eslint-disable-next-line  */}
            <Link onClick={() => super.changeState('forgotPassword')}>
              Reset
            </Link>
          </FormHelperText>
        </Grid>
      </Layout>
    );
  };
}

// @ts-ignore
export default withStyles(styles as any)(SignIn);
