import { init, RematchRootState } from '@rematch/core';

import * as models from '../models';

const store = init({ models });

export type RootState = RematchRootState<typeof models>;
export type RootDispatch = typeof store.dispatch;

export default store;
