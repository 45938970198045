import { ApiClient, ApiService } from 'advanext-services';
import { APPLICATIONS_SERVICE_URL } from 'Config';

const nectarAppsClient = new ApiClient(APPLICATIONS_SERVICE_URL ?? '');

const apiService = new ApiService({
  [ApiService.DEFAULT_CLIENT_KEY]: nectarAppsClient,
});

export default apiService;
