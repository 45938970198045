import React from 'react';
import { Alert } from '@material-ui/lab';

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Icon,
} from '@material-ui/core';

type Props = {
  className?: string;
};

const TenantUsageWidget = ({ className = '' }: Props): JSX.Element => (
  <Card className={className}>
    <CardHeader
      title="Usage"
      action={
        <IconButton>
          <Icon>add</Icon>
        </IconButton>
      }
    />
    <CardContent>
      <Alert severity="info">The section is under development.</Alert>
    </CardContent>
  </Card>
);

export default TenantUsageWidget;
