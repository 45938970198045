import React from 'react';
import { StyledCard } from 'advanext-components';
import { CompanyLinkedPerson } from 'advanext-models/advanext/company';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { LinkedOrMemberPerson } from 'Types';
import { headCells } from './constants';
import Row from './Row';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  noData: {
    textAlign: 'center',
    fontSize: '16px',
    color: 'grey',
  },
});

type Props = {
  isLoading: boolean;
  linkedPersons?: LinkedOrMemberPerson[];
};

const LinkedPersons = ({ isLoading, linkedPersons }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <StyledCard title="Linked Persons">
      {isLoading ? (
        <CircularProgress size={40} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '3%' }} />
              {headCells.map(({ id, label, style }) => (
                <TableCell component="th" key={id} style={style}>
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {linkedPersons?.map((e: LinkedOrMemberPerson) => (
              <Row
                key={e instanceof CompanyLinkedPerson ? e.personId : e.userId}
                row={e}
              />
            ))}
            {!linkedPersons?.length && (
              <TableRow>
                <TableCell colSpan={7} className={classes.noData}>
                  Nothing to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </StyledCard>
  );
};

export default LinkedPersons;
