import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { NavLink } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { User } from 'advanext-models/nectar';

import { useSelectors } from 'Hooks';
import {
  user as userSelector,
  loaded as userLoadedSelector,
} from 'Redux/models/Auth';
import { navItems } from 'Config/routes';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    margin: theme.spacing(2, 4, 0, 4),
    background: theme.palette.text.secondary,
  },
  list: {
    margin: theme.spacing(0, -4, 0, -4),
  },
  listHeader: {
    padding: theme.spacing(0, 4, 0, 4),
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint,
    textTransform: 'uppercase',
    lineHeight: theme.typography.body1.lineHeight,
  },
  listItem: {
    width: '100%',
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: fade(theme.palette.background.default, 0.1),
    },
    '&.active': {
      background: fade(theme.palette.background.default, 0.1),
    },
    '&.Mui-selected': {
      background: 'none',
      '& .MuiTypography-root': {
        color: theme.palette.background.default,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: theme.spacing(0.5),
        height: '100%',
        background: theme.palette.background.default,
        left: 0,
        top: 0,
      },
    },
  },
  listItemIcon: {
    color: theme.palette.text.hint,
  },
}));

const SidebarMenu = (): JSX.Element => {
  const classes = useStyles();

  const [user, userLoaded] = useSelectors([userSelector, userLoadedSelector]);

  return !userLoaded ? (
    <Skeleton className={classes.skeleton} variant="rect" />
  ) : (
    <>
      {navItems.map((e, index) =>
        (user as User)?.group.includes(e.access) ? (
          <List
            className={classes.list}
            key={index}
            subheader={
              e.section ? (
                <ListSubheader component="div" className={classes.listHeader}>
                  {e.section}
                </ListSubheader>
              ) : undefined
            }
          >
            {e.items.map(({ to, id, label, icon: Icon }) => (
              <ListItem
                dense
                button
                color="primary"
                className={classes.listItem}
                to={to}
                key={id}
                component={NavLink}
              >
                <ListItemIcon>
                  <Icon fontSize="small" className={classes.listItemIcon} />
                </ListItemIcon>

                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        ) : null,
      )}
    </>
  );
};
export default SidebarMenu;
