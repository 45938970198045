import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import DebouncePromise from 'awesome-debounce-promise';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import AsyncSelect from 'react-select/async';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorAlert, LoadingAlert, PageTitle } from 'advanext-components';
import Status from 'advanext-models/nectar/uwtask/Status';
import {EntityType, NectarEntity} from 'advanext-models/nectar/entity';
import { Company } from 'advanext-models/advanext/company';
import "reflect-metadata";
import {
  useConstant,
  useMonitoredCompaniesInfo,
  useUWTasks,
  useEntities,
} from 'Hooks';

import {
  CountryOption,
  CountryValueContainer,
  CompanyOption,
} from './components';

import { getCompanies } from 'Api';
import { TenantProviderContext } from 'Views/providers/Tenant';
import { ROUTES, searchCountriesOptions } from 'Constants';
import { OptionType } from 'Types';
import MonitoredCompanies from './MonitoredCompanies';
import RecentCompanies from './RecentCompanies';
import { ONBOARDED_COMPANIES_FILTER } from 'Utilities';
import {plainToClass} from "class-transformer";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
}));

const Companies = (): JSX.Element => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [country, setCountry] = useState<OptionType>(searchCountriesOptions[0]);
  const countryOptionType = country as OptionType;
  const { push } = useHistory();

  const handleOpenCompany: ({ value }: { value: any }) => void = useCallback(
    ({ value }) => push(ROUTES.Company.replace(':companyId', value)),
    [push],
  );

  const handleSelectCountry = useCallback((country) => setCountry(country), []);

  const debouncedFetchCompanies = useConstant(() =>
    DebouncePromise(async (inputValue: string, country: string) => {
      if (inputValue) {
        try {
          const companies = await getCompanies(inputValue, country);
          return companies.map(({ address, id, name }) => ({
            label: { address, name },
            value: id,
          }));
        } catch (e) {
          addToast('Search functionality is currently offline', {
            appearance: 'error',
          });
          return [];
        }
      }
      return [];
    }, 700),
  );
  const handleSearchCompany = useCallback(
    (inputValue: string) => debouncedFetchCompanies(inputValue, country.value),
    [debouncedFetchCompanies, country.value],
  );

  const { data: uwTasks } = useUWTasks();
  const { compoundTenantId } = useContext(TenantProviderContext);

  const { entities: recentCompanies, loading: isRecentCompaniesLoading } =
    useEntities<Company>(compoundTenantId, undefined, {
      entityType: EntityType.COMPANY_INFORMATION,
      filterExpression: ONBOARDED_COMPANIES_FILTER,
      sort: 'descending',
    });

  function getDecorators(target: any, propertyName: string | symbol): string[] {
    // get info about keys that used in current property
    console.log("metadatas:")
    // console.log(Reflect.getMetadata(target, propertyName))
    console.log(Reflect.getMetadataKeys(target))
    // console.log(Reflect.getOwnMetadata(target, propertyName))
    console.log("metadatas over:")
    const keys: any[] = Reflect.getMetadataKeys(target, propertyName);
    const decorators = keys
        // filter your custom decorators
        .filter(key => key.toString().startsWith("custom:anotations"))
        .reduce((values, key) => {
          // get metadata value.
          const currValues = Reflect.getMetadata(key, target, propertyName);
          return values.concat(currValues);
        }, []);

    return decorators;
  }

  if(recentCompanies.length > 0){
    // console.log("loaded companies, head:")
    // console.log(getDecorators(NectarEntity, "entityType"))
    // console.log(recentCompanies[0])
    // console.log(plainToClass(NectarEntity, recentCompanies[0]))
  }

  const {
    data: companiesInfo,
    isLoading: isCompaniesInfoLoading,
    error: companiesInfoError,
  } = useMonitoredCompaniesInfo();

  const rows = _.sortBy(companiesInfo?.map((e1) => {
    const match = uwTasks?.filter((e2) => e2.company.toString() === e1.id);

    return {
      ...e1,
      isCompleted:
        match?.length > 0
          ? match.every(
              (e3) =>
                e3.status === Status.Completed ||
                e3.status === Status.NotRequired,
            )
          : false,
    };
  }), [(el) => el.name?.toLowerCase()]);

  return (
    <>
      <PageTitle title="Companies" />
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5" className={classes.subtitle}>
          Company Lookup
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Select
              value={country}
              options={searchCountriesOptions}
              onChange={handleSelectCountry}
              components={{
                Option: CountryOption,
                ValueContainer: CountryValueContainer,
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <AsyncSelect
              cacheOptions
              blurInputOnSelect
              // @ts-ignore
              onChange={handleOpenCompany}
              loadOptions={handleSearchCompany}
              placeholder="Search For Companies"
              components={{
                Option: CompanyOption,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box mt={2}>
        {isCompaniesInfoLoading ? (
          <LoadingAlert text="Monitored companies are loading, please wait" />
        ) : companiesInfoError ? (
          <ErrorAlert text="Failed to load monitored companies" />
        ) : (
          <MonitoredCompanies rows={rows ?? []} />
        )}
      </Box>
      <Box mt={2}>
        {isRecentCompaniesLoading ? (
          <LoadingAlert text="Recent Sign Ups are being loaded, please wait" />
        ) : (
          <RecentCompanies entities={recentCompanies} />
        )}
      </Box>
    </>
  );
};

export default Companies;
