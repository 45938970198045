import { Order, SortOrder } from 'Types';
import get from 'lodash.get';

export const stringToColor = (str: string): string => {
  const charsSum = str
    .split('')
    .reduce((sum, char) => sum + char.charCodeAt(0), 0);
  return `#${(charsSum % 0xffffff).toString(16)}`;
};

export const EMPTY_STRING_DISPLAY_VALUE = 'N/A';

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
): SortOrder {
  if (get(b, orderBy) < get(a, orderBy)) return -1;
  if (get(b, orderBy) > get(a, orderBy)) return 1;
  return 0;
}

export function getComparator<T>(
  orderBy: keyof T,
  order?: Order,
): (a: T, b: T) => number {
  return order === Order.DESC
    ? (a: T, b: T): SortOrder => descendingComparator(a, b, orderBy)
    : (a: T, b: T): SortOrder =>
        -descendingComparator(a, b, orderBy) as SortOrder;
}

export const getValueOrNA = (value?: string | number): string | number => {
  if (value === undefined) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }
  return value;
};

export const getValueOrNAExtended =
  (value?: string | number) =>
  (mapping: { [key: string]: JSX.Element }): JSX.Element | string => {
    if (value === undefined) {
      return EMPTY_STRING_DISPLAY_VALUE;
    }
    return mapping[value];
  };

export const getYesNoValue = (value?: boolean): string => {
  if (value === undefined) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }
  return value ? 'Yes' : 'No';
};

export const getYesNoValueExtended =
  (value?: boolean) =>
  (yes: JSX.Element, no: JSX.Element): JSX.Element | string => {
    if (value === undefined) {
      return EMPTY_STRING_DISPLAY_VALUE;
    }
    return value ? yes : no;
  };

export const convertBooleanToString = (
  value?: boolean,
  defaultValue?: string,
): string | undefined => {
  switch (value) {
    case true:
      return 'TRUE';
    case false:
      return 'FALSE';
    default:
      return defaultValue || undefined;
  }
};

export const getDateDiffOrNA = (value?: number): string => {
  if (value === undefined) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }
  const dateDiff = new Date(new Date().setDate(new Date().getDate() - value));
  const formattedDateDiff = `${dateDiff.getFullYear()}-${dateDiff.getMonth()}-${dateDiff.getDate()}`;
  return `${formattedDateDiff} (${value} days ago)`;
};

export const openUrl = (url: string): void => {
  window.open(url, '_blank');
};

export const openBinaryFile = (binaryFile: Blob): void => {
  window.open(URL.createObjectURL(binaryFile), '_blank');
};
export const formatDate = (date?: Date | string): string => {
  if (date) {
    if (typeof date === 'string')
      return new Date(date).toLocaleDateString('en-CH');
    return date.toLocaleDateString('en-CH');
  }
  return EMPTY_STRING_DISPLAY_VALUE;
};

export const formatDateTime = (date?: Date | string): string => {
  if (date) {
    if (typeof date === 'string')
      return `${new Date(date).toLocaleDateString('en-CH')} ${new Date(
        date,
      ).toLocaleTimeString('en-CH')}`;
    return `${date.toLocaleDateString('en-CH')} ${date.toLocaleTimeString(
      'en-CH',
    )}`;
  }
  return EMPTY_STRING_DISPLAY_VALUE;
};

export const formatDateString = (date?: string): string => {
  if (date) {
    return new Date(date).toLocaleDateString('en-CH');
  }
  return EMPTY_STRING_DISPLAY_VALUE;
};

export const readFile = async (
  file: File,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (): void => {
      resolve(reader.result);
    };
  });
};

export const formatMoney = (number?: number | string): string => {
  let parsed = number;
  switch (typeof number) {
    case 'string':
      parsed = parseFloat(number.replace(/,/g, ''));
      if (isNaN(parsed)) {
        return EMPTY_STRING_DISPLAY_VALUE;
      }
    // eslint-disable-next-line no-fallthrough
    case 'number': {
      if (parsed === -Infinity || parsed === Infinity) {
        return EMPTY_STRING_DISPLAY_VALUE;
      }
      return (+(parsed as number).toFixed(2)).toLocaleString('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'CHF',
      });
    }
    case 'undefined':
    default:
      return EMPTY_STRING_DISPLAY_VALUE;
  }
};

export const formatNumber = (number?: number | string): string => {
  let parsed = number;
  switch (typeof number) {
    case 'string':
      parsed = parseFloat(number.replace(/,/g, ''));
      if (isNaN(parsed)) {
        return EMPTY_STRING_DISPLAY_VALUE;
      }
    // eslint-disable-next-line no-fallthrough
    case 'number': {
      if (parsed === -Infinity || parsed === Infinity) {
        return EMPTY_STRING_DISPLAY_VALUE;
      }
      return (+(parsed as number).toFixed(2)).toLocaleString('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    case 'undefined':
    default:
      return EMPTY_STRING_DISPLAY_VALUE;
  }
};

export const sleep = (delay: number): Promise<() => {}> =>
  new Promise((resolve) => setTimeout(resolve, delay));
