import {
  CreateResultRequest,
  DeactivateResultRequest,
} from 'advanext-models/nectar/astrea';
import { apiService } from 'Services';
import { ScoreResultPath } from 'Constants';

export const createScoreResultApi = async ({
  blueprintId,
  referenceId,
  adminId,
  compoundTenant,
  answers,
}: CreateResultRequest): Promise<string | undefined> => {
  try {
    await apiService.post(`${ScoreResultPath}/create`, {
      data: {
        blueprintId,
        referenceId,
        adminId,
        compoundTenant,
        answers,
      },
      params: { compoundTenantId: compoundTenant?.toString() },
    });
    return;
  } catch (e) {
    return 'Error occurs while uploading answers';
  }
};

export const deactivateScoreResultApi = async ({
  type,
  country,
  referenceId,
  compoundTenant,
}: DeactivateResultRequest): Promise<string | undefined> => {
  try {
    await apiService.post(`${ScoreResultPath}/deactivate`, {
      data: {
        type,
        country,
        referenceId,
        compoundTenant,
      },
      params: { compoundTenantId: compoundTenant?.toString() },
    });
    return;
  } catch (e) {
    return 'Error occurs while deactivating';
  }
};
