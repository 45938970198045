import React from 'react';
import { MonitoringChangesStatus } from 'advanext-models/nectar/monitoring';
import MonitoringTable from 'Views/pages/owner/Company/Monitoring/MonitoringTable';

const Monitoring = (): JSX.Element => {
  return (
    <>
      <MonitoringTable
        status={MonitoringChangesStatus.UNPROCESSED}
        title="Unprocessed Monitoring Events"
        isCompanyNameShown={true}
      />
      <MonitoringTable
          status={MonitoringChangesStatus.PROGRESS}
          title="In progress Monitoring Events"
          isCompanyNameShown={true}
      />
      <MonitoringTable
        status={MonitoringChangesStatus.PROCESSED}
        title="Processed Monitoring Events"
        isCompanyNameShown={true}
      />
    </>
  );
};
export default Monitoring;
