import useSWR from 'swr';
import type { Tenant } from 'advanext-models/nectar/tenant';

import { TenantPath } from 'Constants';
import { fetcher } from 'Api';
import { ApiEntity } from 'Types';

export const useTenant = (id: string): ApiEntity<Tenant> => {
  const { data, error } = useSWR(
    TenantPath.replace(/:id/g, encodeURIComponent(id)),
    fetcher,
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  } as ApiEntity<Tenant>;
};
