import { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import type { PolicyDocument } from 'Types';
import { AndPolicy } from 'Policies/documents';

export const usePolicy = (
  documents: Array<PolicyDocument>,
): [boolean, boolean] => {
  const selectors = createStructuredSelector(
    documents.reduce(
      (memo: Record<string, unknown>, policy) => ({
        ...memo,
        ...(policy.selectors || {}),
      }),
      {},
    ) as never,
  );

  const state = useSelector(selectors);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(true);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const policyResult = await AndPolicy(documents).permit(state, dispatch);
      setResult(policyResult);
      setLoading(false);
    })();
  }, []); // eslint-disable-line

  return [result, loading];
};
