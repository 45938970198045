import React, { useState, useCallback, useMemo } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { StyledCard, StyledDialog } from 'advanext-components';
import { NectarEntity, EntityType } from 'advanext-models/nectar/entity';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import {
  buildFoldersTree,
  getComparator,
  downloadFileContent,
} from 'Utilities';

import Row from './Row';
import { Order, OnCreateEntityEvent } from 'Types';
import { SortOrder } from 'Types';
import { useEntities, useTenantMembers } from 'Hooks';
import { SHARED_TENANT } from 'Constants';
import { downloadFile, putFile, moveFileApi } from 'Api';
import { headCells, DocumentPosition } from './constants';
import EntityUploader from './EntityUploader';
import FoldersComponent from './FoldersComponent';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  spinnerBox: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type Props = {
  compoundCompanyId: CompoundCompanyId;
  compoundTenantId: CompoundTenantId;
};

const Documents = ({
  compoundCompanyId,
  compoundTenantId,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const { data } = useTenantMembers(compoundTenantId.tenant);

  const {
    entities: privateEntities,
    loading: privateLoading,
    update,
    remove,
    create,
    createFile,
    loadMore: loadPrivateEntities,
  } = useEntities(compoundTenantId, compoundCompanyId, { fetchAll: true });

  const {
    entities: sharedEntities,
    loading: sharedLoading,
    loadMore: loadSharedEntities,
  } = useEntities(SHARED_TENANT, compoundCompanyId, { fetchAll: true });

  const documents = useMemo(
    () =>
      [...privateEntities, ...sharedEntities]
        .filter(({ file }) => !!file?.fileId)
        .sort(({ created: c1 }, { created: c2 }) => +c1 - +c2),
    [privateEntities, sharedEntities],
  );

  const foldersTree = buildFoldersTree(documents);

  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof DocumentPosition>();
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);

  const openDeleteConfirmation = useCallback(
    (id) => setFileToDelete(id),
    [setFileToDelete],
  );

  const closeDeleteConfirmation = useCallback(
    () => setFileToDelete(null),
    [setFileToDelete],
  );

  const handleRequestSort = (property: keyof DocumentPosition): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const handleCreate = useCallback(
    async (data: OnCreateEntityEvent) => {
      const { documentType, metadata, file } = data;

      const newEntity = await create(
        new NectarEntity({
          entityId: '',
          entityType: EntityType.DOCUMENT,
          compoundTenantId: compoundTenantId,
          compoundCompanyId: compoundCompanyId,
          created: new Date(),
        }),
      );

      const { uploadUrl } = await createFile({
        entityId: newEntity.entityId,
        filename: file.name,
        contentType: file.type,
        documentType,
      });

      await putFile(uploadUrl, file, file.type);

      if (metadata) await update(newEntity.entityId, metadata);
    },
    [compoundCompanyId, compoundTenantId, create, createFile, update],
  );

  const handleDownload = useCallback(
    async (fileId: string, filename: string, contentType: string) => {
      const content = await downloadFile(fileId);
      downloadFileContent(content, filename, contentType);
    },
    [],
  );

  const handleMove = useCallback(
    async (fileId: string, filePath: string) => {
      await moveFileApi(fileId, filePath);
      await loadPrivateEntities(true);
      await loadSharedEntities(true);
    },
    [loadPrivateEntities, loadSharedEntities],
  );

  const currentFolderFiles = documents.filter(
    ({ file }) => !currentPath || file?.filePath?.startsWith(currentPath),
  );

  if (privateLoading || sharedLoading) {
    return (
      <Box mb={4} className={classes.spinnerBox}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <>
      <Box mb={4}>
        <StyledCard title="Folders">
          <FoldersComponent
            currentPath={currentPath}
            foldersTree={foldersTree}
            onChange={setCurrentPath}
            onReload={async (): Promise<void> => {
              await loadPrivateEntities(true);
              await loadSharedEntities(true);
            }}
          />
        </StyledCard>
      </Box>
      <StyledCard title="Documents">
        <EntityUploader onCreate={handleCreate} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '3%' }} />
              {headCells.map(({ id, label, style, isSortable }) =>
                isSortable ? (
                  <TableCell
                    component="th"
                    sortDirection={orderBy === id ? order : false}
                    key={id}
                    style={style}
                  >
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : Order.ASC}
                      onClick={(): void => handleRequestSort(id)}
                      IconComponent={ArrowDropDown}
                    >
                      <strong>{label}</strong>
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell component="th" key={id} style={style}>
                    <strong>{label}</strong>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentFolderFiles.length ? (
              currentFolderFiles
                .sort(
                  //@ts-expect-error
                  // Fix when correct BE data and type from models available
                  orderBy
                    ? getComparator<DocumentPosition>(orderBy, order)
                    : (): SortOrder => 0,
                )
                .map((e: NectarEntity<unknown>) => (
                  <Row
                    key={e.entityId}
                    row={e}
                    onDelete={openDeleteConfirmation}
                    onUpdate={update}
                    onMove={handleMove}
                    onDownload={handleDownload}
                    members={data || []}
                  />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1">No files to show.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledCard>
      <StyledDialog
        isOpen={!!fileToDelete}
        onClose={closeDeleteConfirmation}
        onConfirm={async (): Promise<void> => {
          if (fileToDelete) await remove(fileToDelete);
          closeDeleteConfirmation();
        }}
        header="Remove Document"
        confirmBtnName="Remove"
      >
        Please confirm if you really want to remove the selected document
      </StyledDialog>
    </>
  );
};

export default Documents;
