import React, { useMemo, useState } from 'react';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { StyledCard } from 'advanext-components';
import classnames from 'classnames';
import {
  BalanceSheet,
  BalanceSheetRatios,
  FinancialStatement,
  IncomeStatement,
} from 'advanext-models/nectar/financialstatement';
import { FinancialStatementNectarScore } from 'advanext-models/nectar/score';
import { NectarEntity } from 'advanext-models/nectar/entity';
import { Chip, Button, ButtonGroup, Typography } from '@material-ui/core';

import { useEntities } from 'Hooks';
import FinancialDataGrid from './FinancialDataGrid';

import {
  fillFinancialData,
  fillRatios,
  assetsColumns,
  liabilitiesColumns,
  bsRatiosColumns,
  incomeStatementColumns,
  incomeStatementKpisColumns,
} from './helpers';

const SECTION_BALANCE_SHEET = 'balance-sheet';
const SECTION_INCOME_STATEMENT = 'income-statement';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.speedDial,
  },
  buttonGroup: {
    textAlign: 'center',
  },
  section: {
    margin: theme.spacing(3, 0),
  },
}));

type Props = {
  loading: boolean;
  entities: NectarEntity<FinancialStatement>[];
  financialStatements?: { [key: number]: FinancialStatement };
  financialStatementScores?: { [key: number]: FinancialStatementNectarScore };
  onRefresh: () => void | Promise<void>;
};

const FinancialStatements = ({
  onRefresh,
  loading,
  entities,
  financialStatements,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [activeSection, setActiveSection] = useState(SECTION_BALANCE_SHEET);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);

  const years = useMemo(
    () =>
      Object.keys(financialStatements || {})
        .slice()
        .map(Number)
        .sort(),
    [financialStatements],
  );

  const { remove } = useEntities<FinancialStatement>();

  return (
    <>
      <div className={classnames(classes.section, classes.buttonGroup)}>
        <ButtonGroup>
          <Button
            color={
              activeSection === SECTION_BALANCE_SHEET ? 'primary' : 'default'
            }
            onClick={(): void => setActiveSection(SECTION_BALANCE_SHEET)}
          >
            Balance Sheet
          </Button>
          <Button
            color={
              activeSection === SECTION_INCOME_STATEMENT ? 'primary' : 'default'
            }
            onClick={(): void => setActiveSection(SECTION_INCOME_STATEMENT)}
          >
            Income Statement
          </Button>
        </ButtonGroup>
      </div>
      <StyledCard title="Financial Statements">
        <Typography variant="subtitle1">
          Available Financial Statements
        </Typography>

        {years.map((year) => (
          <>
            <Chip
              key={year}
              disabled={loading}
              color={selectedYears.includes(year) ? 'primary' : 'default'}
              size="small"
              clickable
              label={year.toString()}
              deleteIcon={<DeleteForever />}
              onClick={(): void => {
                selectedYears.includes(year)
                  ? setSelectedYears(selectedYears.filter((y) => y !== year))
                  : setSelectedYears(
                      Array.from(new Set([...selectedYears, year])),
                    );
              }}
              onDelete={async (): Promise<void> => {
                await Promise.all(
                  entities
                    .filter(({ content }) => content?.year === year)
                    .map(({ entityId }) => entityId)
                    .map(remove),
                );

                await onRefresh();
              }}
            />{' '}
          </>
        ))}

        {activeSection === SECTION_BALANCE_SHEET && (
          <>
            <div className={classes.section}>
              <FinancialDataGrid
                tableName="Assets"
                data={fillFinancialData<BalanceSheet>(
                  Object.entries(financialStatements ?? []).map(
                    ([year, fs]) => [year, fs.balanceSheet],
                  ),
                  assetsColumns,
                )}
                years={selectedYears.length === 0 ? years : selectedYears}
              />
            </div>

            <div className={classes.section}>
              <FinancialDataGrid
                tableName="Equity and Liabilities"
                data={fillFinancialData<BalanceSheet>(
                  Object.entries(financialStatements ?? []).map(
                    ([year, fs]) => [year, fs.balanceSheet],
                  ),
                  liabilitiesColumns,
                )}
                years={selectedYears.length === 0 ? years : selectedYears}
              />
            </div>

            <div className={classes.section}>
              <FinancialDataGrid
                tableName="KPIs"
                data={fillRatios<BalanceSheetRatios>(
                  Object.entries(financialStatements ?? []).map(
                    ([year, fs]) => [year, fs.bsRatios],
                  ),
                  bsRatiosColumns,
                )}
                years={selectedYears.length === 0 ? years : selectedYears}
              />
            </div>
          </>
        )}

        {activeSection === SECTION_INCOME_STATEMENT && (
          <>
            <div className={classes.section}>
              <FinancialDataGrid
                tableName="Income Statement"
                data={fillFinancialData<IncomeStatement>(
                  Object.entries(financialStatements ?? []).map(
                    ([year, fs]) => [year, fs.incomeStatement],
                  ),
                  incomeStatementColumns,
                )}
                years={selectedYears.length === 0 ? years : selectedYears}
              />
            </div>

            <div className={classes.section}>
              <FinancialDataGrid
                tableName="KPIs"
                data={fillFinancialData<IncomeStatement>(
                  Object.entries(financialStatements ?? []).map(
                    ([year, fs]) => [year, fs.incomeStatement],
                  ),
                  incomeStatementKpisColumns,
                )}
                years={selectedYears.length === 0 ? years : selectedYears}
              />
            </div>
          </>
        )}
      </StyledCard>
      {/* <div className={classes.section} />
      <StyledCard title="Financial Statement Ratios">
        <div className={classes.section}>
          <Typography variant="subtitle1">Ratios</Typography>
          <FinancialDataGrid
            columns={financialStatementRatiosTableData}
            rows={financialStatements}
            years={years}
            selectedYears={selectedYears}
          />
        </div>
        <div className={classes.section}>
          <Typography variant="subtitle1">
            Scores
          </Typography>
          <FinancialDataGrid
            columns={financialStatementScoresTableData}
            rows={financialStatementScores}
            years={years}
          />
        </div>
      </StyledCard> */}
    </>
  );
};

export default FinancialStatements;
