import { Loan } from 'advanext-models/advanext/loan';

export const headCells: Array<{
  id: keyof Loan;
  label: string;
  style?: { width: string };
}> = [
  {
    id: 'loanId',
    label: 'ID',
  },
  {
    id: 'activated',
    label: 'Activation Date',
  },
  {
    id: 'productType',
    label: 'Product',
    style: { width: '20%' },
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'state',
    label: 'Status',
  },
  {
    id: 'creditArrangementId',
    label: 'Credit Arrangement',
  },
];
