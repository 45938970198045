import { Country, Address } from 'advanext-models/advanext';
import { COMPANY_ROUTES } from 'Constants';

export const getCountry = (
  country?: Country,
): { isCH: boolean; isDE: boolean } => ({
  isCH: country === Country.CH,
  isDE: country === Country.DE,
});

export const getCompanyFullAddressFormatted = (address: Address): string =>
  `${address.address}, ${address.town}, ${address.postalCode}, ${address.countryCode}`;

export const getCompanyAddressFormatted = (address: Address): string =>
  `${address.address}, ${address.town}`;

export const getCompanyActiveTab = (pathname: string): number => {
  const currentPath = `/${pathname.split('/').slice(-1)[0]}`;
  switch (currentPath) {
    case COMPANY_ROUTES.Reports:
      return 1;
    case COMPANY_ROUTES.DueDiligence:
      return 2;
    case COMPANY_ROUTES.FinancialStatements:
      return 3;
    case COMPANY_ROUTES.BankStatements:
      return 4;
    case COMPANY_ROUTES.CompanyDueDiligence:
      return 5;
    case COMPANY_ROUTES.Monitoring:
      return 6;
    case COMPANY_ROUTES.Documents:
      return 7;
    case COMPANY_ROUTES.Overview:
    default:
      return 0;
  }
};
