import cn from 'classnames';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import React, { useState, useCallback, useEffect, MouseEvent } from 'react';
import { User } from 'advanext-models/nectar';
import { extractTypeAndNameFromFilePath } from 'advanext-models/advanext/document';
import { StyledInput, StyledSelect } from 'advanext-components';
import { makeStyles } from '@material-ui/core/styles';

import Theme from 'Config/theme';

import {
  NectarEntity,
  EntityStatus,
  EntityMetadata,
} from 'advanext-models/nectar/entity';

import {
  IconButton,
  TableCell,
  TableRow,
  Chip,
  Popover,
  Link,
} from '@material-ui/core';

import {
  Edit,
  Save,
  Cancel,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';

import {
  entityStatusesOptions,
  getDocumentType,
  getEntityStatus,
  getNameFromPath,
} from 'Utilities';

import { OptionType } from 'Types';
import updatesColumns from './updates.json';

const useStyles = makeStyles((theme: typeof Theme) => ({
  td: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  eventStatus: {
    color: '#000000',
    minWidth: '100px',
  },
  unprocessedStatus: {
    backgroundColor: '#f9d7a1',
    border: '1px solid #f29f3e',
  },
  approvedStatus: {
    backgroundColor: '#bfddbc',
    border: '1px solid #67ac5c',
  },
  declinedStatus: {
    backgroundColor: '#f1b7b1',
    border: '1px solid #e87668',
  },
  pendingStatus: {
    backgroundColor: '#f9d7a1',
    border: '1px solid #f29f3e',
  },
  updatesRow: {
    background: '#f2f2f2',
  },
  noBorder: {
    border: 'none',
  },
  popover: {
    padding: theme.spacing(3),
    display: 'flex',
  },
}));

const INITIAL_STATUS = EntityStatus.UNPROCESSED;
const INITIAL_STATUS_OPTION = {
  label: getEntityStatus(INITIAL_STATUS),
  value: INITIAL_STATUS,
};
const INITIAL_COMMENT = '';

type Props = {
  row: NectarEntity<unknown>;
  onDelete: (entityId?: string) => void;
  onUpdate: (entityId: string, metadata: EntityMetadata) => void;
  onMove: (fileId: string, path: string) => void;
  onDownload: (fileId: string, filename: string, contentType: string) => void;
  members: User[];
};

const Row = ({
  row,
  onDelete,
  onUpdate,
  onMove,
  onDownload,
  members,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [status, setStatus] = useState<OptionType>(INITIAL_STATUS_OPTION);
  const [comment, setComment] = useState(INITIAL_COMMENT);
  const [path, setPath] = useState(row.file?.filePath || '');
  const [showEditPath, setShowEditPath] = useState<HTMLAnchorElement | null>(
    null,
  );
  const [documentType] = extractTypeAndNameFromFilePath(path) || [];

  const classes = useStyles();

  const handleSelectStatus = useCallback(
    (statusOption) => setStatus(statusOption),
    [setStatus],
  );

  const handleCommentChange = useCallback(
    (event) => setComment(event.target.value),
    [setComment],
  );

  const handlePathChange = useCallback(
    (event) => setPath(event.target.value),
    [setPath],
  );

  useEffect(() => {
    if (isEditMode) return;

    setStatus(INITIAL_STATUS_OPTION);
    setComment(INITIAL_COMMENT);
  }, [isEditMode]);

  useEffect(() => {
    if (!isEditMode) return;

    setPath(row.file?.filePath || '');
  }, [isEditMode, row.file]);

  const handleSave = useCallback(() => {
    onUpdate(
      row.entityId,
      new EntityMetadata({
        status: status.value as EntityStatus,
        modified: new Date(),
        modifiedBy: '', // Will be filled by BE
        comment,
      }),
    );
  }, [comment, status, onUpdate, row.entityId]);

  const handleMove = useCallback(() => {
    if (!row.file) return;

    onMove(row.file?.fileId, path);
  }, [onMove, path, row.file]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(): void => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          {dayjs(row.created).format('YYYY-MM-DD HH:mm:ss')}
        </TableCell>
        <TableCell>
          <Link
            onClick={(e: MouseEvent<HTMLAnchorElement>): void =>
              setShowEditPath(e.currentTarget)
            }
          >
            {getDocumentType(documentType)}
          </Link>
          <Popover
            classes={{ paper: classes.popover }}
            open={Boolean(showEditPath)}
            anchorEl={showEditPath}
            onClose={(): void => setShowEditPath(null)}
          >
            <StyledInput
              value={path}
              onChange={handlePathChange}
              className="table"
            />
            <IconButton size="small" color="inherit" onClick={handleMove}>
              <Save />
            </IconButton>
          </Popover>
        </TableCell>
        <TableCell>
          <Link
            onClick={(): void =>
              onDownload(
                row.file?.fileId as string,
                row.file?.filename as string,
                row.file?.contentType as string,
              )
            }
          >
            {row.file?.filename ?? getNameFromPath(row.file?.filePath)}
          </Link>
        </TableCell>
        <TableCell>
          <StyledSelect
            value={status}
            options={entityStatusesOptions}
            onChange={handleSelectStatus}
            className="table"
            disabled={!isEditMode}
            disabledComponent={
              <Chip
                size="small"
                color="secondary"
                label={getEntityStatus(row.metadata?.status)}
                className={cn(classes.eventStatus, {
                  [classes.unprocessedStatus]:
                    row.metadata?.status === EntityStatus.UNPROCESSED,
                  [classes.approvedStatus]:
                    row.metadata?.status === EntityStatus.APPROVED,
                  [classes.declinedStatus]:
                    row.metadata?.status === EntityStatus.DECLINED,
                  [classes.pendingStatus]:
                    row.metadata?.status === EntityStatus.PENDING,
                })}
              />
            }
          />
        </TableCell>
        <TableCell className={classes.td} title={row.metadata?.comment}>
          <StyledInput
            value={comment}
            onChange={handleCommentChange}
            disabled={!isEditMode}
            className="table"
            disabledComponent={<span>{row.metadata?.comment}</span>}
          />
        </TableCell>
        <TableCell>
          {isEditMode ? (
            <>
              <IconButton onClick={handleSave} size="small" color="inherit">
                <Save />
              </IconButton>
              <IconButton
                onClick={(): void => setEditMode(false)}
                size="small"
                color="inherit"
              >
                <Cancel />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={(): void => {
                  setEditMode(true);
                }}
                size="small"
                color="inherit"
              >
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                onClick={(): void => onDelete(row.entityId)}
                color="inherit"
              >
                <Delete />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      {open && (
        <>
          <TableRow className={classes.updatesRow}>
            <TableCell className={classes.noBorder} />
            <TableCell colSpan={6} className={classes.noBorder}>
              <h3>Status Updates</h3>
            </TableCell>
          </TableRow>
          <TableRow className={classes.updatesRow}>
            <TableCell className={classes.noBorder} />
            {updatesColumns.map(({ id, label }) => (
              <TableCell key={id}>
                <strong>{label}</strong>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
          {[row.metadata, ...(row.metadata?.history ?? [])]
            .sort((a, b) => {
              return (
                (b?.modified?.getTime() ?? 0) - (a?.modified?.getTime() ?? 0)
              );
            })
            .map((history) => {
              const member = history?.modifiedBy
                ? members.find(
                    (member: User) => member.id === history?.modifiedBy,
                  )
                : undefined;
              return (
                <TableRow key={uuid()} className={classes.updatesRow}>
                  <TableCell className={classes.noBorder} />
                  <TableCell>
                    {dayjs(history?.modified).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {/* Document type depends on the path and can not be changed! */}
                    <span>{getDocumentType(documentType)}</span>
                  </TableCell>
                  <TableCell>
                    {member?.firstName || member?.lastName
                      ? `${member.firstName} ${member.lastName}`
                      : 'n/a'}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color="secondary"
                      label={getEntityStatus(history?.status)}
                      className={cn(classes.eventStatus, {
                        [classes.unprocessedStatus]:
                          history?.status === EntityStatus.UNPROCESSED,
                        [classes.approvedStatus]:
                          history?.status === EntityStatus.APPROVED,
                        [classes.declinedStatus]:
                          history?.status === EntityStatus.DECLINED,
                        [classes.pendingStatus]:
                          history?.status === EntityStatus.PENDING,
                      })}
                    />
                  </TableCell>
                  <TableCell colSpan={2} title={history?.comment}>
                    {history?.comment}
                  </TableCell>
                </TableRow>
              );
            })}
        </>
      )}
    </>
  );
};

export default Row;
