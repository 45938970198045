import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmSignUp as AmplifyConfirmSignUp } from 'aws-amplify-react';
import {
  Grid,
  Button,
  TextField,
  Link,
  FormHelperText,
} from '@material-ui/core';

import { AUTH_STATES } from 'Services/auth';

import {
  INITIAL_FORM_STATE,
  formStateApplyEvent,
  formStateTouchAll,
  hasError,
  getError,
} from 'Services/forms';

import Layout from 'Views/providers/Auth/Layout';

const schema = {
  code: { required: true },
};
/* eslint-disable */

const styles = () => ({
  helperText: {
    textAlign: 'center',
  },
  submitButton: {
    width: '100%',
  },
});

class ConfirmSignUp extends AmplifyConfirmSignUp {
  constructor(props: any) {
    super(props);

    // eslint-disable-next-line
    this._validAuthStates = [AUTH_STATES.ConfirmSignUp];

    this.state = {
      ...(this.state || {}),
      formState: INITIAL_FORM_STATE,
    } as any;
  }

  setStateAsync = async (state: any) => {
    return new Promise((resolve) => {
      // @ts-ignore
      this.setState(state, resolve);
    });
  };

  handleInputChange = async (event: any) => {
    if (event.persist instanceof Function) event.persist();

    const { formState: oldFormState } = this.state as any;
    const formState = formStateApplyEvent(event, oldFormState, schema);
    await this.setStateAsync({ ...this.state, formState });

    return super.handleInputChange(event);
  };

  hasError = (field: string) => {
    return hasError(field, (this.state as any).formState);
  };

  getError = (field: string) => {
    return getError(field, (this.state as any).formState);
  };

  error = (error: any) => {
    super.changeState(AUTH_STATES.ConfirmSignUp);
    super.error(error);
  };

  submitForm = async (event: any) => {
    event.preventDefault();

    const { formState } = this.state as any;

    if (formState.isValid) {
      super.changeState(AUTH_STATES.Loading);
      return this.confirm();
    }

    this.setStateAsync({
      formState: formStateTouchAll(formState, schema),
    });
  };

  showComponent = () => {
    const { classes } = this.props as any;

    return (
      <Layout
      // features={[
      //   { icon: 'outlined', text: 'Credit range from 10K to 2.5M' },
      //   { icon: 'flash_on', text: 'Instant credit rating' },
      //   { icon: 'access_time', text: 'Financing within 48 hours' },
      // ]}
      // onSubmit={this.submitForm}
      >
        <Grid item>
          <TextField
            autoComplete="off"
            error={this.hasError('code')}
            fullWidth
            helperText={this.getError('code')}
            label="Code"
            name="code"
            onChange={this.handleInputChange}
            required
            type="text"
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.submitButton}
            color="primary"
            onClick={this.submitForm}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          <FormHelperText className={classes.helperText}>
            Did not receive the code? {/* eslint-disable-next-line */}
            <Link onClick={this.resend}>Resend code</Link>
          </FormHelperText>
          <FormHelperText className={classes.helperText}>
            Already activated your account? {/* eslint-disable-next-line */}
            <Link onClick={() => this.changeState(AUTH_STATES.SignIn)}>
              Back to sign in
            </Link>
          </FormHelperText>
        </Grid>
      </Layout>
    );
  };
}

// @ts-ignore
export default withStyles(styles as any)(ConfirmSignUp);
