import { DataSource } from 'advanext-models/nectar/report';
import { apiService } from 'Services';
import { DataSourceDocumentPath } from 'Constants';

export const updateDataSourceDocumentApi = async (
  dataSource: keyof typeof DataSource,
  companyId: string,
  compoundTenant: string,
  at?: string,
): Promise<Blob | undefined> => {
  try {
    const url = DataSourceDocumentPath.replace(
      /:companyId/g,
      encodeURIComponent(companyId),
    );
    const { data }: { data: Blob } = await apiService.get(url, {
      params: { at, dataSource, compoundTenant },
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
    });
    return data;
  } catch (e) {
    // TODO: Cover this case
  }
};

export const getDataSourceDocumentApi = async (
  dataSource: keyof typeof DataSource,
  companyId: string,
  compoundTenant: string,
  at?: string,
): Promise<Blob | undefined> => {
  try {
    const url = DataSourceDocumentPath.replace(
      /:companyId/g,
      encodeURIComponent(companyId),
    );
    const { data }: { data: Blob } = await apiService.get(url, {
      params: { at, dataSource, compoundTenant },
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
    });
    return data;
  } catch (e) {
    // TODO: Cover this case
  }
};
