import { User } from 'advanext-models/nectar';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { plainToClass } from 'class-transformer';
import * as R from 'ramda';

import {
  MonitoringChanges,
  GetMonitoringChangesResult,
  GetMonitoringChangeHistoryResponse,
} from 'advanext-models/nectar/monitoring';

import {
  CompanyPosition,
  // CompanyPosition,
  ExpandedGetMonitoringChangeHistoryResponse,
} from 'Types';

import {
  CompaniesInfoPath,
  GetMonitoringChangesPath,
  ModifyMonitoringChangesPath,
  MonitoringChangesHistoryPath,
  TenantMembersPath,
} from 'Constants';

import { apiService } from 'Services';

export const getMonitoredChangesApi = async (
  compoundCompanyId?: CompoundCompanyId,
  compoundTenantId?: CompoundTenantId,
  limit?: number,
  status?: string,
  lastEvaluatedKey?: any, // eslint-disable-line
): Promise<GetMonitoringChangesResult> => {
  const { data } = await apiService.get<GetMonitoringChangesResult>(
    GetMonitoringChangesPath,
    {
      params: {
        limit,
        status,
        compoundCompanyId: compoundCompanyId?.toString(),
        compoundTenantId: compoundTenantId?.toString(),
        lastEvaluatedKey: lastEvaluatedKey
          ? encodeURIComponent(JSON.stringify(lastEvaluatedKey))
          : undefined,
      },
    },
  );

  const companiesIds = R.uniq(
    data.changes.map((el: MonitoringChanges) =>
      el.compoundCompanyId.toString(),
    ),
  ).join(',');

  const { data: companyInfo } = await apiService.get(CompaniesInfoPath, {
    params: {
      compoundTenantId: compoundTenantId?.toString(),
      ids: companiesIds,
    },
  });

  return {
    ...data,
    changes: data.changes.map((item) =>
      plainToClass(MonitoringChanges, {
        ...item,
        companyName: companyInfo.find(
          (info: CompanyPosition) =>
            info.id === item.compoundCompanyId.toString(),
        ).name,
      }),
    ),
  };
};

export const updateMonitoredChangeApi = async (
  id: string,
  updates: Partial<MonitoringChanges>,
): Promise<void> => {
  await apiService.patch(ModifyMonitoringChangesPath.replace(':id', id), {
    data: updates,
  });
};

export const getMonitoredChangeUpdatesHistoryApi = async (
  tenantId: string,
  id: string,
): Promise<ExpandedGetMonitoringChangeHistoryResponse> => {
  const { data } = await apiService.get<GetMonitoringChangeHistoryResponse>(
    MonitoringChangesHistoryPath.replace(':id', id),
  );

  const uniqueModifiers = data.changes
    .map((change) => change.modifiedBy as string)
    .filter(
      (value: string, index: number, self: string[]) =>
        value && self.indexOf(value) === index,
    );

  if (uniqueModifiers.length) {
    const { data: members = [] } = await apiService.get<User[]>(
      TenantMembersPath.replace(/:id/g, encodeURIComponent(tenantId)),
      {
        params: { id: uniqueModifiers.join(',') },
      },
    );

    const formattedChanges = data.changes.map((el: MonitoringChanges) => {
      const user =
        el.modifiedBy &&
        members.find((member: User) => member.id === el.modifiedBy);
      return {
        ...el,
        modifiedByUser: user ? `${user.firstName} ${user.lastName}` : '',
      };
    });

    return { ...data, changes: formattedChanges };
  }

  return data;
};
