import React, { useState, useContext, useEffect } from 'react';
import DayjsUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { ErrorAlert, LoadingAlert, StyledTabs } from 'advanext-components';
import { EntityType } from 'advanext-models/nectar/entity';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { Folder } from 'advanext-models/advanext/document';
import { FinancialStatement } from 'advanext-models/nectar/financialstatement';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  AppBar,
  Tab,
  Grid,
  Button,
} from '@material-ui/core';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  useCompany,
  usePolicy,
  useCrifCollections,
  useMonitoredCompanies,
  useUpdateMonitoredCompanies,
  useUWTasks,
  useEntities,
} from 'Hooks';

import {
  formatMoney,
  getCountry,
  getCompanyActiveTab,
  getCompanyFullAddressFormatted,
  getValueOrNA,
  formatPercentage,
  getYesNoValueExtended,
  getValueOrNAExtended,
  formatDate,
} from 'Utilities';

import { IsAdmin } from 'Policies';
import { COMPANY_ROUTES } from 'Constants';
import { TenantProviderContext } from 'Views/providers/Tenant';
import Overview from './Overview';
import QualitativeDD from './QualitativeDD';
import FinancialStatements from './FinancialStatements';
import BankStatements from './BankStatements';
import CompanyDD from './CompanyDD';
import Documents from './Documents';
import { Monitoring } from './Monitoring';
import UnderwritingTasks from './UnderwritingTasks';

import {
  DataSources,
  CreditReportTable,
  TasksStatusIcon,
  DocumentSelectionModal,
} from './components';
import { DataSource, DataSourcesMetadata } from 'advanext-models/nectar/report';
import { plainToClass } from 'class-transformer';

const useStyles = makeStyles({
  monitorWrapper: {
    backgroundColor: '#2B98F0',
  },
  historicViewBarText: {
    color: 'white',
  },
  title: {
    marginBottom: '20px',
  },
  historicView: {
    margin: '20px',
    alignItems: 'baseline',
  },
  checkboxWrapper: {
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '12px',
    marginTop: -12,
    marginLeft: -12,
  },
  completedIcon: {
    color: '#',
  },
});

const navItems = [
  {
    id: 0,
    to: COMPANY_ROUTES.Overview,
    label: 'Overview',
  },
  {
    id: 1,
    to: COMPANY_ROUTES.Reports,
    label: 'Credit & Collection Reports',
  },
  {
    id: 2,
    to: COMPANY_ROUTES.DueDiligence,
    label: 'Qualitative DD',
  },
  {
    id: 3,
    to: COMPANY_ROUTES.FinancialStatements,
    label: 'Financial Statements',
  },
  {
    id: 4,
    to: COMPANY_ROUTES.BankStatements,
    label: 'Bank Statements',
  },
  {
    id: 5,
    to: COMPANY_ROUTES.CompanyDueDiligence,
    label: 'Company DD',
  },
  {
    id: 6,
    to: COMPANY_ROUTES.Monitoring,
    label: 'Monitoring',
  },
  {
    id: 7,
    to: COMPANY_ROUTES.Documents,
    label: 'Documents',
  },
];

const Company = (): JSX.Element => {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  const { companyId } = useParams<{ companyId: string }>();
  const [dpValue, setDPValue] = useState<Dayjs | null>(null);
  const { compoundTenantId } = useContext(TenantProviderContext);
  const compoundCompanyId = CompoundCompanyId.fromValue(companyId);

  const [isMonitored, setIsMonitored] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);

  const { entities: fsEntities, loading: fsLoading } =
    useEntities<FinancialStatement>(compoundTenantId, companyId, {
      entityType: EntityType.FINANCIAL_STATEMENT,
      fetchAll: true,
    });

  const {
    data: company,
    isLoading: isCompanyLoading,
    error,
    revalidate,
  } = useCompany(companyId, dpValue || undefined);

  const { data: crifCollections, order } = useCrifCollections(companyId);

  const dataSourcesMetadata = company?.dataSourcesMetadata
    ? {
        ...company.dataSourcesMetadata,
        ...(crifCollections[0]
          ? {
              [DataSource.CRIF_COLLECTION]: plainToClass(DataSourcesMetadata, {
                link: crifCollections[0].collectionDocumentId,
                created: crifCollections[0].ordered,
              }),
            }
          : undefined),
      }
    : undefined;

  const [isAdmin] = usePolicy([IsAdmin]);

  const { data: uwTasks = [], isCompleted } = useUWTasks(companyId, {
    revalidateOnFocus: false,
  });

  const { data: monitoredCompanies, isLoading: isMonitoredCompaniesLoading } =
    useMonitoredCompanies();

  const { updateMonitoredCompanies, isMonitoredCompaniesUpdating } =
    useUpdateMonitoredCompanies(companyId, compoundTenantId, isMonitored);

  useEffect(() => {
    setIsMonitored(
      !!monitoredCompanies?.some((id) => id.toString() === companyId),
    );
  }, [companyId, monitoredCompanies]);

  return (
    <>
      <DocumentSelectionModal
        open={isDocumentModalOpen}
        compoundTenantId={compoundTenantId}
        compoundCompanyId={compoundCompanyId}
        startPath={Folder.CollectionReportRequestForms}
        onClose={(): void => setDocumentModalOpen(false)}
        onSelect={async (fileId: string): Promise<void> => {
          setDocumentModalOpen(false);
          await order(fileId);
        }}
      />

      {isCompanyLoading ? (
        <LoadingAlert text="Company data is loading, please wait" />
      ) : error ? (
        <ErrorAlert text="Failed to load company data" />
      ) : (
        <>
          {dpValue && (
            <Grid className={classes.monitorWrapper}>
              <Typography
                align="center"
                className={classes.historicViewBarText}
              >
                {`Historical view from ${dayjs(dpValue).format('DD-MM-YYYY')}`}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <div>
              <Box mb={0} display="flex" alignItems="center">
                {!!uwTasks.length && (
                  <Box
                    mr={0.5}
                    mb={-0.5}
                    onClick={(): void => setIsTaskModalOpen(true)}
                  >
                    <TasksStatusIcon
                      isCompleted={isCompleted}
                      isMonitored={isMonitored}
                      size="large"
                    />
                  </Box>
                )}
                <Typography variant="h4">
                  {company?.companyData?.name?.consolidated}
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                {company?.address?.consolidated
                  ? getCompanyFullAddressFormatted(
                      company?.address?.consolidated,
                    )
                  : 'No address'}
              </Typography>
            </div>
            <DataSources
              companyId={companyId}
              companyName={company?.companyData?.name.consolidated}
              country={company?.companyData?.country}
              data={dataSourcesMetadata}
              disabledReorder={!!dpValue}
              at={dpValue?.format('YYYY-MM-DD')}
              compoundTenant={compoundTenantId.toString()}
              onOrderCrifCollection={(): void => setDocumentModalOpen(true)}
            />
          </Grid>

          <Grid container direction="row" className={classes.historicView}>
            <div className={classes.checkboxWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMonitored}
                    onChange={updateMonitoredCompanies}
                    style={{
                      color:
                        !isAdmin ||
                        isMonitoredCompaniesLoading ||
                        isMonitoredCompaniesUpdating
                          ? '#ccc'
                          : '#262B32',
                    }}
                    disabled={
                      !isAdmin ||
                      isMonitoredCompaniesLoading ||
                      isMonitoredCompaniesUpdating
                    }
                  />
                }
                label="Monitor company"
              />
              {(isMonitoredCompaniesLoading ||
                isMonitoredCompaniesUpdating) && (
                <CircularProgress size={20} className={classes.spinner} />
              )}
            </div>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <KeyboardDatePicker
                label={<strong>Historical View</strong>}
                format="DD-MM-YYYY"
                value={dpValue}
                onChange={(date): void => {
                  if (date?.isValid()) {
                    setDPValue(date);
                  }
                }}
                maxDate={new Date()}
              />
            </MuiPickersUtilsProvider>
            {dpValue && (
              <Button
                variant="contained"
                onClick={(): void => setDPValue(null)}
              >
                Reset
              </Button>
            )}
          </Grid>

          <AppBar position="static" elevation={0}>
            <StyledTabs
              value={getCompanyActiveTab(pathname)}
              onChange={(): void => undefined}
              variant="scrollable"
            >
              {navItems.map(({ id, label, to }) => (
                <Tab
                  key={id}
                  label={label}
                  component={NavLink}
                  to={`${url}${to}`}
                  disabled={
                    (to === COMPANY_ROUTES.DueDiligence ||
                      to === COMPANY_ROUTES.Monitoring ||
                      to === COMPANY_ROUTES.Documents) &&
                    !!dpValue
                  }
                />
              ))}
            </StyledTabs>
          </AppBar>
          <Box mb={3} />
          <Switch>
            <Redirect
              exact
              path={path}
              to={`${path}${COMPANY_ROUTES.Overview}`}
            />
            <Route exact path={`${path}${COMPANY_ROUTES.Overview}`}>
              <Overview companyReport={company} companyId={companyId} />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.Reports}`}>
              {company?.creditReport &&
                getCountry(company?.companyData?.country).isCH && (
                  <>
                    <CreditReportTable
                      title="Bisnode report"
                      entries={[
                        {
                          property: 'D&B Score',
                          value: getValueOrNA(
                            company.creditReport.score?.BISNODE,
                          ),
                          update: dataSourcesMetadata?.BISNODE?.created,
                        },
                        {
                          property: 'D&B Rating',
                          value: getValueOrNA(
                            company.creditReport.rating?.BISNODE,
                          ),
                          update: dataSourcesMetadata?.BISNODE?.created,
                        },
                        {
                          property: 'D&B Paydex Score',
                          value: getValueOrNA(
                            company.creditReport.repaymentIndex?.BISNODE,
                          ),
                          update: dataSourcesMetadata?.BISNODE?.created,
                        },
                        {
                          property: 'D&B Credit Limit',
                          value: formatMoney(
                            company.creditReport.creditRecommendation?.BISNODE
                              ?.amount,
                          ),
                          update: dataSourcesMetadata?.BISNODE?.created,
                        },
                      ]}
                    />
                    <CreditReportTable
                      title="Crif report"
                      entries={[
                        {
                          property: 'Crif Score',
                          value: getValueOrNA(company.creditReport.score?.CRIF),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                        {
                          property: 'Crif Rating',
                          value: getValueOrNA(
                            company.creditReport.rating?.CRIF,
                          ),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                        {
                          property: 'Crif Belastet',
                          value: getYesNoValueExtended(
                            company.creditReport.belastet?.CRIF,
                          )(
                            <span>Belastet</span>,
                            <span>
                              Not belastet
                              <Check style={{ verticalAlign: 'middle' }} />
                            </span>,
                          ),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                        {
                          property: 'Crif Credit Limit',
                          value: formatMoney(
                            company.creditReport.creditRecommendation?.CRIF
                              ?.amount,
                          ),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                        {
                          property: 'Payment delay',
                          value: getValueOrNAExtended(
                            company.creditReport.paymentDelay?.CRIF,
                          )({
                            NOT_AVAILABLE: <span>Not Available</span>,
                            WITH_DELAY: <span>With delay</span>,
                            OK: (
                              <span>
                                Ok
                                <Check style={{ verticalAlign: 'middle' }} />
                              </span>
                            ),
                          }),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                        {
                          property: 'Fluctuation rate',
                          value: formatPercentage(
                            company.countrySpecific?.crifSpecific
                              ?.fluctuationRate,
                          ),
                          update: dataSourcesMetadata?.CRIF?.created,
                        },
                      ]}
                    />
                    <CreditReportTable
                      title="Collection report"
                      entries={[
                        {
                          property: 'Total amount of claims',
                          value: formatMoney(
                            company.creditReport?.claims?.consolidated
                              ?.sumOfClaims?.amount,
                          ),
                          update: dataSourcesMetadata?.CRIF_COLLECTION?.created,
                        },
                        {
                          property: 'Open amount of claims',
                          value: formatMoney(
                            company.creditReport?.claims?.consolidated
                              ?.sumOfOpenAmount?.amount,
                          ),
                          update: dataSourcesMetadata?.CRIF_COLLECTION?.created,
                        },
                        {
                          property: 'Latest claim',
                          value: formatDate(
                            company.creditReport?.claims?.consolidated
                              ?.latestClaim?.dateOpen,
                          ),
                          update: dataSourcesMetadata?.CRIF_COLLECTION?.created,
                        },
                        {
                          property: 'Number of claims',
                          value: getValueOrNA(
                            company.creditReport?.claims?.consolidated
                              ?.claimEntries?.length,
                          ),
                          update: dataSourcesMetadata?.CRIF_COLLECTION?.created,
                        },
                      ]}
                    />
                  </>
                )}
              {company?.creditReport &&
                getCountry(company?.companyData?.country).isDE && (
                  <>
                    <CreditReportTable
                      title="Schufa report"
                      entries={[
                        {
                          property: 'SCHUFA Score',
                          value: getValueOrNA(
                            company.creditReport.score?.SCHUFA,
                          ),
                          update: dataSourcesMetadata?.SCHUFA?.created,
                        },
                        {
                          property: 'SCHUFA Rating',
                          value: getValueOrNA(
                            company.creditReport.rating?.SCHUFA,
                          ),
                          update: dataSourcesMetadata?.SCHUFA?.created,
                        },
                        {
                          property: 'SCHUFA Credit Limit',
                          value: formatMoney(
                            company.creditReport.creditRecommendation?.SCHUFA
                              ?.amount,
                          ),
                          update: dataSourcesMetadata?.SCHUFA?.created,
                        },
                      ]}
                    />
                    <CreditReportTable
                      title="Creditsafe report"
                      entries={[
                        {
                          property: 'CreditSafe Score',
                          value: getValueOrNA(
                            company?.creditReport.score?.CREDITSAFE,
                          ),
                          update: dataSourcesMetadata?.CREDITSAFE?.created,
                        },
                        {
                          property: 'CreditSafe Rating',
                          value: getValueOrNA(
                            company?.creditReport.rating?.CREDITSAFE,
                          ),
                          update: dataSourcesMetadata?.CREDITSAFE?.created,
                        },
                        {
                          property: 'CreditSafe Credit Limit',
                          value: formatMoney(
                            company?.creditReport.creditRecommendation
                              ?.CREDITSAFE?.amount,
                          ),
                          update: dataSourcesMetadata?.CREDITSAFE?.created,
                        },
                        {
                          property: 'Creditreform Payment Behaviour',
                          value: getValueOrNA(undefined),
                          update:
                            dataSourcesMetadata
                              ?.CREDITREFORM_INTERNATIONAL_COMPLETE?.created,
                        },
                        {
                          property: 'Creditreform Score',
                          value: getValueOrNA(
                            company?.creditReport.score?.CREDITREFORM,
                          ),
                          update:
                            dataSourcesMetadata
                              ?.CREDITREFORM_INTERNATIONAL_COMPLETE?.created,
                        },
                        {
                          property: 'Creditreform Rating',
                          value: getValueOrNA(
                            company?.creditReport.rating?.CREDITREFORM,
                          ),
                          update:
                            dataSourcesMetadata
                              ?.CREDITREFORM_INTERNATIONAL_COMPLETE?.created,
                        },
                        {
                          property: 'Creditreform Credit Limit',
                          value: formatMoney(
                            company?.creditReport.creditRecommendation
                              ?.CREDITREFORM?.amount,
                          ),
                          update:
                            dataSourcesMetadata
                              ?.CREDITREFORM_INTERNATIONAL_COMPLETE?.created,
                        },
                      ]}
                    />
                  </>
                )}
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.DueDiligence}`}>
              <QualitativeDD company={company} companyId={companyId} />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.FinancialStatements}`}>
              <FinancialStatements
                entities={fsEntities}
                loading={fsLoading}
                onRefresh={async (): Promise<void> => {
                  await revalidate();
                }}
                financialStatementScores={company?.financialStatementScores}
                financialStatements={company?.financialStatements}
              />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.BankStatements}`}>
              <BankStatements
                compoundTenantId={compoundTenantId}
                companyId={companyId}
              />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.CompanyDueDiligence}`}>
              <CompanyDD companyId={companyId} />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.Monitoring}`}>
              <Monitoring companyId={companyId} />
            </Route>
            <Route exact path={`${path}${COMPANY_ROUTES.Documents}`}>
              <Documents
                compoundCompanyId={compoundCompanyId}
                compoundTenantId={compoundTenantId}
              />
            </Route>
          </Switch>
          <UnderwritingTasks
            isOpen={isTaskModalOpen}
            onClose={(): void => setIsTaskModalOpen(false)}
            isMonitored={isMonitored}
            companyId={companyId}
          />
        </>
      )}
    </>
  );
};

export default Company;
