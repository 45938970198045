export const AUTH_STATES = {
  ConfirmSignIn: 'confirmSignIn',
  ConfirmSignUp: 'confirmSignUp',
  ForgotPassword: 'forgotPassword',
  Loading: 'loading',
  RequireNewPassword: 'requireNewPassword',
  SignedOut: 'signedOut',
  SignedIn: 'signedIn',
  SignedUp: 'signedUp',
  SignIn: 'signIn',
  SignOut: 'signOut',
  SignUp: 'signUp',
  VerifyContact: 'verifyContact',
};

export default {
  AUTH_STATES,
};
