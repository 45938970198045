export const headCells: Array<{
  id: string;
  label: string;
  style?: { width: string };
}> = [
  {
    id: 'firstName',
    label: 'Name',
  },
  {
    id: 'personType',
    label: 'Type',
  },
  {
    id: 'signatoryRights',
    label: 'Signatory rights',
  },
  {
    id: 'identificationStatus',
    label: 'Identification Status',
  },
  {
    id: 'pepChecks',
    label: 'PEP/Sanction check',
  },
  {
    id: 'pepChecksDate',
    label: 'PEP/Sanction check date',
  },
];
