import React, { useState, useCallback, useEffect } from 'react';

import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import type { Tenant } from 'advanext-models/nectar/tenant';

import {
  FormState,
  INITIAL_FORM_STATE,
  isValid,
  formStateApplyEvent,
  hasError as formHasError,
  getError as formGetError,
} from 'Services/forms';
import { FormEvent } from 'Types';

const schema = {
  tenantId: { required: true, tenantId: true },
  name: { required: true },
};

type Props = {
  tenant?: Tenant;
  onChange: (formState: FormState) => void;
};

const TenantForm = ({ tenant, onChange }: Props): JSX.Element => {
  const [formState, setFormState] = useState<FormState>({
    ...INITIAL_FORM_STATE,
    values: tenant ? { ...tenant } : ({} as Tenant),
    isValid: isValid({ values: tenant || {} } as FormState, schema),
  });

  const handleInputChange = useCallback(
    (event: FormEvent) => {
      setFormState(formStateApplyEvent(event, formState, schema));
    },
    [formState],
  );

  const hasError = useCallback(
    (field: string) => formHasError(field, formState),
    [formState],
  );
  const getError = useCallback(
    (field: string) => formGetError(field, formState),
    [formState],
  );

  useEffect(() => {
    onChange(formState as FormState);
  }, [formState, onChange]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Tenant Identifier</TableCell>
          <TableCell>
            <TextField
              error={hasError('tenantId')}
              fullWidth
              helperText={getError('tenantId')}
              label="Tenant Identifier"
              margin="dense"
              name="tenantId"
              onChange={handleInputChange}
              required
              value={formState.values.tenantId || ''}
              disabled={!!tenant}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Descriptive Name</TableCell>
          <TableCell>
            <TextField
              error={hasError('name')}
              fullWidth
              helperText={getError('name')}
              label="Descriptive Name"
              margin="dense"
              name="name"
              onChange={handleInputChange}
              required
              value={formState.values.name || ''}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TenantForm;
