import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { StyledCard } from 'advanext-components';
import { Mandate } from 'advanext-models/nectar/management';

import { useCompanyMandates } from 'Hooks';
import { Order, SortOrder } from 'Types';
import { formatDate, getComparator } from 'Utilities';
import { headCells } from './constants';

type Props = {
  companyId: string;
};

const OtherMandatesTable = ({ companyId }: Props): JSX.Element => {
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<keyof Mandate | undefined>(undefined);

  const handleRequestSort = (property: keyof Mandate): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const { data = {}, isLoading: OtherMandatesTableLoading } =
    useCompanyMandates(companyId);

  const entries = useMemo(() => Object.values(data).flat(), [data]);

  return (
    <StyledCard title="Other Mandates">
      {OtherMandatesTableLoading ? (
        <CircularProgress />
      ) : entries.length === 0 ? (
        <div>No management information available</div>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, label }) => (
                <TableCell
                  component="th"
                  sortDirection={orderBy === id ? order : false}
                  key={id}
                >
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : Order.ASC}
                    onClick={(): void => {
                      handleRequestSort(id);
                    }}
                    IconComponent={ArrowDropDown}
                  >
                    <strong>{label}</strong>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries
              .sort(
                orderBy
                  ? getComparator<Mandate>(orderBy, order)
                  : (): SortOrder => 0,
              )
              .map(
                ({
                  fullName,
                  companyName,
                  startDate,
                  endDate,
                  active,
                  uid,
                }) => (
                  <TableRow
                    key={`${fullName}-${companyName}-${startDate}-${endDate}-${active}`}
                  >
                    <TableCell>{fullName}</TableCell>
                    <TableCell>{companyName}</TableCell>
                    <TableCell>{uid.formatted}</TableCell>
                    <TableCell>{formatDate(startDate)}</TableCell>
                    <TableCell>{formatDate(endDate)}</TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      )}
    </StyledCard>
  );
};

export default OtherMandatesTable;
