export const headCells: Array<{
  id: string;
  label: string;
  style?: { width: string };
  isSortable: boolean;
}> = [
  {
    id: 'created',
    label: 'Creation Date',
    isSortable: true,
  },
  {
    id: 'rating',
    label: 'Rating',
    isSortable: true,
  },
  {
    id: 'product',
    label: 'Product',
    isSortable: true,
  },
  {
    id: 'author',
    label: 'Author',
    isSortable: true,
  },
  {
    id: 'creditLine',
    label: 'Credit Line',
    isSortable: true,
  },
  {
    id: 'id',
    label: 'Actions',
    style: { width: '7%' },
    isSortable: false,
  },
];
