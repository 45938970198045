import React from 'react';
import { PageTitle } from 'advanext-components';

import Layout from 'Views/providers/Auth/Layout';
import { useSelectors } from 'Hooks';
import {
  user as userSelector,
  loading as userIsLoading,
} from 'Redux/models/Auth';

const NotFound = (): JSX.Element => {
  const [user, isLoading] = useSelectors([userSelector, userIsLoading]);
  if (isLoading) return <div>Loading...</div>;

  return user ? (
    <>
      <PageTitle title="Not Found" />
      The page you are looking for could not be found.{' '}
    </>
  ) : (
    <Layout>
      <PageTitle title="Not Found" />
      The page you are looking for could not be found.
    </Layout>
  );
};

export default NotFound;
