import useSWR from 'swr';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Country } from 'advanext-models/advanext';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { ResultResponse } from 'advanext-models/nectar/astrea';
import { user as userSelector } from 'Redux/models/Auth';

import { ScoreResultPath } from 'Constants';
import { TenantProviderContext } from 'Views/providers/Tenant';
import { fetcher } from 'Api';
import { ApiEntity } from 'Types';

export const useScoreResult = (
  type: string,
  compoundCompanyId?: CompoundCompanyId,
  country?: Country,
): ApiEntity<ResultResponse> => {
  const user = useSelector(userSelector);
  const { compoundTenantId } = useContext(TenantProviderContext);

  if (!user?.tenant) {
    throw new Error('tenant of user must be defined');
  }
  const stringified = JSON.stringify({
    type,
    country,
    compoundCompanyId: compoundCompanyId?.toString(),
    compoundTenantId: compoundTenantId.toString(),
  });
  const { data, error, isValidating } = useSWR(
    [ScoreResultPath, stringified],
    fetcher,
    { revalidateOnFocus: false },
  );

  return {
    data,
    isLoading: (data === undefined && !error) || isValidating,
    error,
  } as ApiEntity<ResultResponse>;
};
