import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from 'Constants';
import cn from 'classnames';
import {
  Table,
  IconButton,
  TableCell,
  TableRow,
  Chip,
  CircularProgress,
  Link,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, Save, Cancel } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  ErrorAlert,
  LoadingAlert,
  StyledInput,
  StyledSelect,
} from 'advanext-components';
import { MonitoringChangeTypeConst } from 'Constants';
import {
  useUpdateMonitoredChange,
  useMonitoredChangeUpdateHistory,
} from 'Hooks';
import {
  getCompanyFullAddressFormatted,
  monitoringStatusesOptions,
  getMonitoringStatus,
  getMonitoringChangeType,
  formatDate,
  formatDateTime,
  EMPTY_STRING_DISPLAY_VALUE,
} from 'Utilities';
import type {
  OptionType,
  ExpandedGetMonitoringChangeHistoryResponse,
} from 'Types';
import {
  MonitoringChanges,
  MonitoringChangesStatus,
} from 'advanext-models/nectar/monitoring';
import { Address } from 'advanext-models/advanext';
import changesColumns from './changes.json';
import updatesColumns from './updates.json';

const getChangeDetails = (
  change: Record<string, unknown>,
): Array<{ source: string; newValue: string; oldValue: string }> => {
  switch (change?.type) {
    case MonitoringChangeTypeConst.NectarRating:
      return [
        {
          source: MonitoringChangeTypeConst.NectarRating,
          newValue: change?.newValue as string,
          oldValue: (change?.oldValue as string) ?? EMPTY_STRING_DISPLAY_VALUE,
        },
      ];
    case MonitoringChangeTypeConst.Name:
    case MonitoringChangeTypeConst.CreditReportRating:
      return Object.keys(change?.newValue as Record<string, string>).map(
        (key) => ({
          source: key,
          newValue: (
            change as {
              type: string;
              newValue: Record<string, string>;
              oldValue: Record<string, string>;
            }
          ).newValue[key],
          oldValue:
            (
              change as {
                type: string;
                newValue: Record<string, string>;
                oldValue: Record<string, string>;
              }
            )?.oldValue[key] ?? EMPTY_STRING_DISPLAY_VALUE,
        }),
      );
    case MonitoringChangeTypeConst.Address:
      return Object.keys(change?.newValue as Record<string, string>).map(
        (key) => ({
          source: key,
          newValue: getCompanyFullAddressFormatted(
            (
              change as {
                type: string;
                newValue: Record<string, Address>;
                oldValue: Record<string, Address>;
              }
            ).newValue[key],
          ),
          oldValue: (
            change as {
              type: string;
              newValue: Record<string, Address>;
              oldValue: Record<string, Address>;
            }
          ).oldValue[key]
            ? getCompanyFullAddressFormatted(
                (
                  change as {
                    type: string;
                    newValue: Record<string, Address>;
                    oldValue: Record<string, Address>;
                  }
                ).oldValue[key],
              )
            : EMPTY_STRING_DISPLAY_VALUE,
        }),
      );
    case MonitoringChangeTypeConst.Management:
      return [
        {
          source: '',
          newValue: 'Review Management Information',
          oldValue: EMPTY_STRING_DISPLAY_VALUE,
        },
      ];
    default:
      return [{ source: '', newValue: '', oldValue: '' }];
  }
};

const useStyles = makeStyles({
  tdWrap: {
    whiteSpace: 'break-spaces',
    overflow: 'visible',
    overflowWrap: 'break-word',
  },
  tr: {
    display: 'table',
    width: '100%',
  },
  changesRow: {
    background: '#f2f2f2',
  },
  noBorder: {
    border: 'none',
  },
  eventStatus: {
    color: '#000000',
    minWidth: '100px',
  },
  unprocessedStatus: {
    backgroundColor: '#f9d7a1',
    border: '1px solid #f29f3e',
  },
  processedStatus: {
    backgroundColor: '#bfddbc',
    border: '1px solid #67ac5c',
  },
  progressStatus: {
    backgroundColor: '#afd4f6',
    border: '1px solid #4696ec',
  },
});

type Props = {
  row: MonitoringChanges & { companyName?: string };
  getChanges: () => void;
  isCompanyNameShown: boolean;
};

const Row = ({ row, getChanges, isCompanyNameShown }: Props): JSX.Element => {
  const initialStatusOption = useMemo(
    () =>
      monitoringStatusesOptions.find(({ value }) => value === row.status) ??
      monitoringStatusesOptions[0],
    [row.status],
  );

  const { push } = useHistory();

  const onOpenCompany = useCallback(
    (id) => push(ROUTES.Company.replace(':companyId', id)),
    [push],
  );

  const [open, setOpen] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [status, setStatus] = useState<OptionType>(initialStatusOption);
  const [comment, setComment] = useState(row.comment);
  const [updatesHistory, setUpdatesHistory] =
    useState<ExpandedGetMonitoringChangeHistoryResponse>();

  const classes = useStyles();

  const { updateMonitoredChange, isUpdating } = useUpdateMonitoredChange({
    monitoringChangesId: idToUpdate,
    status: status.value as MonitoringChangesStatus,
    comment,
  });

  const handleSelectStatus = useCallback(
    (statusOption) => setStatus(statusOption),
    [setStatus],
  );

  const handleInputChange = useCallback(
    (event) => setComment(event.target.value),
    [setComment],
  );
  const handleCloseEdit = useCallback(() => {
    setEditMode(false);
    setStatus(status);
    setComment(comment);
    setIdToUpdate('');
  }, [status, comment, setEditMode, setStatus, setComment]);

  const handleSave = useCallback(() => {
    updateMonitoredChange();
    setTimeout(() => getChanges(), 2500);
    handleCloseEdit();
  }, [handleCloseEdit, updateMonitoredChange, getChanges]);

  const {
    getUpdatesHistory,
    result: updates,
    error: updatesHistoryError,
    isLoading: updatesHistoryIsLoading,
  } = useMonitoredChangeUpdateHistory();

  useEffect(() => {
    if (updates) {
      setUpdatesHistory(updates);
    }
  }, [updates]);

  const handleOpen = useCallback(
    (id: string) => {
      if (!open) {
        getUpdatesHistory(id);
      }
      if (open) {
        setUpdatesHistory(undefined);
      }
      setOpen(!open);
    },
    [open, setUpdatesHistory, getUpdatesHistory],
  );

  const renderDetails = (change: Record<string, unknown>): JSX.Element => {
    const rowDetails = getChangeDetails(change);
    return (
      <Table>
        <TableBody>
          {rowDetails.map((detail) => (
            <TableRow key={detail?.source}>
              <TableCell style={{ width: isCompanyNameShown ? '30%' : '23%' }}>
                {detail?.source}
              </TableCell>
              <TableCell style={{ width: isCompanyNameShown ? '30%' : '35%' }}>
                {detail?.newValue}
              </TableCell>
              <TableCell
                style={{
                  width: '40%',
                  textDecoration: 'line-through',
                }}
              >
                {detail?.oldValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(): void =>
              handleOpen(row.monitoringChangesId as unknown as string)
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatDate(row.created)}</TableCell>
        {isCompanyNameShown && (
          <TableCell>
            <Link
              underline="always"
              onClick={(): void => onOpenCompany(row?.compoundCompanyId)}
            >
              {row?.companyName}
            </Link>
          </TableCell>
        )}
        <TableCell className={classes.tdWrap}>
          {row.changes
            .map((change) => getMonitoringChangeType(change.type as string))
            .join(', ')}
        </TableCell>
        {isUpdating ? (
          <TableCell>
            <CircularProgress size={20} />
          </TableCell>
        ) : (
          <>
            <TableCell>
              <StyledSelect
                value={status}
                options={monitoringStatusesOptions}
                onChange={handleSelectStatus}
                className="table"
                disabled={!isEditMode}
                disabledComponent={
                  <Chip
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={getMonitoringStatus(status.value)}
                    className={cn(classes.eventStatus, {
                      [classes.unprocessedStatus]:
                        status.value === MonitoringChangesStatus.UNPROCESSED,
                      [classes.processedStatus]:
                        status.value === MonitoringChangesStatus.PROCESSED,
                      [classes.progressStatus]:
                        status.value === MonitoringChangesStatus.PROGRESS,
                    })}
                  />
                }
              />
            </TableCell>
            <TableCell className={classes.tdWrap} title={comment}>
              <StyledInput
                value={comment}
                onChange={handleInputChange}
                disabled={!isEditMode}
                className="table"
                disabledComponent={<span>{comment}</span>}
              />
            </TableCell>
            <TableCell align="center">
              {isEditMode ? (
                <>
                  <IconButton onClick={handleSave} size="small" color="inherit">
                    <Save />
                  </IconButton>
                  <IconButton
                    onClick={handleCloseEdit}
                    size="small"
                    color="inherit"
                  >
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  onClick={(): void => {
                    setEditMode(true);
                    setIdToUpdate(row.monitoringChangesId);
                  }}
                  size="small"
                  color="inherit"
                >
                  <Edit />
                </IconButton>
              )}
            </TableCell>
          </>
        )}
      </TableRow>
      {open && (
        <>
          <TableRow className={classes.changesRow}>
            <TableCell className={classes.noBorder} />
            <TableCell
              colSpan={isCompanyNameShown ? 6 : 5}
              className={classes.noBorder}
            >
              <h3>Changes</h3>
            </TableCell>
          </TableRow>
          <TableRow className={classes.changesRow}>
            <TableCell className={classes.noBorder} />
            {changesColumns.map(({ id, label }) => (
              <TableCell
                key={id}
                colSpan={
                  (id === 'newValue' || id === 'oldValue') && isCompanyNameShown
                    ? 2
                    : 1
                }
              >
                <strong>{label}</strong>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
          {row.changes.map((c: Record<string, unknown>) => (
            <TableRow key={c.type as string} className={classes.changesRow}>
              <TableCell className={classes.noBorder} />
              <TableCell>{getMonitoringChangeType(c.type as string)}</TableCell>
              <TableCell colSpan={isCompanyNameShown ? 5 : 4}>
                {renderDetails(c)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.changesRow}>
            <TableCell className={classes.noBorder} />
            <TableCell
              colSpan={isCompanyNameShown ? 6 : 5}
              className={classes.noBorder}
            >
              <h3>Status Updates</h3>
            </TableCell>
          </TableRow>
          {updatesHistoryIsLoading ? (
            <TableRow className={classes.changesRow}>
              <TableCell className={classes.noBorder} />
              <TableCell colSpan={isCompanyNameShown ? 6 : 5}>
                <LoadingAlert text="Updates history is loading, please wait" />
              </TableCell>
            </TableRow>
          ) : updatesHistoryError ? (
            <TableRow className={classes.changesRow}>
              <TableCell className={classes.noBorder} />
              <TableCell colSpan={isCompanyNameShown ? 6 : 5}>
                <ErrorAlert text="Failed to load updates history" />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow className={classes.changesRow}>
                <TableCell className={classes.noBorder} />
                {updatesColumns.map(({ id, label }) => (
                  <TableCell
                    key={id}
                    colSpan={id === 'comment' && isCompanyNameShown ? 2 : 1}
                  >
                    <strong>{label}</strong>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
              {updatesHistory?.changes
                .sort(
                  (a, b) =>
                    new Date(b.modified).getTime() -
                    new Date(a.modified).getTime(),
                )
                .map((c) => (
                  <TableRow
                    key={c.modified.toString()}
                    className={classes.changesRow}
                  >
                    <TableCell className={classes.noBorder} />
                    <TableCell>{formatDateTime(c.modified)}</TableCell>
                    <TableCell className={classes.tdWrap}>
                      {c.modifiedByUser}
                    </TableCell>

                    <TableCell>
                      {typeof c.status === 'string' && (
                        <Chip
                          size="small"
                          variant="outlined"
                          color="secondary"
                          label={getMonitoringStatus(c.status)}
                          className={cn(classes.eventStatus, {
                            [classes.unprocessedStatus]:
                              c.status === MonitoringChangesStatus.UNPROCESSED,
                            [classes.processedStatus]:
                              c.status === MonitoringChangesStatus.PROCESSED,
                            [classes.progressStatus]:
                              c.status === MonitoringChangesStatus.PROGRESS,
                          })}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={isCompanyNameShown ? 3 : 2}
                      title={c.comment}
                      className={classes.tdWrap}
                    >
                      {c.comment}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Row;
