import React, { ReactElement, useContext } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Theme from 'Config/theme';
import { TenantProviderContext } from 'Views/providers/Tenant';

const useStyles = makeStyles((theme: typeof Theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    background: 'none',
    color: theme.palette.text.secondary,
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
    },
    '&::before': {
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
  },
}));

const ApplicationSelector = (): ReactElement => {
  const classes = useStyles();
  const tenantContext = useContext(TenantProviderContext);

  return (
    <Select
      displayEmpty
      labelId="application-selector-label"
      className={classes.root}
      fullWidth
      value={tenantContext.application}
      onChange={(event): void => {
        tenantContext.selectApplication(event.target.value as string);
      }}
    >
      <MenuItem value="">
        <em>Aggregate View</em>
      </MenuItem>
      {tenantContext.applications.map((application) => (
        <MenuItem
          key={application.tenantApplicationId}
          value={application.tenantApplicationId}
        >
          {application.tenantApplicationId}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ApplicationSelector;
