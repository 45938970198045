import React from 'react';
import cn from 'classnames';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  title: string;
  children: React.ReactNode;
};

const useStyles = makeStyles({
  panel: {
    height: '100%',
  },
  panelHeader: {
    background: 'rgba(190, 190, 190, 0.1)',
    padding: '10px 15px',
    borderBottom: '1px solid #ddd',
    color: '#333',
    fontSize: '14px',
    lineHeight: '25px',
    fontWeight: 'bold',
  },
});

const Panel = ({ title, children }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={cn(classes.panel)}>
      <CardHeader
        disableTypography
        title={title}
        className={classes.panelHeader}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default Panel;
