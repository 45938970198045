import React, { useState } from 'react';
import { mutate } from 'swr';
import { useToasts } from 'react-toast-notifications';
import { Skeleton, Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core';
import type { TenantApplication } from 'advanext-models/nectar/tenant';
import { ErrorAlert } from 'advanext-components';

import { useTenantApplications } from 'Hooks';
import {
  createTenantApplication,
  updateTenantApplication,
  deleteTenantApplication,
} from 'Api';
import { FormState } from 'Services/forms';
import { TenantApplicationForm } from 'Forms';
import { TenantApplicationsList } from 'Tables';
import { TenantApplicationsPath } from 'Constants';
import { ROUTES } from 'Constants';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

type Props = {
  tenantId: string;
  isInAddMode?: boolean;
  className?: string;
};

const TenantApplicationsWidget = ({
  tenantId,
  isInAddMode,
  className = '',
}: Props): JSX.Element => {
  const classes = useStyles();
  const { push } = useHistory();

  const [applicationSubmitting, setApplicationSubmitting] = useState(false);
  const [applicationFormState, setApplicationFormState] = useState<FormState>(
    {} as FormState,
  );
  const [selectedApplication, setSelectedApplication] =
    useState<TenantApplication | null>(null);
  const [showApplicationForm, setShowApplicationForm] = useState(false);

  const {
    data: applications,
    isLoading: areApplicationsLoading,
    error,
  } = useTenantApplications(tenantId);

  const { addToast } = useToasts();

  const submitApplication = async (): Promise<void> => {
    if (!applicationFormState.isValid || !tenantId) return;

    setApplicationSubmitting(true);

    if (!selectedApplication) {
      const app = await createTenantApplication(
        tenantId,
        applicationFormState.values as TenantApplication,
      );
      if (!app) {
        addToast('Failed to create application', { appearance: 'error' });
      }
    } else {
      const app = await updateTenantApplication(
        tenantId,
        applicationFormState.values.tenantApplicationId,
        applicationFormState.values as TenantApplication,
      );
      if (!app) {
        addToast('Failed to update application', { appearance: 'error' });
      }
    }

    setShowApplicationForm(false);
    setSelectedApplication(null);
    setApplicationSubmitting(false);
    mutate(TenantApplicationsPath.replace(/:id/g, tenantId));
  };

  const destroyApplication = async (
    application: TenantApplication,
  ): Promise<void> => {
    if (!tenantId) return;

    setApplicationSubmitting(true);
    const app = await deleteTenantApplication(
      tenantId,
      application.tenantApplicationId,
    );
    if (!app) {
      addToast('Failed to delete application', { appearance: 'error' });
    }
    setApplicationSubmitting(false);
    mutate(TenantApplicationsPath.replace(/:id/g, tenantId));
  };

  const Loading = (): JSX.Element => (
    <Skeleton variant="rect" width="100%" height={270} />
  );

  return (
    <Card className={className}>
      {areApplicationsLoading ? (
        <Loading />
      ) : error ? (
        <ErrorAlert text="Failed to load applications data" />
      ) : (
        <>
          <CardHeader
            title="Applications"
            action={
              <IconButton
                disabled={isInAddMode || applicationSubmitting}
                onClick={(): void => {
                  setShowApplicationForm(!showApplicationForm);
                  setSelectedApplication(null);
                }}
              >
                {showApplicationForm && <Icon>arrow_back</Icon>}
                {!showApplicationForm && <Icon>add</Icon>}
              </IconButton>
            }
          />
          <CardContent>
            {applicationSubmitting && (
              <Alert icon={<CircularProgress size={20} />} severity="info">
                The Application is being submitted, please wait.
              </Alert>
            )}

            {isInAddMode && (
              <Alert severity="info">
                You should first create a tenant to register new applications
              </Alert>
            )}

            {!showApplicationForm && !isInAddMode && (
              <TenantApplicationsList
                applications={applications ?? []}
                onEditApplication={(application: TenantApplication): void => {
                  setSelectedApplication(application);
                  setShowApplicationForm(true);
                }}
                onRemoveApplication={destroyApplication}
                onClickApplication={(app): void =>
                  push(
                    ROUTES.OwnerAccountApplication.replace(
                      ':applicationId',
                      app.tenantApplicationId,
                    ),
                  )
                }
              />
            )}
            {showApplicationForm && (
              <TenantApplicationForm
                application={selectedApplication}
                onChange={setApplicationFormState}
              />
            )}
          </CardContent>
          {showApplicationForm && (
            <CardActions className={classes.cardActions}>
              <Button
                color="primary"
                onClick={submitApplication}
                disabled={
                  !applicationFormState.isValid || applicationSubmitting
                }
              >
                Save
              </Button>
            </CardActions>
          )}
        </>
      )}
    </Card>
  );
};

export default TenantApplicationsWidget;
