import { useState, useContext } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { getMonitoredChangeUpdatesHistoryApi } from 'Api';
import { ExpandedGetMonitoringChangeHistoryResponse } from 'Types';
import { TenantProviderContext } from 'Views/providers/Tenant';

export const useMonitoredChangeUpdateHistory = (): {
  getUpdatesHistory: (id: string) => void;
  result?: ExpandedGetMonitoringChangeHistoryResponse;
  error?: string;
  isLoading: boolean;
} => {
  const { compoundTenantId } = useContext(TenantProviderContext);
  const [error, setError] = useState<string | undefined>();

  const [result, setResult] =
    useState<ExpandedGetMonitoringChangeHistoryResponse>();

  const getUpdatesHistory = useAsyncCallback(async (id) => {
    try {
      const data = await getMonitoredChangeUpdatesHistoryApi(
        compoundTenantId.tenant,
        id,
      );
      setResult(data);
    } catch {
      setError('Failed to load updates history');
    }
  });

  return {
    getUpdatesHistory: getUpdatesHistory.execute,
    result,
    error,
    isLoading: getUpdatesHistory.loading,
  };
};
