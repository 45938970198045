import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useToasts } from 'react-toast-notifications';
import { QueryLevel, DataSource } from 'advanext-models/nectar/report';
import { apiService } from 'Services';
import { CompanyEntryUpdatePath } from 'Constants';

export const useUpdateDataSource = (
  companyId = '',
  dataSource: keyof typeof DataSource,
): {
  updateDataSource: () => void;
  isUpdating: boolean;
  setIsUpdating: (isUpdating: boolean) => void;
  isUpdated: boolean;
  setIsUpdated: (isUpdate: boolean) => void;
} => {
  const { addToast } = useToasts();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const updateDataSource = useAsyncCallback(async () => {
    setIsUpdating(true);

    const url = CompanyEntryUpdatePath.replace(
      /:id/g,
      encodeURIComponent(companyId),
    );
    try {
      await apiService.post(url, {
        data: { dataSources: [dataSource], queryLevel: QueryLevel.PAID },
      });
      setIsUpdated(true);
    } catch {
      addToast('Failed to update data source', {
        appearance: 'error',
      });
    }
    setIsUpdating(false);
  });

  return {
    updateDataSource: updateDataSource.execute,
    isUpdating,
    setIsUpdating,
    isUpdated,
    setIsUpdated,
  };
};
