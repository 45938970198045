import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Skeleton, Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ErrorAlert } from 'advanext-components';
import { ROUTES } from 'Constants';
import { usePolicy, useTenant } from 'Hooks';
import { IsOwner } from 'Policies';
import { createTenant, updateTenant } from 'Api';
import { TenantForm } from 'Forms';
import { FormState } from 'Services/forms';
import { Tenant } from 'advanext-models/nectar/tenant';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

type Props = {
  tenantId: string;
  isInAddMode: boolean;
  className?: string;
};

const TenantCard = ({
  tenantId,
  isInAddMode,
  className = '',
}: Props): JSX.Element => {
  const { push } = useHistory();
  const classes = useStyles();
  const { addToast } = useToasts();

  const [tenantSubmitting, setTenantSubmitting] = useState(false);
  const [tenantFormState, setTenantFormState] = useState<FormState>(
    {} as FormState,
  );

  const [isOwner, isOwnerLoading] = usePolicy([IsOwner]);

  const { data: tenant, isLoading: tenantLoading, error } = useTenant(tenantId);

  if (!isOwnerLoading && !isOwner) {
    return <Redirect to={ROUTES.Dashboard} />;
  }

  const submitTenant = async (): Promise<void> => {
    if (!tenantFormState.isValid) return;

    setTenantSubmitting(true);

    if (isInAddMode) {
      const newTenant = await createTenant(tenantFormState.values as Tenant);
      if (newTenant) {
        push(
          ROUTES.OwnerTenant.replace(':tenantId', newTenant?.tenantId ?? ''),
        );
      } else {
        addToast('Failed to create tenant', { appearance: 'error' });
      }
    } else {
      const tenant = await updateTenant(tenantId, tenantFormState.values);
      if (!tenant) {
        addToast('Failed to update tenant', { appearance: 'error' });
      }
      setTenantSubmitting(false);
    }
  };

  const Loading = (): JSX.Element => (
    <Skeleton variant="rect" width="100%" height={270} />
  );

  return (
    <Card className={className}>
      {tenantLoading ? (
        <Loading />
      ) : error ? (
        <ErrorAlert text="Failed to load tenant data" />
      ) : (
        <>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {isInAddMode ? 'New Tenant' : tenantId}
            </Typography>
            {tenantSubmitting && (
              <Alert icon={<CircularProgress size={20} />} severity="info">
                The Tenant is being submitted, please wait.
              </Alert>
            )}
            {!tenantLoading && (
              <TenantForm tenant={tenant} onChange={setTenantFormState} />
            )}
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              color="primary"
              onClick={submitTenant}
              disabled={!tenantFormState.isValid || tenantSubmitting}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default TenantCard;
