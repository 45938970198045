import { useState, useCallback, useEffect } from 'react';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import { GroupExpression } from 'advanext-models/lambda/filtering';
import { PaginatedResult } from 'advanext-models/advanext';
import {
  EntityType,
  NectarEntity,
  EntityMetadata,
} from 'advanext-models/nectar/entity';
import {
  CreateEntityFileRequest,
  CreateEntityFileResponse,
} from 'advanext-models/nectar/entity/lambda';

import {
  createEntityApi,
  createEntityFileApi,
  deleteEntityApi,
  updateEntityMetadata,
  getEntities,
  getFilteredEntities,
  getLatestEntity,
} from 'Api';

import { sleep } from 'Utilities';

// 1 sec delay to let the views to be created
const DEFAULT_DELAY = 2000;

export interface UseEntitiesParams {
  filterExpression?: GroupExpression;
  entityType?: EntityType;
  fetchAll?: boolean;
  latest?: boolean;
  sort?: string;
  offset?: Date;
}

export type UseEntitiesResult<T> = {
  error: Error | null;
  entities: NectarEntity<T>[];
  loading: boolean;
  loadMore: (reload: boolean) => Promise<void>;
  create: (request: NectarEntity<T>) => Promise<NectarEntity<T>>;
  createFile: (
    request: CreateEntityFileRequest,
  ) => Promise<CreateEntityFileResponse>;
  update: (entityId: string, request: EntityMetadata) => Promise<void>;
  remove: (entityId: string) => Promise<void>;
};

export const useEntities = <T = unknown>(
  compoundTenantId?: CompoundTenantId | string,
  compoundCompanyId?: CompoundCompanyId | string,
  params?: UseEntitiesParams,
): UseEntitiesResult<T> => {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<NectarEntity<T>[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<unknown | null>(
    null,
  );

  const { entityType, fetchAll, latest, sort, offset, filterExpression } =
    params || {};

  const loadMore = useCallback(
    async (reload = false) => {
      if (!compoundTenantId) return;

      try {
        setLoading(true);

        // Fetch the latest entity by type
        if (
          latest &&
          compoundCompanyId &&
          (entities.length === 0 || reload) &&
          entityType
        ) {
          const entity = await getLatestEntity<T>({
            entityType,
            compoundTenantId,
            compoundCompanyId,
            offset,
          });

          if (entity) {
            setEntities([entity]);
          } else {
            setEntities([]);
          }
        }

        // Fetch all entities in one go
        if (!latest && fetchAll && (entities.length === 0 || reload)) {
          let lastEvaluatedKey: any = null; // eslint-disable-line
          let result: PaginatedResult<NectarEntity<T>> | null = null;
          const accumulatedEntities: NectarEntity<T>[] = [];

          while (result === null || lastEvaluatedKey) {
            if (filterExpression) {
              result = await getFilteredEntities<T>({
                entityType,
                compoundTenantId,
                compoundCompanyId,
                lastEvaluatedKey,
                filterExpression,
                sort,
              });
            } else {
              result = await getEntities<T>({
                entityType,
                compoundTenantId,
                compoundCompanyId,
                sort: sort || 'descending',
                lastEvaluatedKey,
                offset,
              });
            }

            accumulatedEntities.push(...result.items);
            lastEvaluatedKey = result.lastEvaluatedKey;
          }

          console.log("setEntities(accumulatedEntities)", accumulatedEntities)

          setEntities(accumulatedEntities);
        }

        // Fetch next portion of entities (i.e. when implementing "Load More")
        if (
          !latest &&
          !fetchAll &&
          (lastEvaluatedKey || entities.length === 0 || reload)
        ) {
          let lastEvaluatedKey: any = null; // eslint-disable-line
          let result: PaginatedResult<NectarEntity<T>> | null = null;

          if (filterExpression) {
            result = await getFilteredEntities<T>({
              entityType,
              compoundTenantId,
              compoundCompanyId,
              lastEvaluatedKey,
              filterExpression,
              sort,
            });
          } else {
            result = await getEntities<T>({
              entityType,
              compoundTenantId,
              compoundCompanyId,
              sort: sort || 'descending',
              lastEvaluatedKey,
              offset,
            });
          }

          const newEntities = reload ? result.items : [...entities, ...result.items];
          setEntities(newEntities);

          console.log("setEntities(accumulatedEntities) 2: ")
          console.log(newEntities)
          setLastEvaluatedKey(result.lastEvaluatedKey);
        }

        setError(null);
      } catch (e) {
        // @ts-ignore
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [
      entityType,
      fetchAll,
      sort,
      offset,
      compoundTenantId,
      compoundCompanyId,
      entities,
      lastEvaluatedKey,
      latest,
      filterExpression,
    ],
  );

  const create = useCallback(
    async (entity: NectarEntity<T>) => {
      setLoading(true);
      const created = await createEntityApi<T>(entity);
      await sleep(DEFAULT_DELAY);
      await loadMore(true);
      setLoading(false);
      return created;
    },
    [loadMore],
  );

  const createFile = useCallback(
    async (request: CreateEntityFileRequest) => {
      setLoading(true);
      const response = await createEntityFileApi(request);
      await sleep(DEFAULT_DELAY);
      await loadMore(true);
      setLoading(false);
      return response;
    },
    [loadMore],
  );

  const update = useCallback(
    async (entityId: string, metadata: EntityMetadata) => {
      setLoading(true);
      await updateEntityMetadata(entityId, metadata);
      await sleep(DEFAULT_DELAY);
      await loadMore(true);
      setLoading(false);
    },
    [loadMore],
  );

  const remove = useCallback(
    async (entityId: string) => {
      setLoading(true);
      await deleteEntityApi(entityId);
      await sleep(DEFAULT_DELAY);
      await loadMore(true);
      setLoading(false);
    },
    [loadMore],
  );

  useEffect(() => {
    loadMore(true);
  }, []); // eslint-disable-line

  return {
    loading,
    entities,
    error,
    loadMore,
    create,
    createFile,
    update,
    remove,
  };
};
