import { mutate } from 'swr';
import { useAsyncCallback } from 'react-async-hook';
import { useToasts } from 'react-toast-notifications';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import {
  createUWTasksApi,
  getUWTasksApi,
  updateMonitoredCompaniesApi,
} from 'Api';
import { sleep } from 'Utilities';
import { user as userSelector } from 'Redux/models/Auth';
import { useSelector } from 'react-redux';
import { MonitoringPath, UWTasksPath } from 'Constants';

export const useUpdateMonitoredCompanies = (
  companyId: string,
  compoundTenantId: CompoundTenantId,
  isMonitored: boolean,
): {
  updateMonitoredCompanies: () => void;
  isMonitoredCompaniesUpdating: boolean;
} => {
  const { addToast } = useToasts();
  const user = useSelector(userSelector);

  const updateMonitoredCompanies = useAsyncCallback(async () => {
    if (!user?.tenant) {
      throw new Error('tenant of user must be defined');
    }

    const error = await updateMonitoredCompaniesApi(companyId, isMonitored);

    const uwTasks = await getUWTasksApi(companyId, compoundTenantId.toString());
    if (uwTasks.length === 0 && !isMonitored) {
      await createUWTasksApi(companyId, compoundTenantId.toString());
      await sleep(1000);

      const key = JSON.stringify({
        compoundCompanyId: companyId,
        compoundTenantId: compoundTenantId.toString(),
      });
      mutate([UWTasksPath, key]);
    }

    if (error) {
      addToast(error, {
        appearance: 'error',
      });
    } else {
      // Refetch monitored companies
      const stringified = JSON.stringify({
        compoundTenantId: compoundTenantId.toString(),
      });
      setTimeout(() => mutate([MonitoringPath, stringified]), 1000);
      addToast('Company monitoring updated', {
        appearance: 'success',
      });
    }
  });
  return {
    updateMonitoredCompanies: updateMonitoredCompanies.execute,
    isMonitoredCompaniesUpdating: updateMonitoredCompanies.loading,
  };
};
