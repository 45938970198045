import React, { Fragment } from 'react';
import { v1 as uuid } from 'uuid';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  Button,
} from '@material-ui/core';
import type { TenantApplication } from 'advanext-models/nectar/tenant';

type Props = {
  applications: Array<TenantApplication>;
  onEditApplication: (arg0: TenantApplication) => void;
  onRemoveApplication: (arg0: TenantApplication) => void;
  onClickApplication: (app: TenantApplication) => void;
};

const TenantApplicationsList = ({
  applications,
  onEditApplication,
  onRemoveApplication,
  onClickApplication,
}: Props): JSX.Element => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Identifier</TableCell>
          <TableCell>Application Source</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {applications &&
          applications.map((application) => (
            <Fragment key={uuid()}>
              <TableRow>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={(): void => onClickApplication(application)}
                  >
                    {application.tenantApplicationId}
                  </Button>
                </TableCell>
                <TableCell>{application.source}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(): void => onEditApplication(application)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={(): void => onRemoveApplication(application)}
                  >
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TenantApplicationsList;
