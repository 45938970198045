import { CompanySearchResult } from 'advanext-models/nectar/company';
import { DataSource } from 'advanext-models/nectar/report';
import { downloadFileContent } from 'Utilities';
import { apiService } from 'Services';
import { CompaniesSearchPath, MonitoringUpdatePath } from 'Constants';
import { plainToClass } from 'class-transformer';

// ---------
// Companies
// ---------

export const getCompanies = async (
  companyName: string,
  country: string,
): Promise<Array<CompanySearchResult>> => {
  const { data = [] } = await apiService.get(CompaniesSearchPath, {
    params: {
      query: companyName,
      country,
    },
  });
  return data.map((e: CompanySearchResult) =>
    plainToClass(CompanySearchResult, e),
  );
};

export const downloadCompanyReportApi = async (
  companyId: string,
): Promise<void> => {
  try {
    const { data } = await apiService.get(
      `companies/${encodeURIComponent(companyId)}/pdf-report?dataSource=${
        DataSource.BISNODE
      }`,
      {
        headers: {
          Accept: 'application/pdf',
        },
      },
    );
    downloadFileContent(data, 'report.pdf');
  } catch (e) {
    // TODO: Cover this case
  }
};

export const updateMonitoredCompaniesApi = async (
  companyId: string,
  isMonitored: boolean,
): Promise<string | undefined> => {
  try {
    const url = MonitoringUpdatePath.replace(':compoundCompanyId', companyId);

    if (isMonitored) {
      await apiService.delete(url);
    } else {
      await apiService.post(url);
    }
  } catch (e) {
    return 'Error occurs while updating company monitoring';
  }
};
