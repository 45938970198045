import { UWChange, UWTask } from 'advanext-models/nectar/uwtask';
import { plainToClass } from 'class-transformer';
import { UWTaskPath, UWTasksPath } from 'Constants';
import { apiService } from 'Services';

export const updateUWTaskApi = async (
  id: string,
  change: UWChange,
): Promise<UWTask | null> => {
  try {
    const { data } = await apiService.post(UWTaskPath.replace(/:id/g, id), {
      data: change,
    });

    return plainToClass(UWTask, data);
  } catch (e) {
    return null;
  }
};

export const createUWTasksApi = async (
  compoundCompanyId: string,
  compoundTenantId: string,
): Promise<Array<UWTask>> => {
  try {
    const { data } = await apiService.post(UWTasksPath, {
      data: {
        compoundCompanyId,
        compoundTenantId,
      },
    });

    return data.map((e: UWTask) => plainToClass(UWTask, e));
  } catch (e) {
    return [];
  }
};

export const getUWTasksApi = async (
  compoundCompanyId: string,
  compoundTenantId: string,
): Promise<Array<UWTask>> => {
  try {
    const { data } = await apiService.get(UWTasksPath, {
      params: {
        compoundCompanyId,
        compoundTenantId,
      },
    });

    return data.map((e: UWTask) => plainToClass(UWTask, e));
  } catch (e) {
    return [];
  }
};
