import {
  AMPLIFY_REGION,
  AMPLIFY_USER_POOL_ID,
  AMPLIFY_USER_POOL_CLIENT_ID,
} from 'Config';

export default {
  Auth: {
    region: AMPLIFY_REGION,
    userPoolId: AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: AMPLIFY_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
  },
  excludedPaths: ['/not-found'],
};
