import React from 'react';
import { StyledCard } from 'advanext-components';
import { CreditArrangement } from 'advanext-models/advanext/creditarrangement';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { headCells } from './constants';
import { formatDate, formatNumber, getValueOrNA } from 'Utilities';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  noData: {
    textAlign: 'center',
    fontSize: '16px',
    color: 'grey',
  },
});

type Props = {
  isLoading: boolean;
  creditArrangements: (CreditArrangement | undefined)[];
};

const CreditArrangements = ({
  isLoading,
  creditArrangements,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <StyledCard title="Credit Arrangements">
      {isLoading ? (
        <CircularProgress size={40} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, label, style }) => (
                <TableCell component="th" key={id} style={style}>
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {creditArrangements?.map((e?: CreditArrangement) => (
              <TableRow>
                <TableCell>{e?.creditArrangementId}</TableCell>
                <TableCell>{formatDate(e?.created)}</TableCell>
                <TableCell>{getValueOrNA(e?.state)}</TableCell>
                <TableCell>{formatNumber(e?.amount)}</TableCell>
                <TableCell>{formatNumber(e?.availableAmount)}</TableCell>
                <TableCell>{formatNumber(e?.consumedAmount)}</TableCell>
              </TableRow>
            ))}
            {!creditArrangements?.length && (
              <TableRow>
                <TableCell colSpan={6} className={classes.noData}>
                  Nothing to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </StyledCard>
  );
};

export default CreditArrangements;
