import { MonitoringChangesStatus } from 'advanext-models/nectar/monitoring';
import { MonitoringChangeTypeConst } from 'Constants';
import { EMPTY_STRING_DISPLAY_VALUE } from './common';

export const getMonitoringStatus = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case MonitoringChangesStatus.PROCESSED:
      return 'Processed';
    case MonitoringChangesStatus.PROGRESS:
      return 'In Progress';
    case MonitoringChangesStatus.UNPROCESSED:
      return 'Unprocessed';
    default:
      return value;
  }
};

export const monitoringStatuses = Object.values(MonitoringChangesStatus);

export const monitoringStatusesOptions = monitoringStatuses.map((value) => ({
  label: getMonitoringStatus(value),
  value,
}));

export const getMonitoringChangeType = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case MonitoringChangeTypeConst.CreditReportRating:
      return 'Credit Report Rating';
    case MonitoringChangeTypeConst.Management:
      return 'Management';
    case MonitoringChangeTypeConst.NectarRating:
      return 'Nectar Rating';
    case MonitoringChangeTypeConst.Address:
      return 'Address';
    case MonitoringChangeTypeConst.Name:
      return 'Name';
    default:
      return value;
  }
};
