import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { fetcher } from 'Api';
import { ApiEntity } from 'Types';
import { MonitoringPath, CompaniesInfoPath } from 'Constants';
import { user as userSelector } from 'Redux/models/Auth';
import { CompanyPosition } from 'Types';

export const useMonitoredCompaniesInfo = (): ApiEntity<CompanyPosition[]> => {
  const user = useSelector(userSelector);

  if (!user?.tenant) {
    throw new Error('tenant of user must be defined');
  }

  const { data: monitoring, error: monitoringError } = useSWR(
    [MonitoringPath],
    fetcher,
  );

  const monitoredCompaniesIds = (monitoring as string[])?.join(',');
  const monitoredCompaniesStringified = JSON.stringify({
    ids: monitoredCompaniesIds,
  });

  const { data: companiesInfo, error: companiesInfoError } = useSWR(
    monitoredCompaniesIds
      ? [CompaniesInfoPath, monitoredCompaniesStringified]
      : null,
    fetcher,
  );

  return {
    data: companiesInfo as Array<CompanyPosition>,
    isLoading: monitoredCompaniesIds
      ? !companiesInfo && !companiesInfoError
      : !monitoring && !monitoringError,
    error: monitoringError || companiesInfoError,
  } as ApiEntity<Array<CompanyPosition>>;
};
