import useSWR from 'swr';
import type { PaginatedResult } from 'advanext-models/advanext';
import type { TenantApplicationEvent } from 'advanext-models/nectar/tenant';

import { fetcher } from 'Api';
import { TenantApplicationEventsPath } from 'Constants';
import { ApiEntity } from 'Types';

export const useTenantApplicationEvents = (
  tenantId: string,
  tenantApplicationId: string,
  lastEvaluatedKey?: any, // eslint-disable-line
): ApiEntity<TenantApplicationEvent[]> => {
  const url = TenantApplicationEventsPath.replace(
    /:tenantId/g,
    tenantId,
  ).replace(/:applicationId/g, tenantApplicationId);

  const finalUrl = `${url}?lastEvaluatedKey=${
    lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : ''
  }`;
  const { data, error, revalidate } = useSWR<PaginatedResult<unknown>>(
    finalUrl,
    fetcher,
  );

  return {
    data: data?.items || [],
    lastEvaluatedKey: data?.lastEvaluatedKey,
    isLoading: !error && !data,
    error,
    revalidate,
  } as ApiEntity<TenantApplicationEvent[]>;
};
