import useSWR from 'swr';
import { User } from 'advanext-models/nectar';
import { fetcher } from 'Api';
import { TenantMembersPath } from 'Constants';
import { ApiEntity } from 'Types';
import { plainToClass } from 'class-transformer';

export const useTenantMembers = (id: string): ApiEntity<Array<User>> => {
  const { data, error, revalidate } = useSWR(
    TenantMembersPath.replace(/:id/g, encodeURIComponent(id)),
    fetcher,
  );

  // TODO: make a model transformer for string arrays
  const formattedData = ((data as Record<string, string>[]) || []).map(
    (item) => ({
      ...item,
      group: (item.group || '')
        .split(',')
        .map((str) => str.trim())
        .filter((elem) => elem !== ''),
    }),
  );

  return {
    data: formattedData
      .map((e) => plainToClass(User, e))
      .filter((user) => user.enabled),
    isLoading: !error && !data,
    error,
    revalidate,
  } as ApiEntity<Array<User>>;
};
