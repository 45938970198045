import { EMPTY_STRING_DISPLAY_VALUE } from './common';
import { DocumentType } from 'advanext-models/advanext/document';

export const getDocumentType = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case DocumentType.BALANCE_SHEET:
      return 'Financial Statement';
    case DocumentType.COLLECTION_REPORT_REQUEST_FORM:
      return 'Collection Report Request Form';
    case DocumentType.BANK_STATEMENT:
      return 'Bank Statement';
    case DocumentType.IDENTIFICATION_FILE:
      return 'IDnow';
    case DocumentType.LOAN_CONTRACT:
      return 'Loan agreement';
    case DocumentType.USER_CONTRACT:
      return 'Form K';
    case DocumentType.REPORTS:
      return 'Reports';
    case DocumentType.CRIF_COLLECTION_REPORT:
      return 'CRIF Collection Report';
    case DocumentType.OTHER:
      return 'Other';
    case DocumentType.COMPANY_DD:
      return 'Company DD';
    default:
      throw new Error(`Hey dev, you need to add a new doc type: ${value}`);
  }
};

export const documentTypeOptions = Object.values(DocumentType).map((value) => ({
  label: getDocumentType(value),
  value,
}));

export const getNameFromPath = (path?: string): string | undefined =>
  path?.split('/').slice(-1)[0];
