import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { v1 as uuid } from 'uuid';

import {
  Grid,
  Button,
  Card,
  CardHeader,
  Typography,
  Avatar,
  IconButton,
  Icon,
} from '@material-ui/core';
import { ErrorAlert, LoadingAlert, PageTitle } from 'advanext-components';
import type { Tenant } from 'advanext-models/nectar/tenant';

import { ROUTES } from 'Constants';
import { usePolicy, useTenants } from 'Hooks';
import { IsOwner } from 'Policies';
import { stringToColor } from 'Utilities';

const Tenants = (): JSX.Element => {
  const { push } = useHistory();
  const [isOwner, isOwnerLoading] = usePolicy([IsOwner]);

  const { data, isLoading, error } = useTenants();

  if (!isOwnerLoading && !isOwner) {
    return <Redirect to={ROUTES.Dashboard} />;
  }

  return (
    <>
      <PageTitle
        title="Tenants"
        subtitle={
          <Typography variant="subtitle1" color="textSecondary">
            By adding a new tenant you may grant a third party rights to use the
            Nectar platform.
          </Typography>
        }
        info="Manage data and applied pricing"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={(): void => {
            push(ROUTES.OwnerTenant.replace(':tenantId', 'new'));
          }}
        >
          Register new Tenant
        </Button>
      </PageTitle>

      {isLoading ? (
        <LoadingAlert text="Tenants are loading, please wait" />
      ) : error || !data ? (
        <ErrorAlert text="Failed to load tenants data" />
      ) : (
        <Grid container spacing={3}>
          {data.map((tenant: Tenant) => (
            <Grid item md={4} key={uuid()}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      onClick={(): void => {
                        push(
                          ROUTES.OwnerTenant.replace(
                            ':tenantId',
                            tenant.tenantId,
                          ),
                        );
                      }}
                    >
                      <Icon>create</Icon>
                    </IconButton>
                  }
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: stringToColor(tenant.tenantId),
                      }}
                    >
                      {tenant.tenantId[0].toUpperCase()}
                    </Avatar>
                  }
                  title={tenant.tenantId}
                  subheader={tenant.name}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Tenants;
