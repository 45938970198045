import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { StyledCard } from 'advanext-components';
import { EntityType, GenericNectarData } from 'advanext-models/nectar/entity';
import { CompoundTenantId } from 'advanext-models/nectar/tenant';
import { getComparator } from 'Utilities';
import { Order, SortOrder, BankStatementObject } from 'Types';
import { useEntities } from 'Hooks';
import { headCells } from './constants';
import StatementsRemover from './StatementsRemover';
import BankStatementDetails from './BankStatementDetails';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  row: {
    cursor: 'pointer',
  },
});

type Props = {
  compoundTenantId: CompoundTenantId;
  companyId: string;
};

const BankStatements = ({
  compoundTenantId,
  companyId,
}: Props): JSX.Element => {
  const classes = useStyles();

  const { entities, loading, remove } = useEntities<GenericNectarData>(
    compoundTenantId,
    companyId,
    {
      entityType: EntityType.BANK_STATEMENT,
      fetchAll: true,
    },
  );

  const data = entities
    .map((entity) =>
      entity.content?.elements
        .concat([
          {
            id: 'created',
            name: 'Created',
            value: entity.created.toString(),
          },
        ])
        .concat([{ id: 'entityId', name: 'Entity Id', value: entity.entityId }])
        .reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next.value,
          }),
          {} as BankStatementObject,
        ),
    )
    .filter(
      (nectarElementAsObject?: BankStatementObject) =>
        nectarElementAsObject &&
        nectarElementAsObject.bank &&
        nectarElementAsObject.iban,
    ) as BankStatementObject[];

  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof BankStatementObject>();
  const [selectedStatement, setSelectedStatement] =
    useState<BankStatementObject | null>(null);

  const handleRequestSort = (property: keyof BankStatementObject): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  return (
    <>
      {selectedStatement ? (
        <BankStatementDetails
          data={selectedStatement}
          onClose={(): void => setSelectedStatement(null)}
        />
      ) : (
        <>
          <div>
            <StatementsRemover
              isLoading={loading}
              options={data}
              onDelete={async (entityId: string): Promise<void> => {
                await remove(entityId);
              }}
            />
          </div>
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledCard title="Uploaded Bank Statements">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map(({ id, label, style, isSortable }) =>
                      isSortable ? (
                        <TableCell
                          component="th"
                          sortDirection={orderBy === id ? order : false}
                          key={id}
                          style={style}
                        >
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : Order.ASC}
                            onClick={(): void => {
                              handleRequestSort(id);
                            }}
                            IconComponent={ArrowDropDown}
                          >
                            <strong>{label}</strong>
                          </TableSortLabel>
                        </TableCell>
                      ) : (
                        <TableCell component="th" key={id} style={style}>
                          <strong>{label}</strong>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length ? (
                    data
                      .sort(
                        orderBy
                          ? getComparator<BankStatementObject>(orderBy, order)
                          : (): SortOrder => 0,
                      )
                      .map((e, index) => (
                        <TableRow
                          key={`${e.bank}_${e.iban}_${e.created}_${index}`}
                          onClick={(): void => setSelectedStatement(e)}
                          className={classes.row}
                        >
                          <TableCell>
                            {dayjs(e.created).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell>{e.bank}</TableCell>
                          <TableCell>{e.iban}</TableCell>
                          <TableCell>{e.currency}</TableCell>
                          <TableCell>
                            {dayjs(e.period_start).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell>
                            {dayjs(e.period_end).format('YYYY-MM-DD')}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1">
                          No statements to show.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledCard>
          </Box>
        </>
      )}
    </>
  );
};

export default BankStatements;
