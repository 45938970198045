import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { user as userSelector } from 'Redux/models/Auth';
import { useToasts } from 'react-toast-notifications';
import { CompoundCompanyId } from 'advanext-models/advanext/company';

import { MonitoringPath } from 'Constants';
import { fetcher } from 'Api';
import { ApiEntity } from 'Types';

export const useMonitoredCompanies = (): ApiEntity<CompoundCompanyId[]> => {
  const user = useSelector(userSelector);
  const { addToast } = useToasts();

  if (!user?.tenant) {
    throw new Error('tenant of user must be defined');
  }

  const { data, error } = useSWR([MonitoringPath], fetcher);

  if (error) {
    addToast(error, {
      appearance: 'error',
    });
  }

  return {
    data:
      (data as string[])?.map((item) => CompoundCompanyId.fromValue(item)) ||
      [],
    isLoading: !error && !data,
    error,
  } as ApiEntity<CompoundCompanyId[]>;
};
