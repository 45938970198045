import { Mandate } from 'advanext-models/nectar/management';

export const headCells: Array<{
  id: keyof Mandate;
  label: string;
}> = [
  { id: 'fullName', label: 'Full Name' },
  { id: 'companyName', label: 'Company' },
  {
    id: 'uid',
    label: 'Unique ID',
  },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },
];
