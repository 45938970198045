import type { PolicyDocument } from 'Types';

/* eslint-disable */
export default (policies: Array<PolicyDocument>): PolicyDocument => ({
  selectors: policies.reduce(
    (memo: Record<string, any>, policy) => ({
      ...memo,
      ...(policy.selectors || {}),
    }),
    {},
  ) as any,
  permit: async (state, dispatch) => {
    const results = await Promise.all(
      policies.map((policy) => policy.permit(state, dispatch)),
    );
    return results.every((result) => !!result);
  },
});
