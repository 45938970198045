import { mutate } from 'swr';
import { useToasts } from 'react-toast-notifications';
import { useAsyncCallback } from 'react-async-hook';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Country } from 'advanext-models/advanext';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { Answer } from 'advanext-models/nectar/astrea';

import { createScoreResultApi } from 'Api';
import { user as userSelector } from 'Redux/models/Auth';
import { TenantProviderContext } from 'Views/providers/Tenant';
import { ScoreResultPath } from 'Constants';

export const useCreateScoreResult = (
  referenceId: string,
  country?: Country,
  compoundCompanyId?: CompoundCompanyId,
): {
  createResult: (answers: Answer[], blueprintId: string, type: string) => void;
  isCreating: boolean;
} => {
  const { addToast } = useToasts();
  const user = useSelector(userSelector);
  const { compoundTenantId } = useContext(TenantProviderContext);

  const createResult = useAsyncCallback(
    async (answers: Answer[], blueprintId: string, type: string) => {
      if (!user?.tenant) {
        throw new Error('tenant of user must be defined');
      }
      const error = await createScoreResultApi({
        // eslint-disable-next-line
        blueprintId: blueprintId as any,
        referenceId,
        adminId: user.id,
        compoundTenant: compoundTenantId,
        answers,
      });
      if (error) {
        addToast(error, {
          appearance: 'error',
        });
      } else {
        addToast('Score result was created', {
          appearance: 'success',
        });
        // Re-fetch score result
        const stringified = JSON.stringify({
          type,
          country,
          compoundCompanyId: compoundCompanyId?.toString(),
          compoundTenantId: compoundTenantId.toString(),
        });
        mutate([ScoreResultPath, stringified]);
      }
    },
  );

  return {
    createResult: createResult.execute,
    isCreating: createResult.loading,
  };
};
