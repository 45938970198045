import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { PageTitle } from 'advanext-components';

import { user as userSelector } from 'Redux/models/Auth';
import { useSelectors } from 'Hooks';

import { TenantApplicationsWidget } from 'Views/widgets';
import { WebhooksCard } from './components';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

const Applications = (): JSX.Element => {
  const classes = useStyles();
  const [user] = useSelectors([userSelector]);

  return (
    <>
      <PageTitle
        title="Application"
        subtitle={
          <Typography variant="subtitle1" color="textSecondary">
            Register new applications to integrate them with Nectar.
          </Typography>
        }
      />

      {user && !user.tenant && (
        <Alert severity="warning">You do not belong to any tenant.</Alert>
      )}

      {user && user.tenant && (
        <TenantApplicationsWidget
          className={classes.card}
          tenantId={user.tenant}
        />
      )}

      <WebhooksCard className={classes.card} />
    </>
  );
};

export default Applications;
