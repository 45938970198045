import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Authenticator } from 'aws-amplify-react';

import { AUTH_STATES, translateCognitoUser } from 'Services/auth';

import SignIn from '../SignIn';
import ConfirmSignUp from '../ConfirmSignUp';
import ForgotPassword from '../ForgotPassword';
import RequireNewPassword from '../RequireNewPassword';
import Loading from '../Loading';
import ErrorContext from '../ErrorContext';

/* eslint-disable */

class CustomAuthenticator extends Authenticator {
  handleStateChange(state: any, data: any) {
    if (state === AUTH_STATES.SignedIn) {
      const user = translateCognitoUser(data);
      (this.props as any)?.storeAuthenticatedUser(user);
      super.handleStateChange(state, user);
    } else if (state === AUTH_STATES.SignOut) {
      (this.props as any)?.signOut();
      super.handleStateChange(state, data);
    } else if (state === AUTH_STATES.VerifyContact) {
      const user = translateCognitoUser(data);
      (this.props as any)?.storeAuthenticatedUser(user);
      super.handleStateChange(state, user);
    } else {
      super.handleStateChange(state, data);
    }
  }

  render() {
    if (this.state.showToast) super.setState({ showToast: false });

    return (
      <ErrorContext.Provider value={this.state.error}>
        {super.render()}
      </ErrorContext.Provider>
    );
  }
}

// DON'T subscribe to user events, since that would lead to
// infinite re-rendering cycles
const EnhancedCustomAuthenticator: any = compose(
  withRouter,
  connect(
    null,
    // @ts-ignore
    ({ Auth: { storeAuthenticatedUser, signOut } }) => ({
      storeAuthenticatedUser,
      signOut,
    }),
  ),
)(CustomAuthenticator);

type Props = {
  amplifyConfig: any;
  children: React.ReactNode;
  federated?: boolean;
  signUpConfig: Record<string, any>;
};

const AuthProvider = ({
  amplifyConfig,
  children,
  federated,
  signUpConfig,
}: Props): JSX.Element => {
  const history = useHistory();
  const excludedPaths = amplifyConfig.excludedPaths || [];

  const currentPathExcluded = !!excludedPaths.find(
    (path: string) => history.location.pathname.indexOf(path) === 0,
  );

  if (currentPathExcluded) return <>{children}</>;

  return (
    <>
      <EnhancedCustomAuthenticator
        amplifyConfig={amplifyConfig}
        container=""
        federated={federated}
        hideDefault
        signUpConfig={signUpConfig}
      >
        <SignIn />
        <ConfirmSignUp />
        <ForgotPassword />
        <RequireNewPassword />
        {/*// @ts-ignore*/}
        <Loading />
        {children}
      </EnhancedCustomAuthenticator>
    </>
  );
};
export default AuthProvider;
