import useSWR from 'swr';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Dayjs } from 'dayjs';
import { NectarReport } from 'advanext-models/nectar/report';
import { user as userSelector } from 'Redux/models/Auth';
import { CompanyPath } from 'Constants';
import { fetcher } from 'Api';
import { ApiEntity } from 'Types';
import { plainToClass } from 'class-transformer';

import { TenantProviderContext } from 'Views/providers/Tenant';

export const useCompany = (id: string, at?: Dayjs): ApiEntity<NectarReport> => {
  const user = useSelector(userSelector);
  const { compoundTenantId } = useContext(TenantProviderContext);

  if (!user?.tenant) {
    throw new Error('tenant of user must be defined');
  }
  const stringified = JSON.stringify({
    compoundTenant: compoundTenantId.toString(),
    at: at?.format('YYYY-MM-DD'),
  });

  const { data, error, revalidate } = useSWR<NectarReport>(
    [CompanyPath.replace(/:id/g, encodeURIComponent(id)), stringified],
    fetcher,
    { revalidateOnFocus: false },
  );

  return {
    data: plainToClass(NectarReport, data),
    isLoading: !error && !data,
    error,
    revalidate,
  } as ApiEntity<NectarReport>;
};
