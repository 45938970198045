import React, { useState, useMemo } from 'react';

import { Tab, Box, makeStyles } from '@material-ui/core';
import { Country } from 'advanext-models/advanext';
import { Managers } from 'advanext-models/nectar/report';
import { Person } from 'advanext-models/nectar/management';

import type { ConsolidatedMultiSourceProperty } from 'advanext-models/nectar/multisource';
import { StyledTabs, TabPanel } from 'advanext-components';
import { getCountry } from 'Utilities';
import ManagersTable from './ManagersTable';
import OtherMandatesTable from './OtherMandatesTable';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#262B32',
    color: '#fff',
    height: '34px',
    minHeight: '34px',
    borderRadius: '4px',
  },
  indicator: {
    height: '0px',
  },
  tab: {
    height: '34px',
    minHeight: '34px',
  },
  selected: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
  textColorInherit: {
    opacity: 1,
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    backgroundColor: 'transparent',
  },
}));

type Props = {
  managers: ConsolidatedMultiSourceProperty<Managers>;
  companyId: string;
  country?: Country;
};

const ManagementInfo = ({
  managers,
  companyId,
  country,
}: Props): JSX.Element => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  const allManagers: Person[] = useMemo(
    () => managers?.consolidated?.persons ?? [],
    [managers],
  );
  const [currentManagers, previousManagers] = useMemo(
    () => [
      allManagers.filter((m) => m.currentPosition),
      allManagers.filter((m) => !m.currentPosition),
    ],
    [allManagers],
  );

  const { isCH: isSwissCompany } = useMemo(
    () => getCountry(country),
    [country],
  );

  return (
    <>
      <Box mt={3} display="flex" justifyContent="center">
        <StyledTabs
          value={tab}
          onChange={(_, newTab: number): void => {
            setTab(newTab);
          }}
          classes={{ root: classes.root, indicator: classes.indicator }}
        >
          <Tab
            label="Current Managers"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              textColorInherit: classes.textColorInherit,
            }}
          />
          <Tab
            label="Previous Managers"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              textColorInherit: classes.textColorInherit,
            }}
          />
          {isSwissCompany && (
            <Tab
              label="Other Mandates"
              classes={{
                root: classes.tab,
                selected: classes.selected,
                textColorInherit: classes.textColorInherit,
              }}
            />
          )}
        </StyledTabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <ManagersTable managers={currentManagers} title="Current managers" />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ManagersTable managers={previousManagers} title="Previous managers" />
      </TabPanel>
      {isSwissCompany && (
        <TabPanel value={tab} index={2}>
          <OtherMandatesTable companyId={companyId} />
        </TabPanel>
      )}
    </>
  );
};
export default ManagementInfo;
