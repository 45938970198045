import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import { ValueType } from 'react-select';
import {
  TableRow,
  TableCell,
  Chip,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Save,
  Cancel,
} from '@material-ui/icons';

import { StyledInput, StyledSelect } from 'advanext-components';
import Status from 'advanext-models/nectar/uwtask/Status';
import { UWTask } from 'advanext-models/nectar/uwtask';
import { OptionType } from 'Types';
import { formatDateTime, getTaskStatus, taskStatusesOptions } from 'Utilities';
import updatesColumns from './updatesColumns.json';

const useStyles = makeStyles({
  status: {
    color: '#000000',
    minWidth: '125px',
  },
  unprocessedStatus: {
    backgroundColor: 'rgba(255, 152, 0, 0.4)',
    border: '1px solid #FF9800',
  },
  completedStatus: {
    backgroundColor: 'rgba(76, 175, 80, 0.4);',
    border: '1px solid #4CAF50',
  },
  declinedStatus: {
    backgroundColor: 'rgba(244, 67, 54, 0.4)',
    border: '1px solid #F44336;',
  },
  progressStatus: {
    backgroundColor: 'rgba(33, 150, 243, 0.4)',
    border: '1px solid #2196F3',
  },
  notRequiredStatus: {
    backgroundColor: 'rgba(171, 172, 173, 0.5)',
    border: '1px solid #ABACAD',
  },
  incompleteStatus: {
    backgroundColor: 'rgba(255, 152, 0, 0.4)',
    border: '1px solid #FF9800',
  },
  updatesRow: {
    background: '#f2f2f2',
  },
});

type Props = {
  row: UWTask;
  isEditable: boolean;
  onUpdateRow: (id: string, comment: string, status: Status) => void;
};

const Row = ({ row, isEditable, onUpdateRow }: Props): JSX.Element => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const initialStatusOption = useMemo(
    () =>
      [
        {
          label: 'Required',
          value: 'REQUIRED',
        },
      ].find(({ value }) => value === row.status) ?? {
        label: 'Required',
        value: 'REQUIRED',
      },
    [row.status],
  );
  const [status, setStatus] =
    useState<ValueType<OptionType, boolean>>(initialStatusOption);
  const [comment, setComment] = useState('');

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(): void => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.uwTaskType}</TableCell>
        <TableCell>
          <StyledSelect
            value={status}
            options={taskStatusesOptions}
            onChange={(statusOption): void => setStatus(statusOption)}
            className="table"
            disabled={!isEditMode}
            disabledComponent={
              <Chip
                size="small"
                variant="outlined"
                color="secondary"
                label={getTaskStatus(row.status)}
                className={cn(classes.status, {
                  [classes.unprocessedStatus]:
                    row.status === Status.PartiallyApproved ||
                    row.status === Status.Required,
                  [classes.completedStatus]: row.status === Status.Completed,
                  [classes.declinedStatus]: row.status === Status.Declined,
                  [classes.progressStatus]: row.status === Status.ToReview,
                  [classes.notRequiredStatus]:
                    row.status === Status.NotRequired,
                  [classes.incompleteStatus]: row.status === Status.Incomplete,
                })}
              />
            }
          />
        </TableCell>
        <TableCell>{formatDateTime(row.updated)}</TableCell>
        <TableCell>{row.updatedBy}</TableCell>
        <TableCell title={row.comment}>
          <StyledInput
            value={comment}
            onChange={(event): void => setComment(event.target.value)}
            disabled={!isEditMode}
            className="table"
            disabledComponent={<span>{row.comment}</span>}
          />
        </TableCell>
        {isEditable && (
          <TableCell align="right">
            {isEditMode ? (
              <>
                <IconButton
                  onClick={async (): Promise<void> => {
                    setIsEditMode(false);
                    onUpdateRow(
                      row.uwTaskId,
                      comment,
                      (status as { value: Status }).value,
                    );
                  }}
                  size="small"
                  color="inherit"
                >
                  <Save />
                </IconButton>
                <IconButton
                  onClick={(): void => {
                    setIsEditMode(false);
                    setComment('');
                  }}
                  size="small"
                  color="inherit"
                >
                  <Cancel />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={(): void => {
                    setIsEditMode(true);
                  }}
                  size="small"
                  color="inherit"
                >
                  <Edit />
                </IconButton>
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      {open && (
        <>
          <TableRow className={classes.updatesRow}>
            <TableCell />
            {updatesColumns.map(({ id, label }) => (
              <TableCell key={id}>
                <strong>{label}</strong>
              </TableCell>
            ))}
            <TableCell colSpan={2} />
          </TableRow>
          {row.changes
            .sort(
              (a, b) =>
                new Date(b.updated).getTime() - new Date(a.updated).getTime(),
            )
            .map((update) => (
              <TableRow
                key={`${row.uwTaskId}-${update.updated}`}
                className={classes.updatesRow}
              >
                <TableCell />
                <TableCell>{formatDateTime(update.updated)}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={getTaskStatus(update.status)}
                    className={cn(classes.status, {
                      [classes.unprocessedStatus]:
                        update.status === Status.PartiallyApproved ||
                        update.status === Status.Required,
                      [classes.completedStatus]:
                        update.status === Status.Completed,
                      [classes.declinedStatus]:
                        update.status === Status.Declined,
                      [classes.progressStatus]:
                        update.status === Status.ToReview,
                      [classes.notRequiredStatus]:
                        update.status === Status.NotRequired,
                      [classes.incompleteStatus]:
                        row.status === Status.Incomplete,
                    })}
                  />
                </TableCell>
                <TableCell>{update.updatedBy}</TableCell>
                <TableCell colSpan={3}>{update.comment}</TableCell>
              </TableRow>
            ))}
        </>
      )}
    </>
  );
};

export default Row;
