import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableSortLabel,
  makeStyles,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core';
import { ArrowDropDown, Search } from '@material-ui/icons';
import {
  ErrorAlert,
  LoadingAlert,
  StyledCard,
  StyledSelect,
} from 'advanext-components';
import { OptionType, Order, SortOrder } from 'Types';
import { useMonitoredChanges } from 'Hooks';
import { MONITORED_CHANGES_PER_PAGE } from 'Constants';
import { getComparator } from 'Utilities';
import events from './events.json';
import eventsExtended from './eventsExtended.json';
import {
  MonitoringChanges,
  GetMonitoringChangesResult,
  MonitoringChangesStatus,
} from 'advanext-models/nectar/monitoring';
import Row from './Row';
import { ValueType } from 'react-select';

type Props = {
  companyId?: string;
  status?: MonitoringChangesStatus;
  title: string;
  isCompanyNameShown?: boolean;
};

const useStyles = makeStyles({
  root: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  showMoreButton: {
    border: '1px solid #262B32',
    color: '#262B32',
    margin: '8px 0px',
    padding: '4px 15px',
    textTransform: 'none',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    marginTop: '-10px',
  },
  companyFilter: {
    width: '200px',
  },
});

const MonitoringTable = ({
  companyId,
  status,
  title,
  isCompanyNameShown = false,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof MonitoringChanges>();

  const eventsColumns = useMemo(
    () => (isCompanyNameShown ? eventsExtended : events),
    [isCompanyNameShown],
  );

  const [monitoredChanges, setMonitoredChanges] =
    useState<GetMonitoringChangesResult>();

  const [isCompanyOpen, setCompanyOpen] = useState(false);

  const [companyNameFilter, setCompanyNameFilter] = useState<
    ValueType<OptionType, boolean> | undefined
  >(undefined);

  const { getChanges, companiesFilterOptions, result, error, isLoading } =
    useMonitoredChanges({
      limit: MONITORED_CHANGES_PER_PAGE,
      status,
      lastEvaluatedKey: monitoredChanges?.lastEvaluatedKey as {
        [key: string]: unknown;
      },
    });

  useEffect(() => {
    getChanges(companyId, undefined);
    // eslint-disable-next-line
  }, []); // run once when component is mounting

  useEffect(() => {
    if (result) {
      setMonitoredChanges((state) => ({
        changes: (state?.changes ?? []).concat(result.changes),
        lastEvaluatedKey: result.lastEvaluatedKey,
      }));
    }
  }, [result, setMonitoredChanges]);

  const handleRequestSort = useCallback(
    (property: keyof MonitoringChanges) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    },
    [order, orderBy, setOrder, setOrderBy],
  );

  const handleLoadMore = useCallback(
    (compoundCompanyId?: string) => {
      getChanges(
        companyId ?? compoundCompanyId,
        monitoredChanges?.lastEvaluatedKey,
      );
    },
    [getChanges, monitoredChanges, companyId],
  );

  const handleGetChanges = useCallback(
    (compoundCompanyId?: string) => {
      setMonitoredChanges(undefined);
      getChanges(companyId ?? compoundCompanyId, undefined);
    },
    [getChanges, setMonitoredChanges, companyId],
  );

  return (
    <StyledCard title={title}>
      {isLoading && !monitoredChanges?.changes ? (
        <LoadingAlert text="Monitoring events are loading, please wait" />
      ) : error ? (
        <ErrorAlert text="Failed to load monitoring events" />
      ) : (
        <>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '5%' }} />
                {eventsColumns.map(({ id, label, style, isSortable }) =>
                  isSortable ? (
                    <TableCell
                      sortDirection={orderBy === id ? order : false}
                      key={id}
                      style={style}
                    >
                      {id === 'companyName' && isCompanyNameShown && (
                        <ClickAwayListener
                          onClickAway={(): void => {
                            setCompanyOpen(false);
                          }}
                        >
                          <IconButton
                            onClick={(): void => {
                              setCompanyOpen(true);
                            }}
                            style={{ backgroundColor: 'transparent' }}
                            disableRipple
                            size="small"
                          >
                            {isCompanyOpen ? (
                              <div className={classes.companyFilter}>
                                <StyledSelect
                                  value={companyNameFilter}
                                  options={[
                                    { label: 'All', value: '' },
                                    ...companiesFilterOptions,
                                  ]}
                                  onChange={(
                                    e: ValueType<OptionType, boolean>,
                                  ): void => {
                                    setCompanyNameFilter(e);
                                    // @ts-ignore
                                    handleGetChanges(e.value);
                                  }}
                                />
                              </div>
                            ) : (
                              <Search />
                            )}
                          </IconButton>
                        </ClickAwayListener>
                      )}
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : Order.ASC}
                        onClick={(): void => {
                          handleRequestSort(id as keyof MonitoringChanges);
                        }}
                        IconComponent={ArrowDropDown}
                      >
                        <strong>{label}</strong>
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell key={id} style={style}>
                      <strong>{label}</strong>
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {monitoredChanges?.changes
                .sort(
                  orderBy ? getComparator(orderBy, order) : (): SortOrder => 0,
                )
                .map((e) => (
                  <Row
                    key={e.monitoringChangesId.toString()}
                    row={e}
                    getChanges={handleGetChanges}
                    isCompanyNameShown={isCompanyNameShown}
                  />
                ))}
            </TableBody>
          </Table>
          {monitoredChanges?.lastEvaluatedKey && (
            <Box justifyContent="center" display="flex" position="relative">
              <Button
                onClick={(): void =>
                  // @ts-ignore
                  handleLoadMore(companyId ?? companyNameFilter?.value)
                }
                className={classes.showMoreButton}
                disabled={isLoading}
              >
                Load more
              </Button>
              {isLoading && (
                <CircularProgress size={20} className={classes.spinner} />
              )}
            </Box>
          )}
        </>
      )}
    </StyledCard>
  );
};

export default MonitoringTable;
