import React, { useMemo, useCallback } from 'react';
import { CircularProgress, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Country } from 'advanext-models/advanext';
import {
  DataSource,
  DataSourcesMetadata,
  QueryLevel,
} from 'advanext-models/nectar/report';
import { MultiSourceProperty } from 'advanext-models/nectar/multisource';

import { useDataSources } from 'Hooks';
import { getCountry } from 'Utilities';
import {
  companyDataSourcesCH,
  creditReportsDataSourcesCH,
  collectionReportDataSourcesCH,
  companyDataSourcesDE,
  creditReportsDataSourcesDE,
} from 'Constants';
import DataSourceCard from './DataSourceCard';

const useStyles = makeStyles({
  widgetBox: {
    minWidth: '500px',
    minHeight: '96px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  name: {
    fontSize: 11,
  },
});

type ExtendedDataSource = DataSourcesMetadata & {
  status?: QueryLevel;
  dataSource: keyof typeof DataSource;
  supportsRefresh: boolean;
};

type Props = {
  companyId: string;
  companyName?: string;
  country?: Country;
  data?: MultiSourceProperty<DataSourcesMetadata>;
  at?: string;
  disabledReorder: boolean;
  compoundTenant: string;
  onOrderCrifCollection: (companyId: string) => void | Promise<void>;
};
const DataSources = ({
  companyId,
  companyName,
  country,
  data,
  at,
  disabledReorder,
  compoundTenant,
  onOrderCrifCollection,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { data: metadata, isLoading, error } = useDataSources(companyId);
  const extendDataSources = useCallback(
    (
      dataSources: Array<keyof typeof DataSource>,
      additionalData: { updateModel: MultiSourceProperty<QueryLevel> },
      initialData?: MultiSourceProperty<DataSourcesMetadata>,
    ): Array<ExtendedDataSource> =>
      dataSources.map((dataSource) => ({
        ...initialData?.[dataSource],
        status: additionalData.updateModel[dataSource],
        dataSource,
        supportsRefresh: dataSource !== DataSource.ZEFIX,
      })),
    [],
  );

  const groupedMetadata = useMemo(() => {
    const { isCH } = getCountry(country);

    let companyData: Array<ExtendedDataSource> = [];
    let creditReports: Array<ExtendedDataSource> = [];
    let collectionReport: Array<ExtendedDataSource> = [];
    if (metadata) {
      companyData = extendDataSources(
        isCH ? companyDataSourcesCH : companyDataSourcesDE,
        metadata,
        data,
      );
      creditReports = extendDataSources(
        isCH ? creditReportsDataSourcesCH : creditReportsDataSourcesDE,
        metadata,
        data,
      );
      collectionReport = isCH
        ? extendDataSources(collectionReportDataSourcesCH, metadata, data)
        : [];
    }

    // In old app there is a filter for creditform international, to show it only for selected countries
    // const creditreformInternationalDataSources = [CREDITREFORM_INTERNATIONAL_COMPLETE, CREDITREFORM_INTERNATIONAL_LIMITED];
    // const creditreformInternationalCountries = ['DE', 'AT', 'BE', 'FR', 'GB', 'IE', 'IT', 'LU', 'NL', 'PT', 'ES'];

    return {
      'Company Data': companyData,
      'Credit Reports': creditReports,
      'Collection Report': collectionReport,
    };
  }, [extendDataSources, country, metadata, data]);

  if (isLoading) {
    return (
      <Box className={classes.widgetBox}>
        <CircularProgress size={30} />
      </Box>
    );
  }
  if (!metadata || error) {
    return (
      <Box className={classes.widgetBox}>
        <Alert severity="error">Failed to load data sources</Alert>
      </Box>
    );
  }

  return (
    <Box className={classes.box}>
      {Object.entries(groupedMetadata).map(([key, value]) =>
        value.length ? (
          <Grid key={key} container direction="column">
            <Grid item className={classes.name}>
              {key}
            </Grid>
            <Grid container direction="row" wrap="nowrap">
              {value.map(
                ({ created, status, supportsRefresh, dataSource, link }) => (
                  <DataSourceCard
                    key={dataSource}
                    companyId={companyId}
                    companyName={companyName}
                    link={link}
                    created={created}
                    dataSource={dataSource}
                    status={status}
                    supportsRefresh={supportsRefresh}
                    at={at}
                    disabledReorder={
                      key === 'Collection Report' ? true : disabledReorder
                    }
                    compoundTenant={compoundTenant}
                    onOrderCrifCollection={onOrderCrifCollection}
                  />
                ),
              )}
            </Grid>
          </Grid>
        ) : null,
      )}
    </Box>
  );
};

export default DataSources;
