import { useCallback } from 'react';
import useSWR from 'swr';
import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { CollectionOrder } from 'advanext-models/nectar/crif';

import { fetcher, orderCollectionsApi } from 'Api';
import { GetCollectionsPath } from 'Constants';

const DEFAULT_POLL_INTERVAL = 30 * 1000;

interface UseCrifCollections {
  data: CollectionOrder[];
  isLoading: boolean;
  error: Error;
  order: (contractDocumentId: string) => Promise<void>;
}

export const useCrifCollections = (
  groupId?: CompoundCompanyId | string,
): UseCrifCollections => {
  const stringGroupId = groupId?.toString() || '';
  const path = GetCollectionsPath.replace(':id', stringGroupId);
  const { data, error, revalidate } = useSWR([path], fetcher, {
    refreshInterval: DEFAULT_POLL_INTERVAL,
  });

  const order = useCallback(
    async (contractDocumentId: string) => {
      if (!stringGroupId) return;

      await orderCollectionsApi(stringGroupId, contractDocumentId);
      await revalidate();
    },
    [stringGroupId, revalidate],
  );

  return {
    data: (data || []) as CollectionOrder[],
    isLoading: !error && !data,
    error,
    order,
  };
};
