import useSWR from 'swr';
import { fetcher } from 'Api';
import { CompanyMandatesPath } from 'Constants';
import { Mandate } from 'advanext-models/nectar/management';
import { ApiEntity, OtherMandates } from 'Types';
import { plainToClass } from 'class-transformer';

export const useCompanyMandates = (id: string): ApiEntity<OtherMandates> => {
  const { data, error, isValidating } = useSWR(
    [CompanyMandatesPath.replace(/:id/g, encodeURIComponent(id))],
    fetcher,
    { revalidateOnFocus: false },
  );
  const d: OtherMandates = {};
  if (data) {
    for (const [key, value] of Object.entries(data as OtherMandates)) {
      const arr: Array<Mandate> = (value as Array<Mandate>).map((e: Mandate) =>
        plainToClass(Mandate, e),
      );
      d[key] = arr;
    }
  }

  return {
    data: data ? d : undefined,
    isLoading: (data === undefined && !error) || isValidating,
    error,
  } as ApiEntity<OtherMandates>;
};
