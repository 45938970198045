import React, { useState } from 'react';
import { Tab, makeStyles } from '@material-ui/core';
import { StyledTabs, TabPanel } from 'advanext-components';
import { MonitoringChangesStatus } from 'advanext-models/nectar/monitoring';

import MonitoringTable from './MonitoringTable';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#262B32',
    color: '#fff',
    height: '34px',
    minHeight: '34px',
    borderRadius: '4px',
  },
  indicator: {
    height: '0px',
  },
  tab: {
    height: '34px',
    minHeight: '34px',
  },
  selected: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
  textColorInherit: {
    opacity: 1,
  },
}));

type Props = {
  companyId: string;
};

const Monitoring = ({ companyId }: Props): JSX.Element => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledTabs
          value={tab}
          onChange={(_, newTab: number): void => {
            setTab(newTab);
          }}
          classes={{ root: classes.root, indicator: classes.indicator }}
        >
          <Tab
            label="Unprocessed Events"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              textColorInherit: classes.textColorInherit,
            }}
          />
          <Tab
            label="All Events"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              textColorInherit: classes.textColorInherit,
            }}
          />
        </StyledTabs>
      </div>

      <TabPanel value={tab} index={0}>
        <MonitoringTable
          companyId={companyId}
          status={MonitoringChangesStatus.UNPROCESSED}
          title="Monitoring events"
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MonitoringTable companyId={companyId} title="Monitoring events" />
      </TabPanel>
    </>
  );
};

export default Monitoring;
