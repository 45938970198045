import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import { v1 } from 'uuid';

import { User } from 'advanext-models/nectar';

import routes from 'Config/routes';
import { amplifyConfig } from 'Config/auth';
import { useSelectors } from 'Hooks';

import {
  user as userSelector,
  loaded as userLoadedSelector,
} from 'Redux/models/Auth';
import AppLayout from 'Views/layouts';

const Root = (): JSX.Element => {
  const { pathname } = useLocation();
  const [user, userLoaded] = useSelectors([userSelector, userLoadedSelector]);
  const isExcludedRoute = amplifyConfig.excludedPaths.includes(pathname);

  if (!isExcludedRoute && (!userLoaded || (userLoaded && !user))) {
    return <></>;
  }
  return (
    <AppLayout>
      <Switch>
        {routes.map((route, index) => {
          if (route.redirect) {
            return (
              <Redirect
                key={v1({ msecs: index })}
                exact={route.exact}
                to={route.to}
                from={route.from}
              />
            );
          }

          if (route.access && (user as User)?.group.includes(route.access)) {
            return (
              <Route
                key={v1({ msecs: index })}
                component={route.component}
                path={route.path}
                exact={route.exact}
              />
            );
          }

          return route.access ? null : (
            <Route
              key={v1({ msecs: index })}
              component={route.component}
              path={route.path}
              exact={route.exact}
            />
          );
        })}
      </Switch>
    </AppLayout>
  );
};

export default Root;
