import { useAsyncCallback } from 'react-async-hook';
import { useToasts } from 'react-toast-notifications';
import { user as userSelector } from 'Redux/models/Auth';
import { useSelector } from 'react-redux';
import { MonitoringChanges } from 'advanext-models/nectar/monitoring';

import { updateMonitoredChangeApi } from 'Api';

export const useUpdateMonitoredChange = (
  requestBody: Omit<Partial<MonitoringChanges>, 'modifiedBy'>,
): {
  updateMonitoredChange: () => void;
  isUpdating: boolean;
} => {
  const { addToast } = useToasts();
  const user = useSelector(userSelector);

  const updateMonitoredChange = useAsyncCallback(async () => {
    if (!user?.tenant) {
      throw new Error('tenant of user must be defined');
    }

    try {
      await updateMonitoredChangeApi(
        requestBody.monitoringChangesId as string,
        {
          ...requestBody,
          modifiedBy: user.id,
        },
      );

      addToast('Monitoring events updated', {
        appearance: 'success',
      });
    } catch (error) {
      // @ts-ignore
      addToast(error, {
        appearance: 'error',
      });
    }
  });
  return {
    updateMonitoredChange: updateMonitoredChange.execute,
    isUpdating: updateMonitoredChange.loading,
  };
};
