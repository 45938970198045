import { NectarEntity, EntityMetadata } from 'advanext-models/nectar/entity';
import { PaginatedResult } from 'advanext-models/advanext';
import { classToPlain, plainToClass } from 'class-transformer';

import {
  CreateEntityFileRequest,
  CreateEntityFileResponse,
  ListEntitiesRequest,
  ListFilteredEntitiesRequest,
  GetLatestEntityRequest,
} from 'advanext-models/nectar/entity/lambda';

import { apiService } from 'Services';

import {
  EntitiesPath,
  EntitiesFilteredPath,
  EntityPath,
  EntityFiles,
  EntityLatest,
} from 'Constants';

export const createEntityApi = async <T = unknown>(
  entity: NectarEntity<T>,
): Promise<NectarEntity<T>> => {
  const { data } = await apiService.post(EntitiesPath, {
    data: classToPlain(entity),
  });
  return plainToClass(NectarEntity, data) as unknown as NectarEntity<T>;
};

export const createEntityFileApi = async (
  request: CreateEntityFileRequest,
): Promise<CreateEntityFileResponse> => {
  const { data } = await apiService.post<CreateEntityFileResponse>(
    EntityFiles,
    { data: request },
  );
  return data;
};

export const deleteEntityApi = async (entityId: string): Promise<void> => {
  await apiService.delete(EntityPath.replace(/:id/g, entityId));
};

export const updateEntityMetadata = async <T = unknown>(
  entityId: string,
  request: EntityMetadata,
): Promise<NectarEntity<T>> => {
  const { data } = await apiService.patch(
    EntityPath.replace(/:id/g, entityId),
    { data: classToPlain(request) },
  );
  return plainToClass(NectarEntity, data) as unknown as NectarEntity<T>;
};

export const getEntities = async <T = unknown>(
  request: ListEntitiesRequest,
): Promise<PaginatedResult<NectarEntity<T>>> => {
  const { data } = await apiService.get<PaginatedResult<unknown>>(
    EntitiesPath,
    {
      params: {
        ...request,
        compoundCompanyId: request.compoundCompanyId?.toString(),
        compoundTenantId: request.compoundTenantId.toString(),
        offset: request.offset
          ? new Date(request.offset).toISOString()
          : undefined,
      },
    },
  );

  return {
    ...data,
    items: data.items.map(
      (item) => plainToClass(NectarEntity, item) as unknown as NectarEntity<T>,
    ),
  };
};

export const getFilteredEntities = async <T = unknown>(
  request: ListFilteredEntitiesRequest,
): Promise<PaginatedResult<NectarEntity<T>>> => {
  const { data } = await apiService.post<PaginatedResult<unknown>>(
    EntitiesFilteredPath,
    {
      data: {
        ...request,
        compoundCompanyId: request.compoundCompanyId?.toString(),
        compoundTenantId: request.compoundTenantId.toString(),
      },
    },
  );

  return {
    ...data,
    items: data.items.map(
      (item) => plainToClass(NectarEntity, item) as unknown as NectarEntity<T>,
    ),
  };
};

export const getLatestEntity = async <T = unknown>(
  request: GetLatestEntityRequest,
): Promise<NectarEntity<T> | undefined> => {
  const { data } = await apiService.get<unknown | undefined>(EntityLatest, {
    params: {
      ...request,
      compoundCompanyId: request.compoundCompanyId.toString(),
      compoundTenantId: request.compoundTenantId.toString(),
      offset: request.offset
        ? new Date(request.offset).toISOString()
        : undefined,
    },
  });

  return data
    ? (plainToClass(NectarEntity, data) as unknown as NectarEntity<T>)
    : undefined;
};
