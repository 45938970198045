import React from 'react';
import { Box, Link } from '@material-ui/core';
import type { Column } from 'advanext-components';
import { CompanyPosition } from 'Types';
import { TasksStatusIcon } from '../../Company/components';
import { Config } from 'advanext-components/components/Table';
type Props = {
  onOpenCompany: (id?: string) => void;
};
const columns = ({ onOpenCompany }: Props): Array<Column<CompanyPosition>> => [
  {
    sortable: true,
    filterable: true,
    accessor: 'name',
    Header: 'Company',
    // eslint-disable-next-line
    Cell: ({ value, row }: Config<CompanyPosition>): JSX.Element => (
      <>
        <Box mr={0.5} display="flex">
          <TasksStatusIcon
            isCompleted={row?.isCompleted ?? false}
            isMonitored
          />
          <Link underline="always" onClick={(): void => onOpenCompany(row?.id)}>
            {value}
          </Link>
        </Box>
      </>
    ),
    style: {
      width: '50%',
    },
  },
  {
    sortable: true,
    accessor: 'id',
    Header: 'UID',
    style: {
      width: '50%',
    },
  },
];

export default columns;
