import { EMPTY_STRING_DISPLAY_VALUE } from 'Utilities';

export const getSignatoryRights = (value = ''): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case 'NO_SIGNATORY_RIGHTS':
      return 'None';
    case 'LIMITED_SIGNATORY_RIGHTS':
      return 'Joint';
    case 'FULL_SIGNATORY_RIGHTS':
      return 'Single';
    case 'LIMITED_PROCURATION_RIGHTS':
      return 'Joint Procuration';
    case 'FULL_PROCURATION_RIGHTS':
      return 'Single Procuration';
    default:
      return value;
  }
};
