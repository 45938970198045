import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { StyledCard } from 'advanext-components';
import { BankStatementObject } from 'Types';
import { formatDate, getValueOrNA } from 'Utilities';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '15px',
    },
  },
  bankButton: {
    width: '60px',
    height: '30px',
    padding: 0,
    backgroundColor: '#c4c4c4',
    border: '1px solid #262B32',
    fontSize: '18px',
    fontWeight: 400,
    textTransform: 'none',
  },
}));

type Props = {
  data: BankStatementObject;
  onClose: () => void;
};

const BankStatementDetails = ({ data, onClose }: Props): JSX.Element => {
  const classes = useStyles();
  const counterpartiesCount = Object.entries(data).filter(
    ([id, value]) => new RegExp('counterparty_\\d').test(id) && value,
  ).length;
  console.log(
    Object.entries(data).filter(
      ([id, value]) => new RegExp('counterparty_\\d').test(id) && value,
    ),
  );
  console.log(counterpartiesCount);
  return (
    <div className={classes.wrapper}>
      <Button className={classes.bankButton} onClick={(): void => onClose()}>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <StyledCard title="Bank Statement Details">
        <Table>
          <colgroup>
            <col style={{ width: '34%' }} />
            <col style={{ width: '66%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Bank</TableCell>
              <TableCell>{getValueOrNA(data.bank)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IBAN</TableCell>
              <TableCell>{getValueOrNA(data.iban)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Period from</TableCell>
              <TableCell>{formatDate(data.period_start)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Period to</TableCell>
              <TableCell>{formatDate(data.period_end)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell>{getValueOrNA(data.currency)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <StyledCard title="Monthly Cash flows">
        <Table>
          <colgroup>
            <col style={{ width: '34%' }} />
            <col style={{ width: '22%' }} />
            <col style={{ width: '22%' }} />
            <col style={{ width: '22%' }} />
          </colgroup>
          <TableHead>
            <TableCell>Period</TableCell>
            <TableCell>Amount incoming</TableCell>
            <TableCell>Amount outgoing</TableCell>
            <TableCell>Net cash flows</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Month 1</TableCell>
              <TableCell>{data.amount_incoming_month_1}</TableCell>
              <TableCell>{data.amount_outgoing_month_1}</TableCell>
              <TableCell>{data.net_cash_flow_month_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Month 2</TableCell>
              <TableCell>{data.amount_incoming_month_2}</TableCell>
              <TableCell>{data.amount_outgoing_month_2}</TableCell>
              <TableCell>{data.net_cash_flow_month_2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Month 3</TableCell>
              <TableCell>{data.amount_incoming_month_3}</TableCell>
              <TableCell>{data.amount_outgoing_month_3}</TableCell>
              <TableCell>{data.net_cash_flow_month_3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>{data.amount_incoming_total}</TableCell>
              <TableCell>{data.amount_outgoing_total}</TableCell>
              <TableCell>{data.net_cash_flow_total}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Average</TableCell>
              <TableCell>{data.amount_incoming_monthly_average}</TableCell>
              <TableCell>{data.amount_outgoing_monthly_average}</TableCell>
              <TableCell>{data.net_cash_flow_monthly_average}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <StyledCard title="Cash flows Summary">
        <Table>
          <colgroup>
            <col style={{ width: '34%' }} />
            <col style={{ width: '22%' }} />
            <col style={{ width: '22%' }} />
            <col style={{ width: '22%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Approx. Annualized Total inflows</TableCell>
              <TableCell>{data.approx_annualized_total_inflows}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Approx. Annualized Sales</TableCell>
              <TableCell>{data.approx_annualized_sales}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Approx. Annualized B2B Inflows</TableCell>
              <TableCell>{data.approx_annualized_b2b_inflows}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Saldo - beginning first month</TableCell>
              <TableCell>{data.saldo_beggining_first_month}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Saldo - end last month</TableCell>
              <TableCell>{data.saldo_end_last_month}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Herfindahl Index: all transactions</TableCell>
              <TableCell>{data.herfindahl_index_all_transactions}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Herfindahl Index: B2B Sales only</TableCell>
              <TableCell>{data.herfindahl_index_b2b_sales_only}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                B2B inflows (excl. transactions from company itself)
              </TableCell>
              <TableCell>{data.b2b_inflows_share}</TableCell>
              <TableCell>{data.b2b_inflows_sum}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                Sales inflows (excl. transactions from company itself)
              </TableCell>
              <TableCell>{data.sales_inflows_share}</TableCell>
              <TableCell>{data.sales_inflows_sum}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
      <StyledCard title="Largest counterparties">
        <Table>
          <colgroup>
            <col style={{ width: '34%' }} />
            <col style={{ width: '22%' }} />
            <col style={{ width: '22%' }} />
          </colgroup>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>Share</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableHead>
          <TableBody>
            {new Array(counterpartiesCount).fill(0).map((_, index) => (
              <TableRow key={`counterparty_${index + 1}`}>
                <TableCell>{data[`counterparty_${index + 1}`]}</TableCell>
                <TableCell>{data[`counterparty_share_${index + 1}`]}</TableCell>
                <TableCell>
                  {data[`counterparty_amount_${index + 1}`]}
                </TableCell>
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledCard>
    </div>
  );
};

export default BankStatementDetails;
