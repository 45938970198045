import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { StyledCard } from 'advanext-components';
import { Person } from 'advanext-models/nectar/management';

import { Order, PersonPosition, SortOrder } from 'Types';
import { getComparator, formatDate } from 'Utilities';
import { getSignatoryRights } from '../helpers';
import { headCells } from './constants';

type Props = {
  managers: Array<Person>;
  title: string;
};

const ManagersTable = ({ managers, title }: Props): JSX.Element => {
  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof PersonPosition>();

  const handleRequestSort = (property: keyof PersonPosition): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const entries = useMemo(() => {
    const arr: PersonPosition[] = [];
    managers.map(({ positions, name, birthLocation }, i1) =>
      positions.map((p, i2) =>
        arr.push({ id: `${i1}-${i2}`, name, birthLocation, ...p }),
      ),
    );
    return arr;
  }, [managers]);

  return (
    <StyledCard title={title}>
      {managers.length === 0 ? (
        <div>No management information available</div>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, label }) => (
                <TableCell
                  component="th"
                  key={id}
                  sortDirection={orderBy === id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : Order.ASC}
                    onClick={(): void => {
                      handleRequestSort(id);
                    }}
                    IconComponent={ArrowDropDown}
                  >
                    <strong>{label}</strong>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries
              .sort(
                orderBy
                  ? getComparator<PersonPosition>(orderBy, order)
                  : (): SortOrder => 0,
              )
              .map(
                ({
                  id,
                  name,
                  birthLocation,
                  signatoryRights,
                  startDate,
                  endDate,
                }) => (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{birthLocation}</TableCell>
                    <TableCell>{getSignatoryRights(signatoryRights)}</TableCell>
                    <TableCell>{formatDate(startDate)}</TableCell>
                    <TableCell>{formatDate(endDate)}</TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      )}
    </StyledCard>
  );
};

export default ManagersTable;
