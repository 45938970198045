import Type from 'advanext-models/nectar/uwtask/Type';

export const SHOW_ALL_STATUS = 'All Statuses';

export const TASK_TYPE_SORT_ORDER = [
  Type.OnboardingCheck,
  Type.Identification,
  Type.ComplianceForm,
  Type.IbanProvided,
  Type.FinancialStatement,
  Type.BankStatement,
  Type.OtherDocuments,
  Type.DebtCollection,
  Type.ManualReview,
];
