import useSWR from 'swr';
import type { Tenant } from 'advanext-models/nectar/tenant';

import { fetcher } from 'Api';
import { TenantsPath } from 'Constants';
import { ApiEntity } from 'Types';

export const useTenants = (name = ''): ApiEntity<Array<Tenant>> => {
  const stringified = JSON.stringify({ query: name });
  const { data, error } = useSWR([TenantsPath, stringified], fetcher);

  return {
    data,
    isLoading: !error && !data,
    error,
  } as ApiEntity<Array<Tenant>>;
};
