import { User } from 'advanext-models/nectar';
import { plainToClass } from 'class-transformer';

/* eslint-disable */
export const translateCognitoUser = (user: any): User =>
  plainToClass(User, {
    id: user?.attributes?.sub,
    userId: user?.attributes?.sub,
    username: user?.username,
    email:
      user?.attributes?.email ?? user?.challengeParam?.userAttributes.email,
    tenant:
      user?.attributes?.['custom:tenant'] ??
      user?.challengeParam?.userAttributes['custom:tenant'],
    group: (
      user?.attributes?.['custom:group']?.trim() ??
      user?.challengeParam?.userAttributes['custom:group']
    ).split(','),
    firstName:
      user?.attributes?.given_name ??
      user?.challengeParam?.userAttributes.given_name,
    lastName:
      user?.attributes?.family_name ??
      user?.challengeParam?.userAttributes.family_name,
    phoneNumber:
      user?.attributes?.phone_number ??
      user?.challengeParam?.userAttributes.phone_number,
  });

export default {
  translateCognitoUser,
};
