import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ERV } from './typeUtils';

/*
 * A little bit hacky way to add support to up to 10 selectors.
 * It turned out that in Typescript it's not that easy to extract types
 * from functions wrapped in arrays.
 */

type Selector = (args: any) => any; // eslint-disable-line

type S1 = [Selector];
type S2 = [Selector, Selector];
type S3 = [Selector, Selector, Selector];
type S4 = [Selector, Selector, Selector, Selector];
type S5 = [Selector, Selector, Selector, Selector, Selector];
type S6 = [Selector, Selector, Selector, Selector, Selector, Selector];
type S7 = [
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
];
type S8 = [
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
];
type S9 = [
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
];
type S10 = [
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
  Selector,
];

export function useSelectors<T extends S1>(selectors: T): [ERV<T[0]>];
export function useSelectors<T extends S2>(
  selectors: T,
): [ERV<T[0]>, ERV<T[1]>];
export function useSelectors<T extends S3>(
  selectors: T,
): [ERV<T[0]>, ERV<T[1]>, ERV<T[2]>];
export function useSelectors<T extends S4>(
  selectors: T,
): [ERV<T[0]>, ERV<T[1]>, ERV<T[2]>, ERV<T[3]>];
export function useSelectors<T extends S5>(
  selectors: T,
): [ERV<T[0]>, ERV<T[1]>, ERV<T[2]>, ERV<T[3]>, ERV<T[4]>];
export function useSelectors<T extends S6>(
  selectors: T,
): [ERV<T[0]>, ERV<T[1]>, ERV<T[2]>, ERV<T[3]>, ERV<T[4]>, ERV<T[5]>];
export function useSelectors<T extends S7>(
  selectors: T,
): [
  ERV<T[0]>,
  ERV<T[1]>,
  ERV<T[2]>,
  ERV<T[3]>,
  ERV<T[4]>,
  ERV<T[5]>,
  ERV<T[6]>,
];
export function useSelectors<T extends S8>(
  selectors: T,
): [
  ERV<T[0]>,
  ERV<T[1]>,
  ERV<T[2]>,
  ERV<T[3]>,
  ERV<T[4]>,
  ERV<T[5]>,
  ERV<T[6]>,
  ERV<T[7]>,
];
export function useSelectors<T extends S9>(
  selectors: T,
): [
  ERV<T[0]>,
  ERV<T[1]>,
  ERV<T[2]>,
  ERV<T[3]>,
  ERV<T[4]>,
  ERV<T[5]>,
  ERV<T[6]>,
  ERV<T[7]>,
  ERV<T[8]>,
];
export function useSelectors<T extends S10>(
  selectors: T,
): [
  ERV<T[0]>,
  ERV<T[1]>,
  ERV<T[2]>,
  ERV<T[3]>,
  ERV<T[4]>,
  ERV<T[5]>,
  ERV<T[6]>,
  ERV<T[7]>,
  ERV<T[8]>,
  ERV<T[9]>,
];

// DO NOT use default export here, otherwise overrides above will be lost

/**
 * The function intended to reduce the amount of boilerplate code when connecting
 * components to Redux store. Resolved values are automatically inheriting types
 * from corresponding selectors.
 *
 * Under the hood Reselct's createStructuredSelector() is being used to construct
 * a proper structure of selectors, to avoid subscribing components to the whole store.
 */
// eslint-disable-next-line
export function useSelectors(selectors: any[]): any[] {
  const selectorsObject = selectors.reduce(
    (memo, selector, index) => ({ ...memo, [index]: selector }),
    {},
  );

  const structuredSelector = createStructuredSelector(selectorsObject);
  const results: any = useSelector(structuredSelector); // eslint-disable-line
  const resultsArray = selectors.map((value, index) => results[index]);

  return resultsArray;
}
