import { plainToClass } from 'class-transformer';
import { File } from 'advanext-models/advanext/document';

import { apiService } from 'Services';
import { DocumentPath } from 'Constants';

// do not use apiService for upload - authorization is not needed
export const putFile = async (
  url: string,
  body: BodyInit,
  contentType: string,
): Promise<Response> =>
  fetch(url, {
    method: 'PUT',
    body,
    headers: {
      'Content-Type': contentType,
    },
  });

export const downloadFile = async (fileId: string): Promise<Blob> => {
  const { data } = await apiService.get<{ getUrl: string }>(
    DocumentPath.replace(':id', fileId),
  );
  const response = await fetch(data.getUrl, {
    method: 'GET',
    headers: {
      responseType: 'blob',
    },
  });

  return response.blob();
};

export const moveFileApi = async (
  fileId: string,
  path: string,
): Promise<File> => {
  const { data } = await apiService.put<unknown>(
    DocumentPath.replace(':id', fileId),
    { data: { path } },
  );
  return plainToClass(File, data);
};
