import { Translations } from 'Constants';
import { OptionType } from 'Types';

export const NO_DATA_OPTION = {
  label: 'No data available',
  value: 'NO_DATA',
};

export const NOT_APPLICABLE = {
  label: 'Not Applicable',
  value: 'NOT_APPLICABLE',
};

export const BOOLEAN_OPTIONS = [
  {
    label: 'Yes',
    value: 'TRUE',
  },
  {
    label: 'No',
    value: 'FALSE',
  },
];

export const getOptions = (values?: Array<string> | null): Array<OptionType> =>
  values?.map((value) => ({
    label: Translations[value] ?? value,
    value,
  })) ?? [];
