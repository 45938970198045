import React, { useState } from 'react';
import {
  ButtonBase,
  Grid,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useModels, useSelectors } from 'Hooks';
import { user as userSelector } from 'Redux/models/Auth';

/* eslint-disable */

const useStyles = makeStyles((theme) => ({
  profileEmail: {
    color: theme.palette.text.hint,
  },
  profileButton: {},
}));

const ProfileDropdown = () => {
  const classes = useStyles();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const { Auth } = useModels();
  const [user] = useSelectors([userSelector]);

  const profileMenuOpen = Boolean(profileAnchorEl);

  const handleClick = (event: any) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <>
      <ButtonBase className={classes.profileButton} onClick={handleClick}>
        <Grid alignItems="center" container direction="row" spacing={1}>
          <Grid item>
            <Avatar alt={fullName} />
          </Grid>
          <Grid item>
            <Typography align="center" color="textSecondary">
              {fullName}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.profileEmail}
            >
              {user?.email}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>

      <Menu
        open={profileMenuOpen}
        anchorEl={profileAnchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={Auth.signOut}>Log Out</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileDropdown;
