export const ROUTES = {
  Dashboard: '/',
  NotFound: '/not-found',

  OwnerTenants: '/tenants',
  OwnerTenant: '/tenant/:tenantId',
  OwnerAccountApplications: '/account/applications',
  OwnerAccountApplication: '/account/applications/:applicationId',

  Companies: '/companies',
  Company: '/company/:companyId',
  Monitoring: '/monitoring',
  Tasks: '/tasks',

  AdminAccountMembers: '/account/members',
};
