import { createSelector } from 'reselect';
import { User } from 'advanext-models/nectar';

import { RootState } from 'Redux/store';

/* eslint-disable */

const STATE = (state: RootState) => state.Auth;

export const user: (state: RootState) => User | null = createSelector(
  STATE,
  (state) => state.user,
);
export const error: (state: RootState) => Error | null = createSelector(
  STATE,
  (state) => state.error,
);
export const loading: (state: RootState) => boolean = createSelector(
  STATE,
  (state) => state.loading,
);
export const loaded: (state: RootState) => boolean = createSelector(
  STATE,
  (state) => state.loaded,
);
