import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Accordion,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { StyledCard } from 'advanext-components';
import { Order, CreditReportPosition, SortOrder } from 'Types';
import { getComparator, formatDateTime } from 'Utilities';
import { headCells } from './constants';

type Props = {
  title: string;
  entries: Array<CreditReportPosition>;
};

const CreditReportTable = ({ title, entries }: Props): JSX.Element => {
  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof CreditReportPosition>();

  const handleRequestSort = (property: keyof CreditReportPosition): void => {
    const isAsc = orderBy === property && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  return (
    <Accordion defaultExpanded>
      <StyledCard title={title}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, label }) =>
                id === 'value' ? (
                  <TableCell component="th" key={id} width="33%">
                    <strong>{label}</strong>
                  </TableCell>
                ) : (
                  <TableCell
                    component="th"
                    key={id}
                    sortDirection={orderBy === id ? order : false}
                    width="33%"
                  >
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : Order.ASC}
                      onClick={(): void => {
                        handleRequestSort(id);
                      }}
                      IconComponent={ArrowDropDown}
                    >
                      <strong>{label}</strong>
                    </TableSortLabel>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries
              .sort(
                orderBy
                  ? getComparator<CreditReportPosition>(orderBy, order)
                  : (): SortOrder => 0,
              )
              .map(({ property, value, update }, index) => (
                <TableRow key={index}>
                  <TableCell>{property}</TableCell>
                  <TableCell>{value}</TableCell>
                  <TableCell>{formatDateTime(update)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledCard>
    </Accordion>
  );
};

export default CreditReportTable;
