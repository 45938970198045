import dayjs from 'dayjs';
import { NectarReport } from 'advanext-models/nectar/report';
import { ClaimType } from 'advanext-models/nectar/entry';
import {
  AnswerType,
  ResultResponse,
  Blueprint,
} from 'advanext-models/nectar/astrea';
import { OtherMandates } from 'Types';
import { convertBooleanToString } from './common';
import { NO_DATA_OPTION } from './select';

type Props = {
  preFillValue?: string | null;
  company?: NectarReport;
  otherMandates?: OtherMandates;
};

type ReturnType = string | number | (string | undefined)[] | undefined;

const populateBlueprintWithData = ({
  preFillValue,
  company,
  otherMandates,
}: Props): ReturnType => {
  switch (preFillValue) {
    case 'COMPANY_NAME':
      return company?.companyData?.name.consolidated;
    case 'COMPANY_COUNTRY':
      return company?.companyData?.country;
    case 'PREFINANCING_CREDIT_LIMIT':
      // MODEL AND DATA SHOULD BE ADDED
      // eslint-disable-next-line
      return (
        company?.companyData as any
      )?.loadedSeller?.prefinancingCreditLimit?.toString();
    case 'FACTORING_CREDIT_LIMIT':
      // MODEL AND DATA SHOULD BE ADDED
      // eslint-disable-next-line
      return (
        company?.companyData as any
      )?.loadedSeller?.factoringCreditLimit?.toString();
    case 'REPAYMENT_SCORE':
      return NO_DATA_OPTION.value;
    case 'FINANCED_AMOUNT':
      // MODEL AND DATA SHOULD BE ADDED
      // eslint-disable-next-line
      return (company as any)?.invoices?.financedAmount?.toString();
    case 'ACCOUNT_HOLDER_NAME':
      // MODEL AND DATA SHOULD BE ADDED
      // eslint-disable-next-line
      return (company?.companyData as any)?.loadedSeller?.fullName;
    case 'SIGNATORY_RIGHTS':
      // SHOULD BE ADDED
      return undefined;
    case 'ACCOUNT_EMAIL':
      // MODEL AND DATA SHOULD BE ADDED
      // eslint-disable-next-line
      return (company?.companyData as any)?.loadedSeller?.email;
    case 'ACCOUNT_HOLDER_NATIONALITY':
      return (
        // MODEL AND DATA SHOULD BE ADDED
        // eslint-disable-next-line
        (company?.companyData as any)?.loadedSeller?.nationality ??
        NO_DATA_OPTION.value
      );
    case 'FOUNDING_DATE':
      return company?.companyData?.foundingDate?.consolidated
        ? dayjs(company.companyData.foundingDate.consolidated).format(
            'YYYY-MM-DD',
          )
        : undefined;
    case 'INDUSTRY':
      return company?.industry?.consolidated?.name;
    case 'LEGAL_FORM':
      return company?.companyData?.legalForm?.consolidated?.canonicalized;
    case 'EMPLOYEES':
      return (
        company?.companyData?.employees?.consolidated ?? NO_DATA_OPTION.value
      );
    case 'HAS_AUDITED_DUTY':
      return convertBooleanToString(
        company?.countrySpecific?.crifSpecific?.auditRequired === 'IS_PRESENT',
      );
    case 'BELASTET':
      return convertBooleanToString(
        company?.creditReport?.belastet?.consolidated,
      );
    case 'CRIF_RATING':
      return company?.creditReport?.rating?.CRIF;
    case 'BISNODE_RATING':
      return company?.creditReport?.rating?.BISNODE ?? NO_DATA_OPTION.value;
    case 'CREDITREFORM_RATING':
      return company?.creditReport?.rating?.CREDITREFORM;
    case 'SCHUFA_RATING':
      return company?.creditReport?.rating?.SCHUFA;
    case 'BISNODE_PAYDEX_SCORE':
      return (
        company?.creditReport?.repaymentIndex?.BISNODE?.toString() ??
        NO_DATA_OPTION.value
      );
    case 'CRIF_AVERAGE_DELAY_DAYS':
      return (
        company?.countrySpecific?.crifSpecific?.avgDelayShortTerm?.toString() ??
        NO_DATA_OPTION.value
      );
    case 'WEBSITE':
      return (
        company?.companyContact?.websites?.consolidated ?? NO_DATA_OPTION.value
      );
    case 'BUSINESS_PURPOSE':
      return company?.companyData?.purpose?.consolidated;
    case 'NAME_CHANGED_2_YEARS':
      return convertBooleanToString(
        !!company?.historicData?.previousNames?.consolidated?.filter((name) =>
          name.dateChanged ? dayjs().diff(name.dateChanged, 'y') < 2 : true,
        )?.length,
      );
    case 'PREVIOUS_NAMES':
      return (
        company?.historicData?.previousNames?.consolidated?.map(
          (data) => data.name,
        ) ?? []
      );
    case 'ADDRESS_CHANGED_2_YEARS':
      return convertBooleanToString(
        !!company?.historicData?.previousAddresses?.consolidated?.filter(
          (address) =>
            address.dateChanged
              ? dayjs().diff(address.dateChanged, 'y') < 2
              : true,
        )?.length,
        NO_DATA_OPTION.value,
      );
    case 'PURPOSE_CHANGED_2_YEARS':
      return convertBooleanToString(
        !!company?.historicData?.previousPurposes?.consolidated?.filter(
          (purpose) =>
            purpose.dateChanged
              ? dayjs().diff(purpose.dateChanged, 'y') < 2
              : true,
        )?.length,
      );
    case 'MANAGERS_COUNT':
      return company?.managers?.consolidated?.persons
        ?.filter((person) => person.currentPosition?.active)
        .length?.toString();
    case 'SIGNATORY_RIGHTS_CHANGES_COUNT':
      return company?.managers?.consolidated?.signatoryRightChanges?.toString();
    case 'FIVE_MANDATES_ON_SINGLE_MANAGER': {
      return convertBooleanToString(
        otherMandates
          ? !!Object.values(otherMandates).find(
              (mandates) =>
                mandates.filter((mandate) => mandate.active).length >= 5,
            )
          : undefined,
      );
    }
    case 'PREVIOUS_COMPANIES_BANKRUPTCY_NEAR_MANAGER_COUNT':
      return otherMandates
        ? Object.values(otherMandates)
            .flat()
            .filter((mandate) => mandate.bankrupt)
            .length?.toString()
        : undefined;
    case 'COLLECTION_REPORT_DATE': {
      const latestCollectionDate =
        company?.creditReport?.claims?.consolidated?.claimEntries
          ?.filter((claim) => claim.claimType === ClaimType.COLLECTION)
          .sort((a, b) =>
            dayjs(a.dateOpen).isAfter(dayjs(b.dateOpen)) ? -1 : 1,
          )[0]?.dateOpen;
      return latestCollectionDate
        ? dayjs(latestCollectionDate).format('YYYY-MM-DD')
        : undefined;
    }
    case 'SUM_OPEN_COLLECTIONS':
      return company?.creditReport?.claims?.consolidated?.sumOfOpenAmount
        ?.amount;
    case 'SUM_TOTAL_COLLECTIONS':
      return company?.creditReport?.claims?.consolidated?.sumOfClaims?.amount;
    default:
      return undefined;
  }
};

export const handleBlueprintValues = (
  blueprint?: Blueprint,
  company?: NectarReport,
  otherMandates?: OtherMandates,
): Record<string, string | string[] | undefined> | undefined =>
  blueprint?.sections.reduce((allValues, section) => {
    const sectionValues = section.questions.reduce(
      (values, { id, preFillValue }) => ({
        [id]: populateBlueprintWithData({
          preFillValue,
          company,
          otherMandates,
        }),
        ...values,
      }),
      {},
    );
    return {
      ...allValues,
      ...sectionValues,
    };
  }, {});

export const handleResultValues = (result?: ResultResponse): {} | undefined =>
  result?.sections.reduce((allValues, section) => {
    const sectionValues = section.questions.reduce(
      (values, { id, answer, answerType }) => ({
        [id]:
          answerType === AnswerType.LIST_STRING
            ? JSON.parse(answer ?? '[]')
            : answer,
        ...values,
      }),
      {},
    );
    return {
      ...allValues,
      ...sectionValues,
    };
  }, {});
