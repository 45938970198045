import { UWTask } from 'advanext-models/nectar/uwtask';
import Status from 'advanext-models/nectar/uwtask/Status';
import Type from 'advanext-models/nectar/uwtask/Type';
import { EMPTY_STRING_DISPLAY_VALUE } from './common';

export const sortUWTasksByType = (tasks: Array<UWTask>): Array<UWTask> => {
  const sortingArray = Object.values(Type);
  return (tasks || []).sort(
    (a, b) =>
      sortingArray.indexOf(a.uwTaskType) - sortingArray.indexOf(b.uwTaskType),
  );
};

export const getTaskType = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case Type.OnboardingCheck:
      return 'ONBOARDING CHECK';
    case Type.Identification:
      return 'IDENTIFICATION';
    case Type.ComplianceForm:
      return 'COMPLIANCE FORM';
    case Type.IbanProvided:
      return 'IBAN PROVIDED';
    case Type.FinancialStatement:
      return 'FINANCIAL STATEMENT';
    case Type.BankStatement:
      return 'BANK STATEMENT';
    case Type.OtherDocuments:
      return 'OTHER DOCUMENTS';
    case Type.DebtCollection:
      return 'DEBT COLLECTION';
    case Type.ManualReview:
      return 'MANUAL REVIEW';
    default:
      return value;
  }
};

export const getTaskStatus = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case Status.NotRequired:
      return 'Not Required';
    case Status.Required:
      return 'Required';
    case Status.Declined:
      return 'Declined';
    case Status.ToReview:
      return 'To Review';
    case Status.Completed:
      return 'Completed';
    case Status.PartiallyApproved:
      return 'Partially Approved';
    case Status.Incomplete:
      return 'Incomplete';
    default:
      return value;
  }
};

export const taskStatuses = Object.values(Type);

export const taskStatusesOptions = Object.values(Status).map((value) => ({
  label: getTaskStatus(value),
  value,
}));

export const taskTypesOptions = Object.values(Type).map((value) => ({
  label: getTaskType(value),
  value,
}));
