export const CompaniesSearchPath = 'companies';
export const CompanyPath = 'companies/:id/nectar-report';
export const CompanyMandatesPath = 'companies/:id/other-mandates';
export const CompanyEntryUpdatePath = 'companies/:id/entry/update';
export const DataSourcePath = 'companies/:id/data-source-metadata';
export const DataSourceDocumentPath = '/companies/:companyId/pdf-report';
export const BankStatementPath = 'companies/:id/bank-statements';
export const TenantsPath = 'tenants';
export const TenantPath = 'tenants/:id';
export const TenantApplicationsPath = 'tenants/:id/applications';
export const TenantApplicationEventsPath =
  'tenants/:tenantId/applications/:applicationId/events';
export const TenantMembersPath = 'tenants/:id/members';
export const BlueprintPath = 'astrea/blueprint';
export const ScoreResultPath = 'astrea/result';
export const MonitoringPath = 'monitoring/companies';
export const MonitoringUpdatePath = 'monitoring/companies/:compoundCompanyId';
export const GetMonitoringChangesPath = 'monitoring/changes';
export const ModifyMonitoringChangesPath = 'monitoring/changes/:id';
export const MonitoringChangesHistoryPath = 'monitoring/changes/:id/history';
export const CompaniesInfoPath = 'companies/info';
export const UWTasksPath = 'uw-tasks';
export const UWTaskPath = 'uw-tasks/:id';
export const LoansPath = 'loans';
export const DocumentPath = 'documents/:id';
export const EntityPath = 'entities/:id';
export const EntitiesPath = 'entities';
export const EntitiesFilteredPath = 'entities/filtered';
export const EntityFiles = 'entities/files';
export const EntityLatest = 'entities/latest';
export const SheetsProcess = 'sheets/process';
export const OrderCollectionPath = 'crif';
export const GetCollectionsPath = 'crif/:id';
