import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Box,
  makeStyles,
} from '@material-ui/core';
import { CompanyBankAccount } from 'advanext-models/advanext/company';
import { StyledCard } from 'advanext-components';
import { cells } from './constants';

const useStyles = makeStyles({
  noData: {
    textAlign: 'center',
    margin: '8px auto',
    fontSize: '16px',
    color: 'grey',
  },
});

type Props = {
  isLoading: boolean;
  bankAccounts?: CompanyBankAccount[];
};

const Banking = ({ isLoading, bankAccounts }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <StyledCard title="Banking">
      {isLoading ? (
        <CircularProgress size={40} />
      ) : (
        <>
          {!bankAccounts?.length ? (
            <Box className={classes.noData}>Nothing to show</Box>
          ) : (
            <Table>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
              <TableBody>
                {cells.map(({ id, label, render }) => (
                  <TableRow key={id}>
                    <TableCell>{label}</TableCell>
                    {bankAccounts?.map((e: CompanyBankAccount, index) => (
                      <TableCell>{render(e, index)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </StyledCard>
  );
};

export default Banking;
