import { CompoundCompanyId } from 'advanext-models/advanext/company';
import { CollectionOrder } from 'advanext-models/nectar/crif';
import { OrderCollectionRequest } from 'advanext-models/nectar/crif/lambda';

import { OrderCollectionPath } from 'Constants';
import { apiService } from 'Services';

export const orderCollectionsApi = async (
  groupId: CompoundCompanyId | string,
  contractDocumentId: string,
): Promise<CollectionOrder> => {
  const { data } = await apiService.post<CollectionOrder>(OrderCollectionPath, {
    data: {
      groupId: groupId.toString(),
      contractDocumentId,
    } as OrderCollectionRequest,
  });

  return data;
};
