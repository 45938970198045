import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import { StyledCard } from 'advanext-components';
import { getValueOrNA, formatNumber } from 'Utilities';
import { CompanySurvey } from 'advanext-models/advanext/company';

type Props = {
  isLoading: boolean;
  survey?: CompanySurvey;
};

const Survey = ({ isLoading, survey }: Props): JSX.Element => (
  <StyledCard title="Survey">
    {isLoading ? (
      <CircularProgress size={40} />
    ) : (
      <Table>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell>Needs Currency</TableCell>
            <TableCell>{getValueOrNA(survey?.needsCurrency)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Needs Amount</TableCell>
            <TableCell>{formatNumber(survey?.needsAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer Size</TableCell>
            <TableCell>
              {getValueOrNA(survey?.customersSize?.join(','))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Geographic Focus</TableCell>
            <TableCell>{getValueOrNA(survey?.geographicFocus)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Yearly Turnover</TableCell>
            <TableCell>{getValueOrNA(survey?.yearlyTurnover)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Website</TableCell>
            <TableCell>{getValueOrNA(survey?.website)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Intended purpose</TableCell>
            <TableCell>{getValueOrNA(survey?.intendedPurpose)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{getValueOrNA(survey?.description)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )}
  </StyledCard>
);

export default Survey;
