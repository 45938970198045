import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#0098EC',
      main: '#0098EC',
      dark: '#0098EC',
    },
    text: {
      primary: '#171C23',
      secondary: '#7C7E7F',
      disabled: '#8C8E91',
      hint: '#65696D',
    },
    background: {
      default: '#F6F6F6',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '8px 0px',
      },
    },
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
      },
    },
    MuiTableSortLabel: {
      icon: {
        marginLeft: '0px',
        fontSize: '24px',
      },
    },
  },
});

export default theme;
