import React from 'react';
import {
  Table as MaterialTable,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Card,
  makeStyles,
  Box,
} from '@material-ui/core';
import { LoadingAlert, ErrorAlert } from 'advanext-components';
import { useUWTasks } from 'Hooks';
import { TasksStatusIcon } from '../components';
import columns from './columns.json';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
  reviewRow: {
    backgroundColor: '#DADADA',
  },
  cellSpacing: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: 'none',
  },
}));

type Props = {
  isMonitored: boolean;
  companyId?: string;
};

const Table = ({ isMonitored, companyId }: Props): JSX.Element => {
  const classes = useStyles();
  const isLoading = false;
  const error = null;

  const { data: uwTasks, onUpdateRow, isCompleted } = useUWTasks(companyId);

  return (
    <Card>
      {isLoading && uwTasks ? (
        <LoadingAlert text="Underwriting tasks are loading, please wait" />
      ) : error ? (
        <ErrorAlert text="Failed to load underwriting tasks" />
      ) : (
        <>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map(
                  ({
                    id,
                    label,
                    style,
                  }: {
                    id: string;
                    label: string;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    style?: any;
                  }) => {
                    if (id === 'actions' && !isMonitored) return null;
                    return (
                      <TableCell key={id} style={style}>
                        <strong>{label}</strong>
                      </TableCell>
                    );
                  },
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {uwTasks?.map((e) => (
                <Row
                  key={e.uwTaskId}
                  row={e}
                  isEditable={isMonitored}
                  onUpdateRow={onUpdateRow}
                />
              ))}
              <TableRow>
                <TableCell colSpan={6} className={classes.cellSpacing} />
              </TableRow>
              <TableRow className={classes.reviewRow}>
                <TableCell />
                <TableCell>REVIEW STATUS</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Box mr={0.5}>
                      <TasksStatusIcon
                        isCompleted={isCompleted} // FINISH: check for tasks completion
                        isMonitored={isMonitored}
                      />
                    </Box>
                    {isCompleted ? 'Completed' : 'Incomplete'}
                  </Box>
                </TableCell>
                <TableCell colSpan={4}>
                  {isCompleted
                    ? 'All the tasks above are done and all the monitoring events in the Monitoring tab are processed'
                    : 'At least one of the tasks above is open and/or at least one monitoring event in the Monitoring tab is unprocessed'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} className={classes.cellSpacing} />
              </TableRow>
            </TableBody>
          </MaterialTable>
        </>
      )}
    </Card>
  );
};

export default Table;
