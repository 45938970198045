const {
  REACT_APP_AMPLIFY_REGION: AMPLIFY_REGION,
  REACT_APP_AMPLIFY_USER_POOL_ID: AMPLIFY_USER_POOL_ID,
  REACT_APP_AMPLIFY_USER_POOL_CLIENT_ID: AMPLIFY_USER_POOL_CLIENT_ID,
  REACT_APP_APPLICATIONS_SERVICE_URL: APPLICATIONS_SERVICE_URL,
} = process.env;

export {
  AMPLIFY_REGION,
  AMPLIFY_USER_POOL_ID,
  AMPLIFY_USER_POOL_CLIENT_ID,
  APPLICATIONS_SERVICE_URL,
};
