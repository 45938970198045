import { DataSource } from 'advanext-models/nectar/report';

export const companyDataSourcesCH = [DataSource.ZEFIX];

export const creditReportsDataSourcesCH = [DataSource.BISNODE, DataSource.CRIF];

export const collectionReportDataSourcesCH = [DataSource.CRIF_COLLECTION];

export const companyDataSourcesDE = [DataSource.FIRMENWISSEN];

export const creditReportsDataSourcesDE = [
  DataSource.SCHUFA,
  DataSource.CREDITSAFE,
  DataSource.CREDITREFORM_INTERNATIONAL_LIMITED,
  DataSource.CREDITREFORM_INTERNATIONAL_COMPLETE,
];
