import { CreditReportPosition } from 'Types';

export const headCells: Array<{
  id: keyof CreditReportPosition;
  label: string;
}> = [
  { id: 'property', label: 'Property' },
  { id: 'value', label: 'Value' },
  { id: 'update', label: 'Last Update' },
];
