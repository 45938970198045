import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ForgotPassword as AmplifyForgotPassword } from 'aws-amplify-react';
import {
  Grid,
  Button,
  TextField,
  Link,
  FormHelperText,
} from '@material-ui/core';

import Auth from '@aws-amplify/auth';
import { ConsoleLogger as Logger } from '@aws-amplify/core';

import { AUTH_STATES } from 'Services/auth';
import Layout from 'Views/providers/Auth/Layout';

import {
  INITIAL_FORM_STATE,
  formStateApplyEvent,
  formStateTouchAll,
  hasError,
  getError,
} from 'Services/forms';

/* eslint-disable */

const schemas = {
  submit: {
    code: { required: true },
    password: { required: true },
  },
  send: {
    username: { required: true, email: true },
  },
};

const styles = () => ({
  helperText: {
    textAlign: 'center',
  },
  submitButton: {
    width: '100%',
  },
});

const logger = new Logger('ForgotPassword');

class ForgotPassword extends AmplifyForgotPassword {
  constructor(props: any) {
    super(props);

    // eslint-disable-next-line
    this._validAuthStates = [AUTH_STATES.ForgotPassword];

    this.state = {
      ...(this.state || {}),
      formState: INITIAL_FORM_STATE,
    } as any;
  }

  getMode = () => {
    const { authData = {} } = this.props;
    if (this.state.delivery || authData.username) {
      return 'submit';
    }

    return 'send';
  };

  getSchema = () => {
    return schemas[this.getMode()];
  };

  setStateAsync = async (state: any) => {
    return new Promise((resolve) => {
      // @ts-ignore
      this.setState(state, resolve);
    });
  };

  handleInputChange = async (event: any) => {
    if (event.persist instanceof Function) event.persist();

    const { formState: oldFormState } = this.state as any;
    const formState = formStateApplyEvent(
      event,
      oldFormState,
      this.getSchema(),
    );
    await this.setStateAsync({ ...this.state, formState });

    return super.handleInputChange(event);
  };

  hasError = (field: string) => {
    return hasError(field, (this.state as any).formState);
  };

  getError = (field: string) => {
    return getError(field, (this.state as any).formState);
  };

  error = (error: any) => {
    super.changeState('forgotPassword');
    super.error(error);
  };

  send = async () => {
    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;

    try {
      const data = await Auth.forgotPassword(username);
      this.setState((prevState) => ({
        ...prevState,
        delivery: data.CodeDeliveryDetails,
        username,
      }));
      logger.debug(data);
    } catch (error) {
      this.error(error);
    } finally {
      this.changeState('forgotPassword');
    }
  };

  submit = async () => {
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username =
      this.getUsernameFromInput() || authData.username || this.state.username;

    try {
      const data = await Auth.forgotPasswordSubmit(username, code, password);
      logger.debug(data);
      this.changeState('signIn');
      this.setState({ delivery: null });
    } catch (error) {
      this.error(error);
    }
  };

  submitForm = async (event: any) => {
    event.preventDefault();

    const { formState } = this.state as any;

    if (formState.isValid) {
      this.changeState('loading');
      return this.getMode() === 'submit' ? this.submit() : this.send();
    }

    this.setStateAsync({
      formState: formStateTouchAll(formState, this.getSchema()),
    });
  };

  sendView: any = (classes: any) => (
    <>
      <Grid item>
        <TextField
          autoComplete="off"
          error={this.hasError('username')}
          fullWidth
          helperText={this.getError('username')}
          label="Email address"
          name="username"
          onChange={this.handleInputChange}
          required
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.submitButton}
          color="primary"
          onClick={this.submitForm}
          type="submit"
          variant="contained"
        >
          Send code
        </Button>
      </Grid>
    </>
  );

  submitView: any = (classes: any) => (
    <>
      <Grid item>
        <TextField
          autoComplete="off"
          error={this.hasError('code')}
          fullWidth
          helperText={this.getError('code')}
          label="Code"
          name="code"
          onChange={this.handleInputChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          autoComplete="off"
          error={this.hasError('password')}
          fullWidth
          helperText={this.getError('password')}
          label="New password"
          name="password"
          onChange={this.handleInputChange}
          placeholder="********"
          required
          type="password"
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.submitButton}
          color="primary"
          onClick={this.submitForm}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </Grid>
    </>
  );

  showComponent() {
    const { classes } = this.props as any;
    return (
      <Layout
      // features={[
      //   { icon: 'euro', text: 'Credit range from 10K to 2.5M' },
      //   { icon: 'flash_on', text: 'Instant credit rating' },
      //   { icon: 'access_time', text: 'Financing within 48 hours' },
      // ]}
      // onSubmit={this.submitForm}
      >
        {this.getMode() === 'submit'
          ? this.submitView(classes)
          : this.sendView(classes)}
        <Grid item>
          {this.getMode() === 'submit' && (
            <FormHelperText className={classes.helperText}>
              Did not receive the code? {/* eslint-disable-next-line */}
              <Link onClick={this.send}>Resend code</Link>
            </FormHelperText>
          )}
          <FormHelperText className={classes.helperText}>
            Remember your password? {/* eslint-disable-next-line */}
            <Link onClick={() => this.changeState('signIn')}>
              Back to sign in
            </Link>
          </FormHelperText>
        </Grid>
      </Layout>
    );
  }
}
// @ts-ignore
export default withStyles(styles as any)(ForgotPassword);
