export type DocumentPosition = {
  created: '';
  documentType: '';
  'file.filename'?: string;
  'metadata.comment'?: string;
  'metadata.status': string;
  actions: unknown;
};

export const headCells: Array<{
  id: keyof DocumentPosition;
  label: string;
  style: { width: string };
  isSortable: boolean;
}> = [
  {
    id: 'created',
    label: 'Created',
    style: { width: '13%' },
    isSortable: true,
  },
  {
    id: 'documentType',
    label: 'Document Type',
    style: { width: '15%' },
    isSortable: false,
  },
  {
    id: 'file.filename',
    label: 'File',
    style: { width: '28%' },
    isSortable: true,
  },
  {
    id: 'metadata.status',
    label: 'Status',
    style: { width: '10%' },
    isSortable: true,
  },
  {
    id: 'metadata.comment',
    label: 'Comment',
    style: { width: '21%' },
    isSortable: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    style: { width: '5%' },
    isSortable: false,
  },
];
