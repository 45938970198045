import { Auth } from 'aws-amplify';
import { User } from 'advanext-models/nectar';

import { translateCognitoUser } from './translators';

export * from './constants';
export * from './translators';

export const getAuthenticatedUser = async (
  bypassCache = false,
): Promise<User> =>
  translateCognitoUser(await Auth.currentAuthenticatedUser({ bypassCache }));

export const getAuthenticatedUserAuthToken = async (): Promise<string> => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
};

export const getAuthenticatedUserAuthTokenWithExpTime = async (): Promise<{
  token: string;
  expTime: number;
}> => {
  const user = await Auth.currentAuthenticatedUser();
  return {
    token: user.signInUserSession.idToken.jwtToken,
    expTime: user.signInUserSession.idToken.payload.exp,
  };
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<unknown> => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, oldPassword, newPassword);
};

export const updateUserDetails = async (details: object): Promise<void> => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, details);
  // reload Amplify user attributes, bypassing the cache
  await Auth.currentAuthenticatedUser({ bypassCache: true });
};

export const signOut = async (): Promise<unknown> => Auth.signOut();
