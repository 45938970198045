import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { StyledCard } from 'advanext-components';
import { Link } from '@material-ui/core';
import { NectarEntity } from 'advanext-models/nectar/entity';
import { Company } from 'advanext-models/advanext/company';
import { Table } from 'advanext-components';
import { Order } from 'advanext-components/Types';
import { Config } from 'advanext-components/components/Table';

import { ROUTES } from 'Constants';

type Row = {
  id: string;
  companyName: string;
  companyUid: string;
  companyId: string;
  created: Date;
};

type Props = {
  entities: NectarEntity<Company>[];
};

export const RecentCompanies = ({ entities }: Props): JSX.Element => {
  const { push } = useHistory();

  const rows = useMemo((): Row[] => {
    return entities.map((entity) => ({
      id: entity.entityId,
      companyName: entity.content?.name as string,
      companyUid: entity.compoundCompanyId.id,
      companyId: entity.compoundCompanyId.toString(),
      // We use company creation date rather than entity creation to be more precise,
      // but usually entity is created few ms later, so we can use either of them
      created: entity.content?.created ?? new Date(),
    }));
  }, [entities]);

  return (
    <StyledCard title="Recent Sign Ups">
      <Table
        paginated
        columns={[
          {
            sortable: true,
            filterable: true,
            accessor: 'companyName',
            Header: 'Company',
            // eslint-disable-next-line
            Cell: ({ value, row }: Config<Row>): JSX.Element => (
              <Link
                onClick={(): void =>
                  push(
                    ROUTES.Company.replace(
                      ':companyId',
                      row?.companyId as string,
                    ),
                  )
                }
              >
                {/* @ts-ignore */}
                {value}
              </Link>
            ),
            style: {
              width: '50%',
            },
          },
          {
            sortable: true,
            filterable: true,
            accessor: 'companyUid',
            Header: 'UID',
          },
          {
            sortable: true,
            filterable: true,
            accessor: 'created',
            Header: 'Sign Up Date',
            Cell: ({ value }: Config<Row>): string =>
              (value as Date)?.toLocaleDateString(),
          },
        ]}
        data={rows}
        defaultOrderBy="created"
        defaultOrder={Order.DESC}
      />
    </StyledCard>
  );
};

export default RecentCompanies;
