import { IndustryRatio } from 'advanext-models/nectar/financialstatement';

const FinancialStatementRecordsConst = {
  // Common attributes
  YEAR: 'YEAR',

  // Income statement part
  NET_SALES: 'NET_SALES',
  COGS: 'COGS',
  SGA_EXPENSE: 'SGA_EXPENSE',
  OTHER_OPERATING_EXPENSE: 'OTHER_OPERATING_EXPENSE',
  DEPRECIATION_AND_AMORTIZATION: 'DEPRECIATION_AND_AMORTIZATION',
  INTEREST_EXPENSES: 'INTEREST_EXPENSES',
  INCOME_TAX_EXPENSE: 'INCOME_TAX_EXPENSE',
  EXTRAORDINARY_ITEMS: 'EXTRAORDINARY_ITEMS',

  // Balance sheet part
  CASH_AND_CASH_EQUIVALENTS: 'CASH_AND_CASH_EQUIVALENTS',
  RECEIVABLES: 'RECEIVABLES',
  INVENTORIES: 'INVENTORIES',
  OTHER_CURRENT_ASSETS: 'OTHER_CURRENT_ASSETS',
  INTANGIBLES: 'INTANGIBLES',
  PLANTS_AND_EQUIPMENT: 'PLANTS_AND_EQUIPMENT',
  OTHER_FIXED_ASSETS: 'OTHER_FIXED_ASSETS',
  TRADE_AND_OTHER_PAYABLES: 'TRADE_AND_OTHER_PAYABLES',
  SHORT_TERM_BORROWINGS: 'SHORT_TERM_BORROWINGS',
  CURRENT_TAX_PAYABLE: 'CURRENT_TAX_PAYABLE',
  ACCRUED_EXPENSE: 'ACCRUED_EXPENSE',
  OTHER_CURRENT_LIABILITIES: 'OTHER_CURRENT_LIABILITIES',
  LONG_TERM_DEBT: 'LONG_TERM_DEBT',
  DEFERRED_TAXES: 'DEFERRED_TAXES',
  OTHER_LONG_TERM_LIABILITIES: 'OTHER_LONG_TERM_LIABILITIES',
  CAPITAL: 'CAPITAL',
  ADDITIONAL_PAID_IN_CAPITAL: 'ADDITIONAL_PAID_IN_CAPITAL',
  RETAINED_EARNINGS: 'RETAINED_EARNINGS',
  CURRENT_PORTION_OF_LONG_TERM_BORROWINGS:
    'CURRENT_PORTION_OF_LONG_TERM_BORROWINGS',
};

export const financialStatementRecords = Object.values(
  FinancialStatementRecordsConst,
);

export const IndustryRatioNames: Record<IndustryRatio, string> = {
  PROFIT_TO_SALES: 'Profit to Sales',
  CASHREC_TO_STDEBT: 'Cash Receivables to Short Term Debt',
  CURRENTASSETS_TO_STDEBT: 'Current Assets to Short Term Debt',
  EQ_TO_DEBT: 'Equity to Debt',
  EQ_TO_STDEBT: 'Equity to Short Term Debt',
  PROFIT_TO_DEBT: 'Profit to Debt',
  PROFIT_TO_STDEBT: 'Profit to Short Term Debt',
  SALES_TO_REC: 'Sales to Receivables',
  SALES_TO_ASSETS: 'Sales to Assets',
  DEPRECATION_TO_PPE: 'Depreciation to PPE', // FIXME: fix naming in Nectar
};
