import { Country } from 'advanext-models/advanext';

export const SearchCountriesConst = {
  Switzerland: Country[Country.CH],
  Germany: Country[Country.DE],
};

export const searchCountriesOptions = Object.entries(SearchCountriesConst).map(
  ([key, value]) => ({
    label: key,
    value: value as string,
  }),
);
