import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { useAsyncCallback } from 'react-async-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import {
  QueryLevel,
  DataSource,
  DataSourcesMetadata,
} from 'advanext-models/nectar/report';

import { StyledDialog } from 'advanext-components';
import { getDataSourceDocumentApi, downloadFile } from 'Api';
import { useUpdateDataSource } from 'Hooks';
import { openUrl, openBinaryFile } from 'Utilities';

const useStyles = makeStyles({
  card: {
    margin: '5px 10px 0 0',
    width: '115px',
    border: '1px solid #262B32',
    backgroundColor: '#e4e4e5',
    padding: '4px 6px',
    boxShadow: 'none',
  },
  media: {
    backgroundSize: 'contain',
    maxWidth: '115px',
    height: '30px',
  },
  content: {
    padding: '0px',
    marginBottom: '5px',
  },
  date: {
    width: '100%',
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '13px',
    paddinTop: '2px',
  },
  actions: {
    padding: 0,
    '& > :not(:first-child)': {
      marginLeft: '5px',
    },
  },
  button: {
    flex: 1,
    minWidth: 'auto',
    height: '20px',
    padding: 0,
    backgroundColor: '#c4c4c4',
    border: '1px solid #262B32',
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
  },
});

type Props = DataSourcesMetadata & {
  companyId?: string;
  companyName?: string;
  dataSource: keyof typeof DataSource;
  status?: string;
  supportsRefresh: boolean;
  at?: string;
  disabledReorder: boolean;
  compoundTenant: string;
  onOrderCrifCollection: (companyId: string) => void | Promise<void>;
};

const DataSourceCard = ({
  companyId,
  companyName = '',
  link,
  created,
  dataSource,
  status,
  supportsRefresh,
  at,
  disabledReorder,
  compoundTenant,
  onOrderCrifCollection,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isReportLoading =
    dataSource === DataSource.CRIF_COLLECTION && created && !link;

  const { updateDataSource, isUpdating, setIsUpdating, isUpdated } =
    useUpdateDataSource(companyId, dataSource);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const openDialog = useCallback(
    () => setIsDialogOpen(true),
    [setIsDialogOpen],
  );

  const closeDialog = useCallback(
    () => setIsDialogOpen(false),
    [setIsDialogOpen],
  );

  const closeUpdatingSnackbar = useCallback(
    (_, reason: string) => {
      if (reason !== 'clickaway') {
        setIsUpdating(false);
      }
    },
    [setIsUpdating],
  );

  const confirmUpdateDataSource = useCallback(async () => {
    closeDialog();

    if (dataSource === DataSource.CRIF_COLLECTION) {
      return onOrderCrifCollection(companyId as string);
    }

    updateDataSource();
  }, [
    closeDialog,
    updateDataSource,
    dataSource,
    companyId,
    onOrderCrifCollection,
  ]);

  const { addToast } = useToasts();

  const openDataSource = useAsyncCallback(
    async (link, dataSource, companyId, compoundTenant, at) => {
      if (dataSource === DataSource.CRIF_COLLECTION) {
        const content = await downloadFile(link);
        openBinaryFile(content);
        return;
      }

      if (link) {
        openUrl(link);
        return;
      }
      const data = await getDataSourceDocumentApi(
        dataSource,
        companyId,
        compoundTenant,
        at,
      );
      if (data) {
        openBinaryFile(data);
      } else {
        addToast('Failed to download file', { appearance: 'error' });
      }
    },
  );

  const handleOpenButton = useCallback(
    () =>
      openDataSource.execute(link, dataSource, companyId, compoundTenant, at),
    [openDataSource, link, dataSource, companyId, compoundTenant, at],
  );

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={`/dataSources/${dataSource.toLowerCase()}.png`}
        title={dataSource}
      />
      <CardContent className={classes.content}>
        <Typography className={classes.date} variant="body2" component="p">
          {created ? dayjs(created).fromNow() : 'Not ordered'}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        {!created && (
          <Button
            className={classes.button}
            onClick={openDialog}
            disabled={disabledReorder}
          >
            {/* @ts-ignore */}
            <FontAwesomeIcon icon={faShoppingCart} />
          </Button>
        )}
        {!!created && !isReportLoading && (
          <Button className={classes.button} onClick={handleOpenButton}>
            Open
          </Button>
        )}
        {isReportLoading && (
          <Button className={classes.button} disabled>
            <CircularProgress size={12} />
          </Button>
        )}
        {!!created && supportsRefresh && (
          <Button
            className={classes.button}
            onClick={
              status === QueryLevel.PAID ? openDialog : confirmUpdateDataSource
            }
            disabled={disabledReorder}
          >
            Reorder
          </Button>
        )}
      </CardActions>

      <StyledDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={confirmUpdateDataSource}
        header={`Buy ${dataSource} Report`}
        confirmBtnName="Buy"
      >
        {`Please confirm if you really want to buy a new ${dataSource} Report for ${companyName}.`}
      </StyledDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isUpdating}
        message={<span id="updating">Updating {dataSource}...</span>}
        onClose={closeUpdatingSnackbar}
      />
    </Card>
  );
};

export default DataSourceCard;
