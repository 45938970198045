export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type OptionType = {
  label: string;
  value: string;
};

export type SortOrder = -1 | 0 | 1;
