import { EntityStatus, EntityType } from 'advanext-models/nectar/entity';
import { EMPTY_STRING_DISPLAY_VALUE } from './common';
import {
  FilterOperator,
  GroupExpression,
  GroupingOperator,
} from 'advanext-models/lambda/filtering';

/**
 * Entities that work like documents
 */
export const DOCUMENT_ENTITY_TYPES = [
  EntityType.DOCUMENT,
  EntityType.FINANCIAL_STATEMENT_DOCUMENT,
  EntityType.REPORT_DOCUMENT,
];

export const getDocumentEntityType = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case EntityType.DOCUMENT:
      return 'Document';
    case EntityType.FINANCIAL_STATEMENT_DOCUMENT:
      return 'Financial Statement Document';
    case EntityType.FINANCIAL_STATEMENT:
      return 'Financial Statement';
    case EntityType.REPORT_DOCUMENT:
      return 'Report Document';
    default:
      return value;
  }
};

// eslint-disable-next-line
export const getEntityTypeOptions = (options = Object.values(EntityStatus)) =>
  options.map((value) => ({
    label: getDocumentEntityType(value),
    value,
  }));

export const getEntityStatus = (value?: string): string => {
  if (!value) {
    return EMPTY_STRING_DISPLAY_VALUE;
  }

  switch (value) {
    case EntityStatus.APPROVED:
      return 'Approved';
    case EntityStatus.DECLINED:
      return 'Declined';
    case EntityStatus.UNPROCESSED:
      return 'Unprocessed';
    case EntityStatus.PENDING:
      return 'Pending';
    default:
      return value;
  }
};

export const entityStatusesOptions = Object.values(EntityStatus).map(
  (value) => ({
    label: getEntityStatus(value),
    value,
  }),
);

export const ONBOARDED_COMPANIES_FILTER: GroupExpression = {
  operator: GroupingOperator.AND,
  groupedNodes: [],
  simpleNodes: [
    {
      operator: FilterOperator.EQUALS,
      attribute: 'data.content.onboarded',
      // eslint-disable-next-line
      // @ts-ignore
      value: true, // FIXME: add booleans support to model
    },
  ],
};
