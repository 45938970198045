import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { Logo } from 'advanext-components';
import { ROUTES } from 'Constants';
import {
  user as userSelector,
  loading as userIsLoading,
} from 'Redux/models/Auth';

import {
  SidebarMenu,
  ProfileDropdown,
  ApplicationSelector,
} from './components';
import { useSelectors, usePolicy } from 'Hooks';
import { IsOwner } from 'Policies';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflowY: 'scroll',
    overflowX: 'scroll',
    background: theme.palette.background.default,
  },
  sidebar: {
    background: '#262B32',
    padding: theme.spacing(4, 4, 4, 4),
    minWidth: theme.spacing(30),
  },
  sidebarContainer: {
    width: '100%',
    minHeight: '100%',
  },
  content: {
    padding: theme.spacing(4),
    flexGrow: 1,
    maxWidth: '1300px',
  },
  logo: {
    width: theme.spacing(15),
    height: theme.spacing(4),
  },
}));

type Props = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const [user, isLoading] = useSelectors([userSelector, userIsLoading]);
  const [isOwner] = usePolicy([IsOwner]);
  if (isLoading) return <div>Loading...</div>;

  return user ? (
    <Grid container className={classes.root} wrap="nowrap">
      <Grid item className={classes.sidebar}>
        <Grid
          justify="space-between"
          className={classes.sidebarContainer}
          direction="column"
          container
          spacing={0}
        >
          <Grid item md={12}>
            <NavLink to={ROUTES.Dashboard}>
              <Logo className={classes.logo} />
            </NavLink>
            <SidebarMenu />
          </Grid>
          <Grid item md={12}>
            <ProfileDropdown />
            <br />
            {isOwner && <ApplicationSelector />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  ) : (
    <>{children}</>
  );
};

export default AppLayout;
