import validateJs from 'validate.js';
import lodashSet from 'lodash.set';
import type { FormEvent } from 'Types';

/* eslint-disable */

export type FormState = {
  values: { [key: string]: any };
  touched: { [key: string]: string | true };
  isValid: boolean;
  errors: { [key: string]: string };
};

const formatValue = (event: FormEvent): any => {
  switch (event.target.type) {
    case 'checkbox':
      return event.target.checked;
    case 'number':
      return Number.parseFloat(event.target.value);
    default:
      return event.target.value;
  }
};

export const INITIAL_FORM_STATE: FormState = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

export const isValid = (formState: FormState, schema: any): boolean =>
  !validateJs(formState.values, schema);

export const formStateApplyEvent = (
  event: FormEvent,
  formState: FormState,
  schema: any,
): FormState => {
  if (event.persist instanceof Function) event.persist();

  const values = lodashSet(
    { ...formState.values },
    event.target.name,
    formatValue(event),
  );
  const errors = validateJs(values, schema);

  return {
    ...formState,
    isValid: !errors,
    errors: errors || {},
    values,
    touched: {
      ...formState.touched,
      [event.target.name]: true,
    },
  };
};

export const formStateTouchAll = (
  formState: FormState,
  schema: any,
): FormState => {
  const errors = validateJs(formState.values, schema);
  return {
    ...formState,
    errors: errors || {},
    isValid: !errors,
    touched: {
      ...formState.touched,
      ...Object.keys(schema).reduce(
        (memo, field) => ({ ...memo, [field]: true }),
        {},
      ),
    },
  };
};

export const hasError = (field: string, formState: FormState): boolean => {
  return !!(formState.touched[field] && formState.errors[field]);
};

export const getError = (
  field: string,
  formState: FormState,
): string | null | undefined => {
  return hasError(field, formState) && formState.errors[field]
    ? formState.errors[field][0]
    : null;
};

export default {
  INITIAL_FORM_STATE,
  formStateApplyEvent,
  formStateTouchAll,
  hasError,
  getError,
};
